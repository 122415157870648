import { dateMask } from '../../../utils';

export class AdvertisementDetails {
  constructor(advertisement = {}) {
    const { productId, userId, material } = advertisement;

    this.ad = new Advertisement(advertisement);
    this.product = new Product(productId);
    this.user = userId || {};
    this.material = material || {};
  }
}

export class Advertisement {
  constructor(ad = {}) {
    const {
      _id,
      productId,
      userId,
      unitPrice,
      totalPrice,
      piecePrice,
      percentOff,
      sequenceValue,
      amount,
      customData,
      createdAt,
      unit,
      city,
      state,
      neighborhood
    } = ad;

    this.id = _id || '';
    this.productId = productId || '';
    this.userId = userId || '';
    this.unitPrice = unitPrice || 0.0;
    this.totalPrice = totalPrice || 0.0;
    this.piecePrice = piecePrice || 0.0;
    this.percentOff = percentOff || 0;
    this.sequenceValue = sequenceValue || '';
    this.amount = amount || 1;
    this.customData = customData || {};
    this.createdAt = createdAt || Date.now();
    this.unit = unit || '';
    this.city = city || '';
    this.state = state || '';
    this.neighborhood = neighborhood || '';
  }
}

export class Product {
  constructor(product = {}) {
    const {
      material,
      _id,
      name,
      brand,
      imageUrl,
      categoryId,
      sequenceValue
    } = product;

    this.material = material || [];
    this.id = _id || '';
    this.name = name || '';
    this.brand = brand || '';
    this.imageUrl = imageUrl || '';
    this.categoryId = categoryId || '';
    this.sequenceValue = sequenceValue || 0;
  }
}
