export default class PopUp {
  constructor() {
    let pp = document.createElement("div");
    this.lock = document.createElement("div");
    this.msg = document.createElement("p");
    this.btn = document.createElement("button");

    this.lock.classList.add("lock-screen");
    this.lock.align = "center";

    this.msg.classList.add("pop-message");

    this.btn.classList.add(
      "btn",
      "neo-shadow",
      "btn-rounded",
      "text-primary",
      "text-bold",
      "border-thin",
      "border-primary"
    );

    pp.classList.add("pop-up", "neo-inner-shadow", "round-corners");
    pp.appendChild(this.msg);
    pp.lastChild.after(this.btn);
    pp.style.visibility = "visible";
    pp.style.opacity = "1";

    this.lock.appendChild(pp);
  }
  open(message, buttonText, action) {
    this.msg.textContent = message || "";
    this.btn.textContent = buttonText || "OK";
    this.btn.onclick = function () {
      if (typeof action === "function") {
        action.call(this);
      }
      document.body.removeChild(this.parentElement.parentElement);
    };

    document.body.lastChild.after(this.lock);
    this.btn.focus();
  }
}
