import React, { useState, useEffect } from 'react';
import api from 'src/api/Api';
import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './Results';
import Loading from '../../components/Loading';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CustomerListView = () => {
  const classes = useStyles();
  const [ads, setAds] = useState([]);
  const [loading, setLoading] = useState(false);

  const getAds = async () => {
    setLoading(true);
    let favoritos = await api.searchUserBookmarks();
    await setAds(favoritos);
    setLoading(false);
  };

  useEffect(() => {
    getAds();
  }, []);

  return (
    <Page className={classes.root} title="Anuncios">
      <Loading open={loading} />
      <Container maxWidth={false}>
        <Box mt={3}>
          <Results reload={getAds} advertisements={ads} />
        </Box>
      </Container>
    </Page>
  );
};

export default CustomerListView;
