import { Box, Typography } from '@material-ui/core';
import React from 'react';

export function getDescriptionStructure(data, spec) {
  let items = [];
  let sortedKeys = [];
  if (data && spec) {
    for (let field in spec) {
      let ord = spec[field].order;
      sortedKeys[ord] = field;
    }
    for (const key of sortedKeys) {
      if (data.hasOwnProperty(key)) {
        const isBool = spec[key].type === 'check';
        const correctValue = isBool ? (data[key] ? 'SIM' : 'NÃO') : data[key];
        const label = spec[key].label;
        const unit = spec[key].unit;
        items.push(
          <Box
            display="flex"
            padding="1.5rem 0"
            flexDirection="column"
            margin={0}
          >
            <Typography color="textSecondary" variant="h4" component="p">
              <strong>{`${label.toUpperCase()}:`}</strong>
            </Typography>
            <Typography component="p" variant="h3">
              {`${correctValue} ${unit || ''}`}
            </Typography>
          </Box>
        );
      }
    }
  }
  return items;
}
