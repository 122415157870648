import React, { useEffect, useState } from 'react';
import { AdvertisementRowModel, Row, RowData } from './AdvertisementModel';
import {
  getBrand,
  getMaterials,
  getProducts,
  saveAdvertisements,
  normalizeNumberValues,
  getCategories
} from './functions';
import AdvertisementRow from '../../components/AdvertisementRow';
import {
  Box,
  CircularProgress,
  Container,
  IconButton,
  makeStyles,
  Typography
} from '@material-ui/core';
import Page from 'src/components/Page';
import {
  AddCircleOutlineOutlined,
  DeleteOutlineRounded,
  FileCopyRounded
} from '@material-ui/icons';
import _ from 'lodash';
import CustomPopUp from 'src/components/CustomPopUp';
import { normalizeAndIgnoreDacritics } from '../../utils';
import OutlineCssButton from 'src/components/OutlineCssButton';
import Api from 'src/api/Api';
import { useNavigate } from 'react-router';
import Loading from 'src/components/Loading';
import AdBuilder from '../../components/AdManager';
import useMobileDetect from '../../utils/useMobileDetect';

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(3)
  },
  actionButtons: {
    color: theme.palette.primary.contrastText,
    '&:hover': {
      color: 'white'
    }
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '44%',
    left: '48%'
  }
}));

export default function Advertisement(props) {
  const advertisementModel = new AdvertisementRowModel();
  const [state, setState] = useState(advertisementModel);
  const [reloadPage, setReloadPage] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const navigate = useNavigate();
  const classes = useStyles();
  const detectMobile = useMobileDetect();

  function getMeasures(name, value, order) {
    let measure = state.rows[order].measure || 0;
    let totalMeasure = state.rows[order].totalMeasure || 0;
    let { length, width } = state.rows[order].customData;
    let { amount, unit } = state.rows[order];

    if (name === 'amount') {
      amount = value;
    }

    if (name === 'width' && unit === 'm²') {
      measure = (value / 1000) * (length / 1000) || 0;
    }

    if (name === 'length' && unit === 'm²') {
      measure = (value / 1000) * (width / 1000) || 0;
    }

    if (name === 'length' && (unit === 'ml' || unit === 'm')) {
      measure = value;
    }

    if (unit === 'un') {
      measure = 1;
      totalMeasure = amount;
    } else {
      totalMeasure = amount * measure;
    }

    return { measure, totalMeasure, amount };
  }

  function getPrices(name, value, order, measures) {
    let {
      unitPrice,
      piecePrice,
      totalPrice,
      totalMeasure,
      amount
    } = state.rows[order];

    if (measures) {
      totalMeasure = measures.totalMeasure;
      amount = measures.amount;
    }

    if (name === 'totalPrice' && totalMeasure !== 0) {
      unitPrice = value / totalMeasure;
      totalPrice = value;
    }

    if (name === 'unitPrice') {
      totalPrice = value * totalMeasure;
      unitPrice = value;
      piecePrice = totalPrice / amount;
    }

    if (name === 'piecePrice') {
      piecePrice = value;
      totalPrice = piecePrice * amount;
      unitPrice = totalMeasure ? piecePrice / (totalMeasure / amount) : 0;
    }

    if (name === 'amount' || name === 'length' || name === 'width') {
      totalPrice = totalMeasure * unitPrice;
      piecePrice = totalPrice / amount;
    }
    return { unitPrice, totalPrice, piecePrice };
  }

  function preTotalPrice(_, floatValue, order) {
    if (isNaN(floatValue)) {
      floatValue = 0;
    }
    const event = {
      target: {
        name: 'totalPrice',
        value: floatValue
      }
    };
    handleChange(event, order);
  }

  function handleDonation(value, order) {
    let rows = state.rows;
    const modifiedRow = rows[order];
    modifiedRow.percentOff = 0;
    modifiedRow.isDonation = value;
    rows[order] = { ...modifiedRow };
    setState({
      ...state,
      rows
    });
    if (modifiedRow.isDonation) {
      preUnitPrice(null, 0, order);
    }
  }

  function preUnitPrice(_, floatValue, order) {
    if (isNaN(floatValue)) {
      floatValue = 0;
    }
    const event = {
      target: {
        name: 'unitPrice',
        value: floatValue
      }
    };
    handleChange(event, order);
  }

  function prePiecePrice(_, floatValue, order) {
    if (isNaN(floatValue)) {
      floatValue = 0;
    }
    const event = {
      target: {
        name: 'piecePrice',
        value: floatValue
      }
    };
    handleChange(event, order);
  }
  function handleFocus(event, order) {
    let rows = state.rows;
    let amount = '';
    rows[order] = { ...rows[order], amount };
    setState({
      ...state,
      rows
    });
  }

  function handleChange(event, order) {
    let { value, name, type } = event.target;

    if (event.target.type === 'checkbox') {
      value = event.target.checked;
    }

    if (!isNaN(value) && Number(value) < 0) {
      value = 1;
    }
    if (
      name === 'totalPrice' ||
      name === 'unitPrice' ||
      name === 'piecePrice'
    ) {
      value = Number(value);
    }
    if (type === 'number') {
      value = Number(value);
    }

    let measures = null;
    let prices = null;

    if (name === 'amount') {
      const { length, width } = state.rows[order].customData;
      const unit = state.rows[order].unit;
      if (unit === 'ml' || unit === 'un') {
        measures = getMeasures(name, value, order);
        prices = getPrices(name, value, order, measures);
      } else if (unit === 'm²') {
        if (Number(length) > 0 && Number(width) > 0) {
          measures = getMeasures(name, value, order);
          prices = getPrices(name, value, order, measures);
        }
      }
    }

    if (
      name === 'totalPrice' ||
      name === 'unitPrice' ||
      name === 'piecePrice'
    ) {
      prices = getPrices(name, value, order);
    }
    if (name === 'percentOff' && value) {
      if (Number(value) > 100) value = 100;
    }
    if (name) {
      const rows = state.rows;
      const modifiedRow = rows[order];

      modifiedRow[name] = value || '';

      if (prices && name === 'unitPrice') {
        modifiedRow.totalPrice = prices.totalPrice;
        modifiedRow.piecePrice = prices.piecePrice;
      }
      if (prices && name === 'totalPrice') {
        modifiedRow.unitPrice = prices.unitPrice;
        modifiedRow.piecePrice = prices.piecePrice;
      }
      if (prices && (name === 'amount' || name === 'piecePrice')) {
        modifiedRow.totalPrice = prices.totalPrice;
        modifiedRow.piecePrice = prices.piecePrice;
        modifiedRow.unitPrice = prices.unitPrice;
      }
      if (measures) {
        modifiedRow.measure = normalizeNumberValues(
          measures.measure,
          modifiedRow.unit
        );
        modifiedRow.totalMeasure = normalizeNumberValues(
          measures.totalMeasure,
          modifiedRow.unit
        );
      }
      rows[order] = { ...modifiedRow };

      setState({
        ...state,
        rows
      });
    }
  }

  function handleCustomChange(event, order) {
    let { value, name, max, type } = event.target;
    if (type === 'checkbox') {
      value = event.target.checked;
    } else {
      event.preventDefault();
      if (type === 'number') {
        value = max
          ? Number(value) > max
            ? max
            : Number(value)
          : Number(value);
      }
    }

    if (!isNaN(value) && Number(value) < 0) {
      value = 1;
    }

    let measures = null;
    let prices = null;

    if (name === 'length' || name === 'width') {
      measures = getMeasures(name, value, order);
      prices = getPrices(name, value, order, measures);
    }

    if (name) {
      const rows = state.rows;
      const newRow = rows[order];
      newRow.customData[name] = _.isBoolean(value) ? value : value || '';
      if (prices) {
        newRow.totalPrice = prices.totalPrice;
        newRow.piecePrice = prices.piecePrice;
      }
      if (measures) {
        newRow.measure = normalizeNumberValues(measures.measure, newRow.unit);
        newRow.totalMeasure = normalizeNumberValues(
          measures.totalMeasure,
          newRow.unit
        );
      }
      rows[order] = { ...newRow };
      setState({
        ...state,
        rows
      });
    }
  }

  function handleSearch(e, values, order) {
    const { target, key } = e;
    const { value, textContent } = target;
    let product = '';
    if (key === 'Enter') {
      product = state.data.products.find(prod =>
        normalizeAndIgnoreDacritics(getBrand(prod)).includes(
          normalizeAndIgnoreDacritics(value)
        )
      );
    } else {
      product = state.data.products.find(
        prod => getBrand(prod) === textContent
      );
    }
    if (product) {
      let rows = state.rows;
      let newRow = rows[order];

      newRow.productId = product.id;
      newRow.product = product;
      rows[order] = newRow;
      setState({
        ...state,
        rows
      });
    }
  }

  function handleMaterial(event, order) {
    const { value } = event.target;
    let material = state.data.materials.find(
      material => material.name === value
    );
    if (material) {
      getProductsByMaterial(material, order);
    }
  }

  async function getProductsByMaterial(material, order) {
    setLoading(true);
    setLoadingMessage('Carregando Produtos');
    let products = await getProducts(material.id);
    if (material && products) {
      let rows = state.rows;
      let newRow = new Row();

      newRow.order = order;
      newRow.category = rows[order].category;
      newRow.material = material;
      newRow.unit = material.unit || 'un';
      newRow.unitLabel = material.unitLabel || 'Unidade';
      newRow.blockSearch = false;
      let customData = {};
      for (var k in material.formStructure) {
        if (material.formStructure[k].type === 'check') {
          customData[k] = false;
        } else {
          customData[k] = '';
        }
      }
      newRow.customData = customData;
      rows[order] = newRow;
      setState({
        ...state,
        rows,
        data: {
          ...state.data,
          productNames: products.map(prd => getBrand(prd)),
          products
        }
      });
    }
    setLoading(false);
  }

  function handleCategory(event, order) {
    const { value } = event.target;
    let category = state.data.categories.find(
      category => category.name === value
    );
    if (category) {
      let rows = state.rows;
      let newRow = new Row();

      newRow.category = category;
      newRow.blockFields = false;
      rows[order] = newRow;
      setState({
        ...state,
        rows,
        data: {
          ...state.data,
          productNames: [],
          materialNames: [],
          materials: [],
          products: []
        }
      });
      fetchMaterials(category.id);
    }
  }

  function handleBlurCapture(e, order) {
    let { value, min, name } = e.target;
    if (min) {
      value = Number(value) <= min ? min : Number(value);
    } else {
      if (name === 'percentOff') {
        value = Number(value) > 0 ? Number(value) : 0;
      } else {
        value = Number(value) >= 1 ? Number(value) : 1;
      }
    }

    e.target.value = value;
    if (name === 'amount' || name === 'percentOff') {
      handleChange(e, order);
    } else {
      handleCustomChange(e, order);
    }
  }

  async function fetchMaterials(categoryId) {
    setLoading(true);
    setLoadingMessage('Carregando Materiais');
    let materials = await getMaterials(categoryId);
    if (materials) {
      setState({
        ...state,
        data: {
          ...state.data,
          materials,
          materialNames: materials.map(mat => mat.name)
        }
      });
    }
    setLoading(false);
  }

  useEffect(() => {
    const fetchCategories = async () => {
      let categories = await getCategories();
      if (categories.length > 0) {
        props.isEditing
          ? setState({
              ...state,
              rows: [new Row(props.editAd)],
              data: props.data
            })
          : setState({
              ...state,
              data: {
                categories,
                categoryNames: categories.map(cat => cat.name)
              }
            });
      }
    };

    if (state.data.categories.length <= 0) {
      fetchCategories();
    }
  }, [state.data.categories, props.isEditing]);

  useEffect(() => {
    setLoading(true);
    const validateToken = async () => {
      const { valid, error } = await Api.validate();
      setLoading(false);
      (!valid || error) && navigate('/login', { replace: true });
    };
    validateToken();
  }, []);

  function addRow() {
    let previousIndex = state.rows.length - 1;
    let lastRow = new Row({ ...state.rows[previousIndex] });
    let rowValidation = lastRow.validateFields();

    if (rowValidation.isValid) {
      let newRow = new Row();
      newRow.order = state.rows.length;
      setState({
        ...state,
        rows: [...state.rows, newRow]
      });
    } else {
      setAlertMessage(rowValidation.message);
      setShowAlert(true);
    }
  }

  function duplicateRow(order) {
    let newRow = new Row({ ...state.rows[order] });
    let rowValidation = newRow.validateFields();

    if (rowValidation.isValid) {
      newRow.order = order + 1;
      newRow.customData = { ...state.rows[order].customData };
      newRow.blockSearch = false;

      setState({
        ...state,
        rows: [...state.rows, { ...newRow }]
      });
    } else {
      setAlertMessage(rowValidation.message);
      setShowAlert(true);
    }
  }

  function removeRow(order) {
    let rows = [...state.rows];
    if (rows.length > 1) {
      rows.splice(order, 1);
      setState({
        ...state,
        rows
      });
    }
  }

  async function validateAndSave() {
    setLoading(true);
    for (let row of state.rows) {
      let newRow = new Row({ ...row });
      newRow.material = newRow.material.id;
      let isValidRow = newRow.validateFields();
      if (!isValidRow.isValid) {
        setAlertMessage(isValidRow.message);
        setShowAlert(true);
        setLoading(false);
        return;
      }
      row = newRow;
    }
    try {
      let response = await saveAdvertisements(state.rows);
      if (response.error) {
        setAlertMessage(response.error.message || 'Erro ao cadastrar!');
        setShowAlert(true);
      } else {
        const advertisementModel = new AdvertisementRowModel();
        setState(advertisementModel);
        setAlertMessage('Anúncios cadastrados com sucesso!');
        setShowAlert(true);
        setReloadPage(true);
      }
    } catch (e) {
      setAlertMessage(e.error.message || 'Erro ao cadastrar!');
      setShowAlert(true);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Page className={classes.root} title="Anunciar">
      <Loading open={loading} message={loadingMessage} />
      {detectMobile.isDesktop() && (
        <div
          style={{
            margin: '0px auto 24px',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <AdBuilder size="[728,90]" id="ad-banner-728x90"></AdBuilder>
        </div>
      )}
      {detectMobile.isMobile() && (
        <div
          style={{
            margin: '0px auto 24px',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <AdBuilder size="[320,100]" id="ad-banner-320x100"></AdBuilder>
        </div>
      )}
      <CustomPopUp
        message={alertMessage}
        visible={showAlert}
        setShow={setShowAlert}
        replaceDefaultAction={() => {
          if (reloadPage) {
            window.location.reload();
          }
          setReloadPage(false);
        }}
        defaultActionTitle="OK"
      />
      <Box width="100%" mb={5}>
        <Typography align="center" variant="h1" color="primary">
          Anuncie sua sobra.
        </Typography>{' '}
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
        marginTop={15}
      >
        <Container maxWidth="lg">
          <Box
            display="flex"
            padding={0}
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            {state.rows.map((row, index) => (
              <AdvertisementRow
                handleChange={handleChange}
                handleCustomChange={handleCustomChange}
                handleMaterial={handleMaterial}
                handleSearch={handleSearch}
                handleCategory={handleCategory}
                preTotalPrice={preTotalPrice}
                preUnitPrice={preUnitPrice}
                prePiecePrice={prePiecePrice}
                handleDonation={handleDonation}
                handleFocus={handleFocus}
                handleBlur={handleBlurCapture}
                key={index}
                order={index}
                isEditing={props.isEditing}
                row={row}
                data={state.data}
              >
                {!props.isEditing && (
                  <>
                    <IconButton
                      className={classes.actionButtons}
                      onClick={() => duplicateRow(index)}
                      title="Duplicar"
                      edge="end"
                      size="small"
                    >
                      <FileCopyRounded />
                    </IconButton>
                    <IconButton
                      className={classes.actionButtons}
                      onClick={() => removeRow(index)}
                      title="Remover"
                      edge="end"
                      size="small"
                    >
                      <DeleteOutlineRounded />
                    </IconButton>
                    <IconButton
                      className={classes.actionButtons}
                      edge="end"
                      size="small"
                      onClick={() => addRow()}
                      title="Nova Sobra"
                    >
                      <AddCircleOutlineOutlined />
                    </IconButton>
                  </>
                )}
              </AdvertisementRow>
            ))}
            {props.isEditing ? (
              <Box position="relative">
                <OutlineCssButton
                  onClick={() => props.callback(state.rows)}
                  variant="contained"
                  color="primary"
                  disabled={props.loading}
                >
                  <strong>Salvar</strong>
                </OutlineCssButton>
                {props.loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </Box>
            ) : (
              <Box position="relative">
                <OutlineCssButton
                  onClick={() => validateAndSave(state.rows)}
                  variant="contained"
                  color="primary"
                  disabled={loading}
                >
                  <strong>Publicar Anúncios</strong>
                </OutlineCssButton>
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </Box>
            )}
          </Box>
        </Container>
      </Box>
      {detectMobile.isMobile() && (
        <div
          style={{
            margin: '24px auto 0px',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <AdBuilder size="[300,250]" id="ad-banner-300x250"></AdBuilder>
        </div>
      )}
    </Page>
  );
}
