import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';

export default function CustomPopUp({
  title,
  visible,
  setShow,
  message,
  defaultActionTitle,
  children,
  actionButtons,
  replaceDefaultAction,
  ...rest
}) {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    if (replaceDefaultAction) {
      replaceDefaultAction();
    }
    setOpen(false);
    setShow && setShow(false);
  };

  useEffect(() => {
    setOpen(visible);
  }, [visible]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title || 'e-sobras'}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
        {children}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {defaultActionTitle || 'OK'}
        </Button>
        {actionButtons}
      </DialogActions>
    </Dialog>
  );
}
