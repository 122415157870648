import { configureStore } from '@reduxjs/toolkit';
import AdvertisementSearchReducer from '../views/advertisementSearch/AdvertisementSearchReducer';
import SearchReducer from '../components/Search/SearchReducer';
import filterReducer from '../components/Filter/FilterReducer';
import locationReducer from '../components/LocationFilter/locationReducer';
import AdvertisementDetailsReducer from '../views/adverisementDetails/AdvertisementDetailsReducer';
import { getDefaultMiddleware } from '@reduxjs/toolkit';

const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false
});

export default configureStore({
  reducer: {
    advertisementSearch: AdvertisementSearchReducer,
    advertisementDetails: AdvertisementDetailsReducer,
    searchState: SearchReducer,
    filtersState: filterReducer,
    locationState: locationReducer
  },
  middleware: customizedMiddleware
});
