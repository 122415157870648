export const formatWithCustomSeparator = (date, separator) => {
  try {
    const parsedDate = new Date(date);
    const day = parsedDate
      .getDate()
      .toString()
      .padStart(2, '0');
    const month = (parsedDate.getMonth() + 1).toString().padStart(2, '0');
    const year = parsedDate.getFullYear();
    if (!separator) separator = '/';
    return ''.concat(day, separator, month, separator, year);
  } catch (error) {
    throw new Error('Invalid date!');
  }
};
