import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  List,
  Typography,
  makeStyles,
  Button
} from '@material-ui/core';
import { Lock as LockIcon } from 'react-feather';
import NavItem from './NavItem';
import {
  MenuBookRounded,
  PostAddRounded,
  FindInPageRounded,
  ListAltRounded,
  AccountBoxRounded,
  BookmarkBorderRounded
} from '@material-ui/icons';
import { getUserAttribute } from 'src/utils/user';
import Api from 'src/api/Api';
import { useState } from 'react';
import Loading from 'src/components/Loading';
import { useDispatch } from 'react-redux';
import {
  resetFilters,
  searchByFilters,
  setFilters
} from 'src/components/Filter/FilterReducer';

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const userName = getUserAttribute('name');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  async function handleSearchButtonClick() {
    setLoading(true);
    await dispatch(resetFilters());
    await dispatch(searchByFilters());
  }

  async function handleMyPageClick() {
    setLoading(true);
    const userId = getUserAttribute('id') || getUserAttribute('_id');
    await dispatch(resetFilters());
    await dispatch(setFilters({ key: 'userId', value: userId }));
    await dispatch(searchByFilters());
  }

  const [items, setItems] = useState([
    {
      href: '/app/profile',
      icon: AccountBoxRounded,
      title: 'Perfil'
    },
    {
      href: '/app/advertisements',
      icon: ListAltRounded,
      title: 'Meus Anúncios'
    },
    {
      isButton: true,
      onClick: handleSearchButtonClick,
      icon: FindInPageRounded,
      title: 'Buscar Produtos'
    },
    {
      href: '/app/advertisement',
      icon: PostAddRounded,
      title: 'Novo Anúncio'
    },
    {
      href: '/app/bookmarks',
      icon: BookmarkBorderRounded,
      title: 'Favoritos'
    },
    {
      href: '/login',
      icon: LockIcon,
      title: 'logout',
      onClick: async e => {
        e.preventDefault();
        e.stopPropagation();
        await Api.logout(getUserAttribute('email'));
        localStorage.clear();
        window.location = '/login?logout=true';
      }
    }
  ]);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    const userId = getUserAttribute('id') || getUserAttribute('_id');
    if (userId) {
      let firstOption = items[0];
      let newItems = [...items];
      newItems[0] = {
        isButton: true,
        onClick: handleMyPageClick,
        icon: MenuBookRounded,
        title: 'Minha Página'
      };
      setItems([firstOption, ...newItems]);
    }
  }, []);

  const content = (
    <>
      <Loading open={loading} />
      <Box height="100%" display="flex" flexDirection="column">
        <Box alignItems="center" display="flex" flexDirection="column" p={2}>
          <Avatar
            className={classes.avatar}
            component={RouterLink}
            src={
              getUserAttribute('userImage') ||
              'https://esobras-imgs.s3.amazonaws.com/ffc9bd99597903694029ca70404c1f0b-avatar.png'
            }
            to="/app/profile"
          />
          <Typography className={classes.name} color="textPrimary" variant="h5">
            {userName}
          </Typography>
        </Box>
        <Divider />
        <Box p={2}>
          <List>
            {items.map(item => (
              <NavItem
                isButton={item.isButton}
                href={item.href}
                key={item.title}
                title={item.title}
                icon={item.icon}
                onClick={item.onClick || function() {}}
              />
            ))}
          </List>
        </Box>
        <Box flexGrow={1} />
      </Box>
    </>
  );

  return (
    <>
      <Drawer
        anchor="left"
        classes={{ paper: classes.mobileDrawer }}
        onClose={onMobileClose}
        open={openMobile}
        variant="temporary"
      >
        {content}
      </Drawer>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;
