const { CONSTANTS } = require('../../config');
const { requests } = require('../../utils');

export async function getSegments() {
  let response = await requests({
    path: CONSTANTS.category
  });
  if (!response.error) {
    return response;
  }
  return [];
}

export async function getMaterial(segmentId) {
  let response = await requests({
    path: CONSTANTS.fetchMaterials,
    params: `?categoryId=${segmentId}`
  });
  if (!response.error) {
    return response;
  }
  return [];
}

export async function getProducts(materialId) {
  let response = await requests({
    path: CONSTANTS.fetchProducts,
    params: `?materialId=${materialId}`
  });

  if (!response.error) {
    return response;
  }
  return [];
}

export const parseSegments = segments =>
  segments.map(category => ({
    name: category.name,
    value: category.id
  }));

export const parseMaterials = materials =>
  materials.map(material => ({
    name: material.name,
    value: material.id
  }));

export const parseProducts = products => {
  return products.map(product => `${product.brand} - ${product.name}`);
};

export const search = query => {
  return requests({
    path: CONSTANTS.adsList,
    params: query || '?'
  })
    .then(res => {
      if (res && res.error) {
        if (res.status > 400) {
          return {
            error: true,
            errorMessage:
              'Erro de autorização ou sessão. Sair e entrar novamente poderá resolver o problema.'
          };
        } else {
          return { error: true, errorMessage: res.error };
        }
      } else {
        return res;
      }
    })
    .catch(e => ({
      error: true,
      errorMessage: 'Erro ao contactar o servidor.'
    }));
};
