import { Box, Typography } from '@material-ui/core';
import React from 'react';

export default function Advice(props) {
  return (
    <Box
      bgcolor="lemonchiffon"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      width="100%"
      paddingLeft="1rem"
      paddingRight="1rem"
      paddingBottom="2rem"
      paddingTop="2rem"
      borderRadius={10}
    >
      <Typography variant="body1" component="p">
        <em>
          <strong>IMPORTANTE!</strong>
        </em>
      </Typography>
      <Typography variant="body1" component="span">
        O{' '}
        <em>
          <Typography component="span">
            <strong>E-SOBRAS</strong>
          </Typography>
        </em>{' '}
        não se responsabiliza pelo conteúdo publicado por{' '}
        <em>
          <strong className="text-primary">{props.userName}</strong>
        </em>
        , pois o mesmo é de inteira responsabilidade do usuáio/anunciante.
      </Typography>
    </Box>
  );
}
