import { Popover } from '@material-ui/core';
import React from 'react';

export default function PopMenu({ open, children, element, ...rest }) {
  return (
    <Popover
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      open={open}
      anchorEl={element}
    >
      {children}
    </Popover>
  );
}
