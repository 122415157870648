import React, { useEffect, useState } from 'react';
import { Link, Link as RouterLink, useMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
  makeStyles,
  Typography,
  Box,
  Hidden
} from '@material-ui/core';
import OutlineCssButton from 'src/components/OutlineCssButton';
import Api from 'src/api/Api';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'transparent',
    marginBottom: '1rem'
  },
  toolbar: {
    height: 64
  },
  navLink: {
    padding: '.5rem'
  },
  upperButton: {
    boxShadow: theme.shadows[26],
    '&:active, &:focus': {
      boxShadow: theme.shadows[25]
    }
  }
}));

const TopBar = () => {
  const classes = useStyles();
  const match = useMatch({ path: '/login' });
  const [showLoginButton, setShowLoginButton] = useState(true);

  useEffect(() => {
    const validateLoggedAndValidToken = async () => {
      const response = await Api.validate();
      setShowLoginButton(!response.valid);
    };
    validateLoggedAndValidToken();
  }, []);

  return (
    <AppBar className={classes.root} elevation={0}>
      <Toolbar className={classes.toolbar}>
        <RouterLink to="/">
          <Typography color="primary" variant="h3">
            <strong>e-sobras</strong>{' '}
            <Typography variant="caption" color="primary">
              <small>beta</small>
            </Typography>
          </Typography>
        </RouterLink>
        <Box flexGrow={1} />
        {showLoginButton && (
          <Link to={match ? '/register' : '/login'}>
            <Typography
              className={classes.navLink}
              color="primary"
              variant="h6"
            >
              <strong>{match ? 'cadastre-se' : 'entrar'}</strong>
            </Typography>
          </Link>
        )}
        <Hidden mdUp>
          <OutlineCssButton title="anunciar" color="primary" variant="outlined">
            <Link
              style={{ color: 'inherit', width: '100%', height: '100%' }}
              to="/app/advertisement"
            >
              <strong>+</strong>
            </Link>
          </OutlineCssButton>
        </Hidden>
        <Hidden mdDown>
          <OutlineCssButton color="primary" variant="outlined">
            <Link
              style={{ color: 'inherit', width: '100%', height: '100%' }}
              to="/app/advertisement"
            >
              + Anunciar
            </Link>
          </OutlineCssButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;
