import { TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const CssInputText = withStyles(theme => ({
  root: {
    '& label.Mui-focused, & label.MuiInputLabel-shrink': {
      color: theme.palette.primary.main,
      transform: 'translate(20%, -75%) scale(0.75)'
    },
    '& label[data-shrink="false"]': {
      transform: 'translate(20%, 85%) scale(1)'
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '60rem',
      border: `solid 3px ${theme.palette.background.default} !important`,
      padding: 0,
      boxShadow:
        'rgba(0, 0, 0, 0.16) 6px 6px 8px 0px inset, rgba(255, 255, 255, 0.77) -6px -6px 8px 0px inset, rgba(0, 0, 0, 0.16) 6px 6px 8px 0px, rgba(255, 255, 255, 0.77) -6px -6px 8px 0px',
      '& .MuiInputAdornment-root': {
        marginLeft: theme.spacing(0.8)
      },
      '& input, & .MuiSelect-root': {
        height: '15px',
        width: '100%'
      },
      '& input': {
        padding: theme.spacing(1.2)
      },
      '& .MuiSelect-root': {
        padding: theme.spacing(0.9)
      },
      '& fieldset': {
        borderColor: 'transparent'
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main
      }
    }
  }
}))(TextField);

export default CssInputText;
