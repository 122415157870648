import { Fab } from '@material-ui/core';
import { WhatsApp } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React from 'react';

const useStyles = makeStyles(theme => ({
  float: {
    position: 'absolute',
    bottom: 20,
    right: 30,
    marginLeft: '70%',
    marginTop: '80%',
    color: 'white'
  }
}));

function FloatingWhatsAppButton() {
  const classes = useStyles();
  return (
    <Fab
      href="https://wa.me/+553139952294?text=Ol%C3%A1,%20gostaria%20de%20obter%20mais%20informa%C3%A7%C3%B5es!"
      target="_blank"
      className={classes.float}
      color="primary"
      aria-label="chat"
      variant="extended"
    >
      <strong>Suporte</strong>
    </Fab>
  );
}

export default FloatingWhatsAppButton;
