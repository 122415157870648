const currencyConfig = {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2
};

export default (value) => {
    if (!value) {
        value = 0;
    }
    return Intl.NumberFormat("pt-br", currencyConfig).format(value);
};
