import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import AdvertisementList from 'src/views/AdvertisementList';
import Bookmarks from 'src/views/Bookmarks';
import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';
import RegisterView from 'src/views/auth/RegisterView';
import Advertisement from 'src/views/Advertisement';
import Teste from 'src/views/home';
import AdvertisementSearch from './views/advertisementSearch';
import AdvertisementDetails from './views/adverisementDetails';
import Account from './views/account/AccountView';
import TermsAndConditions from './views/termsAndConditions';
import SelfProtect from './views/security';
import SecureSales from './views/securitySales';
import CookiesPolicy from './views/cookiesPolicy';
import AboutUs from './views/aboutUs';
import Help from './views/help';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'advertisements', element: <AdvertisementList /> },
      { path: 'bookmarks', element: <Bookmarks /> },
      {
        path: 'advertisement',
        element: <Advertisement />
      },
      { path: '*', element: <Navigate to="/404" /> },
      { path: 'profile', element: <Account /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'search', element: <AdvertisementSearch /> },
      { path: 'details/:id', element: <AdvertisementDetails /> },
      { path: 'terms', element: <TermsAndConditions /> },
      { path: 'help', element: <Help /> },
      { path: 'protect', element: <SelfProtect /> },
      { path: 'secure-sale', element: <SecureSales /> },
      { path: 'cookies-policy', element: <CookiesPolicy /> },
      { path: 'about', element: <AboutUs /> },
      { path: 'login', element: <LoginView /> },
      { path: 'register', element: <RegisterView /> },
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <Teste /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
