export const columns = [
  'Cor',
  {
    name: 'Produto'
  },
  'Material',
  'Anunciante',
  'Quantidade',
  'Preço p/ Peça',
  'Ações'
];

export const options = {
  selectableRowsHideCheckboxes: true,
  selectableRowsHeader: false,
  filter: false,
  searchOpen: true,
  search: true,
  responsive: 'standard',
  print: false,
  download: false,
  downloadOptions: {
    filename: 'anuncios.csv',
    filterOptions: {
      useDisplayedColumnsOnly: true
    }
  },

  textLabels: {
    body: {
      noMatch: 'Desculpe, não encontramos resultados para a sua busca.',
      toolTip: 'Ordenar',
      columnHeaderTooltip: column => `Ordenar por ${column.label}`
    },
    pagination: {
      next: 'Próxima pagina.',
      previous: 'Página anterior.',
      rowsPerPage: 'Linhas por página.',
      displayRows: 'de'
    },
    toolbar: {
      search: 'Buscar',
      downloadCsv: 'Download CSV',
      print: 'Imprimir',
      viewColumns: 'Mostrar Colunas',
      filterTable: 'Filtrar Tabela'
    },
    filter: {
      all: 'Todos',
      title: 'FILTROS',
      reset: 'LIMPAR'
    },
    viewColumns: {
      title: 'Mostrar Colunas',
      titleAria: 'Mostrar / Ocultar Colunas'
    },
    selectedRows: {
      text: 'linha(s) selecionadas',
      delete: 'Remover',
      deleteAria: 'Remover linhas selecionadas'
    }
  }
};
