import { TableFooter, TableCell, TableRow } from "@material-ui/core";
import React, { Component }  from 'react';

const tableTotalSum = tableData => {
  return tableData.reduce((sum, row) => sum + (row.total ? row.total : 0), 0);
};
const tableTaxSum = tableData => {
  return tableData.reduce((sum, row) => sum + (row.tax ? row.tax : 0), 0);
};
export const columns = [
  'ID',
  'Cor',
  {
    name: 'Produto'
  },
  'Material',
  'Quantidade',
  'Total',
  'Data de Criação',
  'Ações'
];
