import { CONSTANTS } from '../config';

export const getUserAttribute = attributeName => {
  let localStorageItem = localStorage.getItem(CONSTANTS.userKey);
  if (localStorageItem) {
    try {
      let user = JSON.parse(localStorageItem);
      if (user[attributeName]) {
        return user[attributeName];
      }
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }
  return undefined;
};

export const pushUserAttributeValue = (attributeName, value) => {
  let localStorageItem = localStorage.getItem(CONSTANTS.userKey);
  if (localStorageItem) {
    try {
      let user = JSON.parse(localStorageItem);
      if (user[attributeName] && Array.isArray(user[attributeName])) {
        user[attributeName].push(value);
        localStorage.setItem(CONSTANTS.userKey, JSON.stringify(user));
      }
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }
  return undefined;
};

export const pullUserAttributeValue = (attributeName, value) => {
  let localStorageItem = localStorage.getItem(CONSTANTS.userKey);
  if (localStorageItem) {
    try {
      let user = JSON.parse(localStorageItem);
      if (user[attributeName] && Array.isArray(user[attributeName])) {
        const index = user[attributeName].indexOf(value);
        if (index > -1) {
          user[attributeName].splice(index, 1);
        }
        localStorage.setItem(CONSTANTS.userKey, JSON.stringify(user));
      }
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }
  return undefined;
};

export const getLocalUser = () => {
  let localStorageItem = localStorage.getItem(CONSTANTS.userKey);
  if (localStorageItem) {
    try {
      let user = JSON.parse(localStorageItem);
      return user;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }
  return undefined;
};
