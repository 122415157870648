import React, { useEffect } from 'react';
import { Box, IconButton, Typography } from '@material-ui/core';
import { Facebook, Mail, Phone } from 'react-feather';
import { WhatsApp } from '@material-ui/icons';
import OutlineCssButton from '../OutlineCssButton';
import { makeStyles } from '@material-ui/styles';
import CustomPopUp from '../CustomPopUp';
import { useState } from 'react';
import PopMenu from '../PopMenu';
import Api from 'src/api/Api';
import {
  getFullProductDescription,
  productDetails
} from '../../views/adverisementDetails/business';
import { useNavigate } from 'react-router';
import theme from 'src/theme';
import {wppMessageSend, wppMessageSendOnElement} from '../../utils/wppMessageSend'

const useStyles = makeStyles(theme => ({
  seeMoreButton: {
    border: `dashed thin ${theme.palette.primary.main}`,
    margin: '1rem',
    color: theme.palette.primary.main,
    boxShadow: theme.shadows[26]
  },
  mapsWrapper: {
    width: '100%',
    maxWidth: '350px',
    minHeight: '200px',
    margin: '2rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0.8rem',
    borderRadius: '1rem',
    backgroundColor: theme.palette.background.default,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    border: `dashed thin ${theme.palette.primary.main}`,
    boxShadow: theme.shadows[26]
  },
  circleAvatar: {
    width: '150px',
    height: '150px',
    marginTop: '1rem',
    background: theme.palette.background.default,
    border: `solid 5px ${theme.palette.background.default}`,
    borderRadius: '75px',
    backgroundPosition: 'center',
    backgroundSize: '100%',
    boxShadow: theme.shadows[26]
  },
  userCardDetailsWrapper: {
    display: 'flex',
    width: '100%',
    flex: '1 0 300px',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0.8rem',
    borderRadius: '1rem',
    border: `dashed thin ${theme.palette.primary.main}`,
    boxShadow: theme.shadows[26]
  },
  contactButtons: {
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: 'white'
    }
  }
}));

export default function UserCardDetails(props) {
  const user = props.user;
  const adInfo = props.adInfo;
  const classes = useStyles();

  const [message, setMessage] = useState('');
  const [linkTo, setLinkTo] = useState('');
  const [openMail, setOpenMail] = useState(false);
  const [openPhone, setOpenPhone] = useState(false);
  const [openWP, setOpenWP] = useState(false);
  const [anchor, setAnchor] = useState(undefined);
  const [showMessage, setShowMessage] = useState(false);

  const URL = document.URL;
  const navigate = useNavigate();

  const handleShowPhoneInfo = async () => {
    const response = await Api.validate();
    if (response.valid) {
      setMessage(user.phone);
      setLinkTo('tel:' + user.phone);
      setOpenPhone(!openPhone);
    } else {
      setShowMessage(true);
    }
  };

  const handleShowEmailInfo = async () => {
    const response = await Api.validate();
    if (response.valid) {
      setMessage(user.email);
      setLinkTo(
        'mailto:' +
          user.email +
          '?subject=Compartilhamento de Sobra&body=' +
          document.URL
      );
      setOpenMail(!openMail);
    } else {
      setShowMessage(true);
    }
  };

  const handleShowWPInfo = async () => {
    const response = await Api.validate();
    if (response.valid) {
      setMessage(user.cellPhone);
      setLinkTo(wppMessageSend(user.cellPhone, adInfo.ad, adInfo.product, adInfo.material, document.URL));
      setOpenWP(!openWP);
    } else {
      setShowMessage(true);
    }
  };

  return (
    <Box className={classes.userCardDetailsWrapper}>
      <CustomPopUp
        replaceDefaultAction={() => {
          localStorage.setItem('lastUrl', URL);
          navigate('/login', { replace: true });
        }}
        defaultActionTitle="Entrar"
        message="Para ter acesso aos dados de contato, você precisa estar logado."
        visible={showMessage}
        setShow={setShowMessage}
      />
      <Box
        className={classes.circleAvatar}
        style={{
          backgroundImage: `url(${user.userImage ||
            'https://esobras-imgs.s3.amazonaws.com/ffc9bd99597903694029ca70404c1f0b-avatar.png'})`
        }}
      ></Box>
      <Typography
        align="center"
        style={{ marginTop: '1rem' }}
        variant="body1"
        component="p"
      >
        <strong>{`${user.name} ${user.surname}`}</strong>
      </Typography>
      <Typography
        align="center"
        style={{ marginTop: '1rem' }}
        variant="body1"
        component="p"
      >
        <strong>{`${user.neighborhood} ${user.city} - ${user.state}`}</strong>
      </Typography>
      <Box
        title={user.isLogged ? 'Online' : 'Offline'}
        display="flex"
        flexDirection="row"
        alignItems="center"
      >
        <Typography
          variant="body1"
          component="span"
          color={user.isLogged ? 'primary' : 'error'}
        >
          {user.isLogged ? 'Online' : 'Offline'}
        </Typography>
        <Typography
          variant="h1"
          component="span"
          color={user.isLogged ? 'primary' : 'error'}
        >
          •
        </Typography>
      </Box>
      <Box display="flex" flexDirection="row">
        <IconButton
          className={classes.contactButtons}
          onClick={e => {
            setAnchor(e.currentTarget);
            handleShowEmailInfo();
          }}
          className={classes.contactButtons}
          title="Email"
        >
          <Mail />
          <PopMenu open={openMail} element={anchor}>
            {message && (
              <Typography style={{ padding: 15 }} component="p" variant="h5">
                <strong>
                  <a href={linkTo}>{message}</a>
                </strong>
              </Typography>
            )}
          </PopMenu>
        </IconButton>
        <IconButton
          onClick={e => {
            setAnchor(e.currentTarget);
            handleShowWPInfo();
          }}
          className={classes.contactButtons}
          title="Whatsapp"
        >
          <WhatsApp />
          <PopMenu open={openWP} element={anchor}>
            {message && (
              <Typography style={{ padding: 15 }} component="p" variant="h5">
                <strong>
                  <a href={linkTo}>{message}</a>
                </strong>
              </Typography>
            )}
          </PopMenu>
        </IconButton>
        <IconButton
          className={classes.contactButtons}
          onClick={e => {
            setAnchor(e.currentTarget);
            handleShowPhoneInfo();
          }}
          title="Telefone"
        >
          <Phone />
          <PopMenu open={openPhone} element={anchor}>
            {message && (
              <Typography style={{ padding: 15 }} component="p" variant="h5">
                <strong>
                  <a href={linkTo}>{message}</a>
                </strong>
              </Typography>
            )}
          </PopMenu>
        </IconButton>
      </Box>
      <OutlineCssButton
        // onClick={() => alert(JSON.stringify(adInfo))}
        className={classes.seeMoreButton}
      >
        <a
          style={{ color: theme.palette.primary.main }}
          target="_blank"
          href={`/search?userId=${user.id}`}
        >
          <strong>Ver anúncios do vendedor</strong>
        </a>
      </OutlineCssButton>
      <OutlineCssButton
        className={classes.mapsWrapper}
        target="blank"
        style={{
          backgroundImage: `url("https://esobras-imgs.s3.amazonaws.com/7c885b5ffb68fb0290768f1576c84a00-location_thumb.png")`
        }}
        href={`https://www.google.com/maps/place/${user.neighborhood},+${user.city}+-+${user.state},+${user.cep}`}
      ></OutlineCssButton>
    </Box>
  );
}
