import { createSlice } from "@reduxjs/toolkit";
import { validateAndSetFilters } from "../Filter/FilterReducer";

const LocationReducer = createSlice({
    name: "locationState",
    initialState: {
        selectedState: "",
        selectedCity: "",
        selectedNeighborhood: "",
        cities: [],
        neighborhoods: []
    },
    reducers: {
        setSelectedSate: (state, action) => {
            state.selectedState = action.payload;
        },
        setSelectedCity: (state, action) => {
            state.selectedCity = action.payload;
        },
        setSelectedNeighborhood: (state, action) => {
            state.selectedNeighborhood = action.payload;
        },
        setCities: (state, action) => {
            state.cities = action.payload;
        },
        setNeighborhoods: (state, action) => {
            state.neighborhoods = action.payload;
        }
    }
});

export const {
    setCities,
    setNeighborhoods,
    setSelectedCity,
    setSelectedNeighborhood,
    setSelectedSate
} = LocationReducer.actions;

export const handleSelectedState = (selectedState, locations) => async (dispatch) => {
    dispatch(setSelectedSate(selectedState));
    dispatch(
        validateAndSetFilters([
            {
                key: "s",
                value: selectedState
            },
            {
                key: "c",
                value: ""
            },
            {
                key: "b",
                value: ""
            }
        ])
    );
    let foundedCities = locations.cities.find((city) => city.state === selectedState);
    dispatch(setCities(foundedCities ? foundedCities.values : []));
    dispatch(setSelectedCity(""));
    dispatch(setSelectedNeighborhood(""));
    dispatch(setNeighborhoods([]));
};

export const cleanLocationFilter = () => async (dispatch) => {
   
    dispatch(
        validateAndSetFilters([
            {
                key: "s",
                value: ""
            },
            {
                key: "c",
                value: ""
            },
            {
                key: "b",
                value: ""
            }
        ])
    );

    dispatch(setCities([]));
    dispatch(setNeighborhoods([]));
    dispatch(setSelectedSate(""))
    dispatch(setSelectedCity(""));
    dispatch(setSelectedNeighborhood(""));
}

export const handleSelectedCity = (city, locations) => async (dispatch) => {
    dispatch(setSelectedCity(city));
    dispatch(
        validateAndSetFilters([
            {
                key: "c",
                value: city
            },
            {
                key: "b",
                value: ""
            }
        ])
    );
    let foundedNeighborhoods = locations.neighborhoods.find((nh) => nh.city === city);
    dispatch(setNeighborhoods(foundedNeighborhoods ? foundedNeighborhoods.values : []));
    dispatch(setSelectedNeighborhood(""));
};

export const handleSelectedNeighborhood = (value) => async (dispatch) => {
    dispatch(setSelectedNeighborhood(value));
    dispatch(
        validateAndSetFilters([
            {
                key: "b",
                value: value
            }
        ])
    );
};

export const selectLocationState = (state) => state.locationState;
export default LocationReducer.reducer;
