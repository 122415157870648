import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { matchSorter } from 'match-sorter';
import {
  selectSearchState,
  fetchSegments,
  setSelectedMaterial,
  setSelectedSegment,
  setModelBrand,
  setImage,
  getMaterialsBySegmentId,
  getProductsByMaterialId,
  setSelectedProduct
} from './SearchReducer';
import { parseProducts } from './utils';
import {
  searchByFilters,
  setFilters,
  validateAndSetFilters,
  resetFilters,
  selectFiltersState,
  removeFilter
} from '../Filter/FilterReducer';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  IconButton,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Tooltip
} from '@material-ui/core';
import { SearchOutlined, ShareOutlined } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import clsx from 'clsx';
import { getBrand } from 'src/views/Advertisement/functions';
import { Facebook, Mail, Whatsapp } from 'react-social-sharing/dist-modules';
import ClipboardButton from '../ClipboardButton';
import OutlineCssButton from '../OutlineCssButton';
import PopMenu from '../PopMenu';

const useStyles = makeStyles(theme => ({
  select: {
    margin: '0 1px 0 0',
    background: theme.palette.primary.main,
    color: 'white',
    borderColor: 'transparent',
    padding: '.4rem',
    colorAdjust: 'exact',
    transition: 'filter ease-in-out .35s',
    filter: 'brightness(.95)',
    maxHeight: '40px',
    flex: '1 0 130px',
    '&:hover': {
      filter: 'brightness(1.1)'
    },
    '& fieldset': {
      border: 'none'
    }
  },
  leftSelect: {
    [theme.breakpoints.up('sm')]: {
      borderRadius: '60rem 0 0 60rem'
    },
    [theme.breakpoints.down('xs')]: {
      borderRadius: '1rem 0 0 0'
    }
  },
  rightSelect: {
    [theme.breakpoints.up('sm')]: {
      borderRadius: '0 60rem 60rem 0'
    },
    [theme.breakpoints.down('xs')]: {
      borderRadius: '0 1rem 0 0'
    }
  },
  search: {
    [theme.breakpoints.up('sm')]: {
      zIndex: '2',
      border: 'solid 4px',
      borderColor: theme.palette.primary.main,
      background: theme.palette.background.default,
      borderRadius: '60rem',
      maxHeight: '47px',
      padding: '0px',
      boxShadow: theme.shadows[25]
    },
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirecton: 'column',
      border: 'none',
      boxShadow: 'none'
    }
  },
  roundButton: {
    [theme.breakpoints.up('sm')]: {
      borderRadius: '60rem',
      color: 'white',
      width: '40px',
      height: '40px',
      padding: '0'
    },
    [theme.breakpoints.down('xs')]: {
      borderRadius: '0 0 1rem 0'
    }
  },
  searchInput: {
    [theme.breakpoints.up('xs')]: {
      height: '100%',
      flex: '4.5 0 300px',
      display: 'flex',
      alignItems: 'center'
    },
    [theme.breakpoints.down('xs')]: {
      backgroundColor: 'white',
      borderRadius: '0 0 1rem 1rem'
    }
  },
  input: {
    marginTop: 0,
    height: 35,
    '& fieldset': {
      border: 'none'
    }
  },
  buttonProgress: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    padding: 5,
    borderRadius: 60,
    color: 'white',
    position: 'absolute',
    top: '20%',
    right: '60%',
    zIndex: 50
  }
}));

export default function Search({
  handleMaterial,
  handleSegment,
  placeholder,
  className,
  preview,
  ...rest
}) {
  const classes = useStyles();
  const state = useSelector(selectSearchState);
  const dispatch = useDispatch();
  const {
    selectedSegment,
    selectedMaterial,
    selectedProduct,
    isLoading,
    segments,
    materials,
    products,
    image
  } = state;

  const [shareUrl, setShareUrl] = useState('');
  const [productPreview, setProductPreview] = useState('');
  const [anchorElement, setAnchorElement] = useState('');
  const [shouldViewShare, setShouldViewShare] = useState(false);

  useEffect(() => {
    dispatch(fetchSegments());
    // if (window.location.pathname === '/') {
    //   dispatch(setSelectedMaterial({}));
    //   dispatch(setSelectedSegment({}));
    //   dispatch(setSelectedProduct({}));
    //   setProductPreview('');
    //   setShouldViewShare(false);
    // }
  }, []);

  const { filters } = useSelector(selectFiltersState);
  const selectedOption = (e, handle, caller) => {
    if (caller === 'material') {
      const { value } = e.target;
      if (value && value !== 'material' && value !== 0) {
        dispatch(resetFilters({ key: 'categoryId' }));
        dispatch(setSelectedMaterial(value));
        dispatch(setFilters({ key: 'material', value }));
        dispatch(getProductsByMaterialId(value));
      }
    }
    if (caller === 'segment') {
      const { value } = e.target;
      dispatch(resetFilters({key:"userId"}));
      dispatch(setSelectedSegment(value));
      dispatch(setFilters({ key: 'categoryId', value }));
      dispatch(getMaterialsBySegmentId(value));
    }
    if (handle && typeof handle === 'function') {
      handle(e);
    }
  };

  async function handleSearch(event, newValue) {
    let product = products.find(p => getBrand(p) === newValue);
    if (product) {
      dispatch(setImage({ imageUrl: product.imageUrl }));
      generateShareUrl(product.id);
      setProductPreview(newValue);
      dispatch(
        validateAndSetFilters([
          { key: 'productId', value: product.id },
          { key: 'mb', value: '' }
        ])
      );
      dispatch(setSelectedProduct(product));
    } else {
      dispatch(setImage({}));
      setProductPreview('');
      dispatch(
        validateAndSetFilters([
          { key: 'productId', value: '' },
          { key: 'mb', value: '' }
        ])
      );
      dispatch(setSelectedProduct({}));
    }
  }

  function replaceSpecialChars(str) {
    str = str.replace(/[ÀÁÂÃÄÅ]/, 'A');
    str = str.replace(/[àáâãäå]/, 'a');
    str = str.replace(/[ùúû]/, 'u');
    str = str.replace(/[ÙÚÛ]/, 'U');
    str = str.replace(/[ìíî]/, 'i');
    str = str.replace(/[ÌÍÎ]/, 'I');
    str = str.replace(/[éèê]/, 'e');
    str = str.replace(/[óòôõ]/, 'o');
    str = str.replace(/[ÓÒÔÔ]/, 'o');
    str = str.replace(/[ÈÉÊË]/, 'E');
    str = str.replace(/[Ç]/, 'C');
    str = str.replace(/[ç]/, 'c');

    return str.replace(/[^a-z0-9]/gi, '');
  }

  const filterOptions = (options, { inputValue }) => {
    if (!inputValue) {
      return options;
    }

    var words = inputValue.split(' ');
    return words.reduceRight((items, word) => {
      word = replaceSpecialChars(word);
      return matchSorter(items, word, {
        threshold: matchSorter.rankings.CONTAINS,
        keys: [item => replaceSpecialChars(item)]
      });
    }, options);
  };

  function setProduct() {
    let searchProductElement = document.getElementById('search-product').value;
    if (searchProductElement) {
      let product = products.find(p => getBrand(p) === searchProductElement);
      if (product) {
        dispatch(setSelectedProduct(product));
      }
    }
  }

  async function generateShareUrl(productId) {
    let query = `${document.URL}?categoryId=${selectedSegment.id}&material=${selectedMaterial.id}&productId=${productId}`;
    setShareUrl(query);
  }

  function search() {
    if (!selectedMaterial.id) {
      return;
    }
    setProduct();
    dispatch(removeFilter({ key: 'page' }));
    dispatch(searchByFilters());
  }

  const InputSearch = params => (
    <TextField
      id="product-value"
      variant="outlined"
      margin="dense"
      fullWidth
      className={classes.input}
      placeholder="Buscar por produto"
      {...params}
    />
  );

  return (
    <Container maxWidth="lg" disableGutters>
      <Box display="flex" flexDirection="column">
        <Box
          className={classes.search}
          flexWrap="wrap"
          display="flex"
          flexDirection="row"
          mt={1}
        >
          <Box
            position="relative"
            style={{ flex: '1 0 300px' }}
            display="flex"
            alignItems="center"
          >
            {isLoading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
            <Select
              className={clsx(classes.select, classes.leftSelect, className)}
              variant="outlined"
              defaultValue="segmento"
              name="segment"
              key={selectedSegment.id}
              onChange={e => selectedOption(e, handleSegment, 'segment')}
              value={selectedSegment.id}
            >
              <MenuItem value="segmento">
                <em>Segmento</em>
              </MenuItem>
              {segments.map(segment => (
                <MenuItem key={segment.id} value={segment.id}>
                  {segment.name}
                </MenuItem>
              ))}
            </Select>
            <Select
              className={clsx(classes.select, classes.rightSelect, className)}
              variant="outlined"
              defaultValue="material"
              name="material"
              key={selectedMaterial.id}
              value={selectedMaterial.id}
              disabled={isLoading}
              onClick={e => selectedOption(e, handleMaterial, 'material')}
            >
              <MenuItem value="material">
                <em>Material</em>
              </MenuItem>
              {materials.map(material => (
                <MenuItem key={material.id} value={material.id}>
                  {material.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box className={classes.searchInput}>
            <Autocomplete
              id="search-product"
              autoHighlight
              blurOnSelect
              fullWidth
              filterOptions={filterOptions}
              key={selectedProduct.id || filters.mb}
              value={getBrand(selectedProduct)}
              options={parseProducts(products)}
              onChange={handleSearch}
              renderInput={InputSearch}
            />

            <Button
              className={classes.roundButton}
              color="primary"
              variant="contained"
              onClick={search}
            >
              <SearchOutlined />
            </Button>
          </Box>
        </Box>
        {preview && image && (
          <Box
            flexWrap="wrap"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            mt={6}
          >
            <img width="200" height="200" src={image} />
            <Box mt={5}>
              <OutlineCssButton
                onClick={event => {
                  setAnchorElement(event.currentTarget);
                  setShouldViewShare(!shouldViewShare);
                }}
                position="relative"
                color="primary"
              >
                <ShareOutlined />
                Compartilhar Preview
                <PopMenu element={anchorElement} open={shouldViewShare}>
                  <Tooltip title="Compartilhar por Whatsapp." placement="top">
                    <IconButton size="small">
                      <Whatsapp
                        solidcircle
                        small
                        link={shareUrl}
                        message={`Olá, estou precisando de uma sobra desse produto: \n\n*${productPreview}*\n\nPor a caso você teria alguma coisa?\n\nLink da imagem do produto:\n`}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Compartilhar por E-mail." placement="top">
                    <IconButton size="small">
                      <Mail solidcircle small link={shareUrl} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Compartilhar no Facebook." placement="top">
                    <IconButton size="small">
                      <Facebook solidcircle small link={shareUrl} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Copiar link" placement="top">
                    <IconButton size="small">
                      <ClipboardButton title="Copiar link" link={shareUrl} />
                    </IconButton>
                  </Tooltip>
                </PopMenu>
              </OutlineCssButton>
            </Box>
          </Box>
        )}
      </Box>
    </Container>
  );
}
