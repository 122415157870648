const creatNewAdSlot = ({ path, size, id }) => {
    const googletag = window.googletag || {};
    googletag.cmd = googletag.cmd || [];
    googletag.cmd.push(function() {
    googletag.defineSlot(path, size, id)
        .addService(googletag.pubads());
    googletag.pubads().enableSingleRequest();
    googletag.enableServices();
    });
    googletag.cmd.push(function() {
    googletag.display(id);
    });

};

export default creatNewAdSlot;