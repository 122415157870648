const filtersList = [
  {
    material: 30,
    filters: [
      {
        key: 'width',
        type: 'range',
        label: 'Largura',
        minFilter: 'wmin',
        maxFilter: 'wmax',
        minValue: 70,
        maxValue: 1850
      },
      {
        key: 'length',
        type: 'range',
        label: 'Comprimento',
        minFilter: 'lmin',
        maxFilter: 'lmax',
        minValue: 70,
        order: 1,
        maxValue: 2750
      },
      {
        key: 'condition',
        label: 'Tipo',
        type: 'select',
        filter: 'cd',
        values: ['Peça', 'Chapa', 'Capa']
      },
      {
        key: 'thickness',
        label: 'Espessura',
        type: 'select',
        filter: 'tck',
        values: [3, 4, 4.5, 5, 5.5, 6, 8, 9, 10, 12, 15, 18, 19, 20, 25, 30]
      },
      {
        key: 'frontback',
        label: 'Faces',
        type: 'select',
        filter: 'fb',
        values: ['0', '1', '2']
      },
      {
        key: 'slatwall',
        label: 'SlatWall',
        type: 'check',
        filter: 'sw'
      },
      {
        key: 'perfurado',
        label: 'Perfurado',
        type: 'check',
        filter: 'perf'
      }
    ]
  },
  {
    material: '5eb47d7bcd807c0f5798196a',
    filters: [
      {
        key: 'width',
        type: 'range',
        label: 'Largura',
        minFilter: 'wmin',
        maxFilter: 'wmax',
        minValue: 70,
        maxValue: 1850
      },
      {
        key: 'length',
        type: 'range',
        label: 'Comprimento',
        minFilter: 'lmin',
        maxFilter: 'lmax',
        minValue: 70,
        order: 1,
        maxValue: 2750
      },
      {
        key: 'condition',
        label: 'Tipo',
        type: 'select',
        filter: 'cd',
        values: ['Peça', 'Chapa', 'Capa']
      },
      {
        key: 'thickness',
        label: 'Espessura',
        type: 'select',
        filter: 'tck',
        values: [3, 4, 4.5, 5, 5.5, 6, 8, 9, 10, 12, 15, 18, 19, 20, 25, 30]
      },
      {
        key: 'frontback',
        label: 'Faces',
        type: 'select',
        filter: 'fb',
        values: ['0', '1', '2']
      },
      {
        key: 'slatwall',
        label: 'SlatWall',
        type: 'check',
        filter: 'sw'
      },
      {
        key: 'perfurado',
        label: 'Perfurado',
        type: 'check',
        filter: 'perf'
      }
    ]
  },
  {
    material: 'HDF',
    filters: [
      {
        key: 'width',
        type: 'range',
        label: 'Largura',
        minFilter: 'wmin',
        maxFilter: 'wmax',
        minValue: 70,
        maxValue: 1850
      },
      {
        key: 'length',
        type: 'range',
        label: 'Comprimento',
        minFilter: 'lmin',
        maxFilter: 'lmax',
        minValue: 70,
        order: 1,
        maxValue: 2750
      },
      {
        key: 'condition',
        label: 'Tipo',
        type: 'select',
        filter: 'cd',
        values: ['Peça', 'Chapa', 'Capa']
      },
      {
        key: 'thickness',
        label: 'Espessura',
        type: 'select',
        filter: 'tck',
        values: [9, 12, 15, 18, 25, 28, 30]
      },
      {
        key: 'frontback',
        label: 'Faces',
        type: 'select',
        filter: 'fb',
        values: ['0', '1', '2']
      },
      {
        key: 'slatwall',
        label: 'SlatWall',
        type: 'check',
        filter: 'sw'
      },
      {
        key: 'perfurado',
        label: 'Perfurado',
        type: 'check',
        filter: 'perf'
      }
    ]
  },
  {
    material: 35,
    filters: [
      {
        key: 'width',
        type: 'range',
        label: 'Largura',
        minFilter: 'wmin',
        maxFilter: 'wmax',
        minValue: 70,
        maxValue: 1850
      },
      {
        key: 'length',
        type: 'range',
        label: 'Comprimento',
        minFilter: 'lmin',
        maxFilter: 'lmax',
        minValue: 70,
        order: 1,
        maxValue: 2750
      },
      {
        key: 'condition',
        label: 'Tipo',
        type: 'select',
        filter: 'cd',
        values: ['Peça', 'Chapa', 'Capa']
      },
      {
        key: 'thickness',
        label: 'Espessura',
        type: 'select',
        filter: 'tck',
        values: [3, 4, 4.5, 5, 5.5, 6, 8, 9, 10, 12]
      },
      {
        key: 'frontback',
        label: 'Faces',
        type: 'select',
        filter: 'fb',
        values: ['0', '1', '2']
      },
      {
        key: 'perfurado',
        label: 'Perfurado',
        type: 'check',
        filter: 'perf'
      }
    ]
  },
  {
    material: 33,
    filters: [
      {
        key: 'width',
        type: 'select',
        values: [
          '19',
          '22',
          '35',
          '45',
          '64',
          '100',
          '150',
          '200',
          '260',
          '340'
        ],
        label: 'Largura',
        filter: 'wh'
      },
      {
        key: 'length',
        type: 'range',
        label: 'Comprimento',
        minFilter: 'lmin',
        maxFilter: 'lmax',
        minValue: 1,
        order: 1,
        maxValue: 1000
      },
      {
        key: 'thickness',
        label: 'Espessura',
        type: 'select',
        filter: 'tck',
        values: [0.45, 0.75, 1, 2, 3]
      },
      {
        key: 'marca',
        label: 'Marca',
        type: 'select',
        filter: 'mr',
        values: [
          'Allparts',
          'BordoPlast',
          'EB3',
          'Multimarcas',
          'Multiperfis',
          'Plastibordo',
          'Proadec',
          'Rehau',
          'Tabone',
          'Tecnofris',
          'Tegus'
        ]
      }
    ]
  },
  {
    material: 34,
    filters: [
      {
        key: 'tamanho',
        filter: 't-tam',
        type: 'select',
        label: 'Tamanho',
        values: [13, 19]
      },
      {
        key: 'cartela',
        filter: 't-ct',
        type: 'select',
        values: ['20', '40', '50', '60'],
        label: 'Cartela'
      }
    ]
  },
  {
    material: 36,
    filters: [
      {
        key: 'width',
        type: 'range',
        label: 'Largura',
        minFilter: 'wmin',
        maxFilter: 'wmax',
        minValue: 70,
        maxValue: 1850
      },
      {
        key: 'length',
        type: 'range',
        label: 'Comprimento',
        minFilter: 'lmin',
        maxFilter: 'lmax',
        minValue: 70,
        order: 1,
        maxValue: 2750
      },
      {
        key: 'condition',
        label: 'Tipo',
        type: 'select',
        filter: 'cd',
        values: ['Peça', 'Chapa', 'Capa']
      },
      {
        key: 'thickness',
        label: 'Espessura',
        type: 'select',
        filter: 'tck',
        values: [3, 4, 4.5, 5, 5.5, 6, 8, 9, 10, 12, 15, 18, 19, 20, 25, 30]
      },
      {
        key: 'frontback',
        label: 'Faces',
        type: 'select',
        filter: 'fb',
        values: ['0', '1', '2']
      },
      {
        key: 'slatwall',
        label: 'SlatWall',
        type: 'check',
        filter: 'sw'
      },
      {
        key: 'perfurado',
        label: 'Perfurado',
        type: 'check',
        filter: 'perf'
      }
    ]
  },
  {
    material: 37,
    filters: [
      {
        key: 'width',
        type: 'range',
        label: 'Largura',
        minFilter: 'wmin',
        maxFilter: 'wmax',
        minValue: 70,
        maxValue: 1850
      },
      {
        key: 'length',
        type: 'range',
        label: 'Comprimento',
        minFilter: 'lmin',
        maxFilter: 'lmax',
        minValue: 70,
        order: 1,
        maxValue: 2750
      },
      {
        key: 'condition',
        label: 'Tipo',
        type: 'select',
        filter: 'cd',
        values: ['Peça', 'Chapa', 'Capa']
      },
      {
        key: 'thickness',
        label: 'Espessura',
        type: 'select',
        filter: 'tck',
        values: [3, 4, 4.5, 5, 5.5, 6, 8, 9, 10, 12, 15, 18, 19, 20, 25, 30]
      },
      {
        key: 'frontback',
        label: 'Faces',
        type: 'select',
        filter: 'fb',
        values: ['0', '1', '2']
      },
      {
        key: 'slatwall',
        label: 'SlatWall',
        type: 'check',
        filter: 'sw'
      },
      {
        key: 'perfurado',
        label: 'Perfurado',
        type: 'check',
        filter: 'perf'
      }
    ]
  },
  {
    material: 38,
    filters: [
      {
        key: 'width',
        type: 'range',
        label: 'Largura',
        minFilter: 'wmin',
        maxFilter: 'wmax',
        minValue: 70,
        maxValue: 1850
      },
      {
        key: 'length',
        type: 'range',
        label: 'Comprimento',
        minFilter: 'lmin',
        maxFilter: 'lmax',
        minValue: 70,
        order: 1,
        maxValue: 2750
      },
      {
        key: 'condition',
        label: 'Tipo',
        type: 'select',
        filter: 'cd',
        values: ['Peça', 'Chapa', 'Capa']
      },
      {
        key: 'thickness',
        label: 'Espessura',
        type: 'select',
        filter: 'tck',
        values: [3, 4, 4.5, 5, 5.5, 6, 8, 9, 10, 12, 15, 18, 19, 20, 25, 30]
      },
      {
        key: 'frontback',
        label: 'Faces',
        type: 'select',
        filter: 'fb',
        values: ['0', '1', '2']
      },
      {
        key: 'slatwall',
        label: 'SlatWall',
        type: 'check',
        filter: 'sw'
      },
      {
        key: 'perfurado',
        label: 'Perfurado',
        type: 'check',
        filter: 'perf'
      }
    ]
  }
];

export default function requestFilters(materialId) {
  return filtersList.find(item => item.material === materialId);
}
