import React from 'react';
import {
  Box,
  InputAdornment,
  MenuItem,
  Switch,
  Typography
} from '@material-ui/core';
import CssInputText from '../CssTextField';

export const getBrand = product => {
  if (!product || !product.name) return '';
  return `${product.brand} - ${product.name}`;
};

export const getOptions = options => {
  return options.map((opt, i) => (
    <option key={i} value={opt}>
      {opt}
    </option>
  ));
};

export const getInput = spec => {
  const {
    label,
    key,
    changeFunction,
    value,
    order,
    max,
    min,
    alt,
    disabled,
    onBlur,
    type,
    unit
  } = spec;
  return (
    <Box
      key={order + key}
      margin="3px"
      display="flex"
      flex="1 1 120px"
      height="100%"
      justifyContent="center"
      marginTop=".8rem"
    >
      <CssInputText
        fullWidth
        title={alt || label}
        label={label}
        onBlurCapture={onBlur}
        type={type}
        name={key}
        value={value}
        variant="outlined"
        margin="dense"
        disabled={disabled || false}
        onChange={function(e) {
          changeFunction(e, order);
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <strong>
                <small>{unit || ''}</small>
              </strong>
            </InputAdornment>
          ),
          inputProps: {
            max: max,
            min: min
          }
        }}
      />
    </Box>
  );
};

export const getCheckbox = spec => {
  const { label, changeFunction, key, value, alt, order, unit } = spec;
  return (
    <Box
      key={order + key}
      display="flex"
      flexDirection="column"
      margin="3px"
      flex="1 1 60px"
      alignItems="center"
      justifyContent="flex-start"
      height="100%"
      marginTop=".8rem"
    >
      <Typography
        style={{ marginTop: '-10px' }}
        variant="caption"
        color="primary"
        component="small"
      >
        {unit ? `${label} ${unit}` : label}
      </Typography>
      <Switch
        title={alt || label}
        checked={value}
        name={key}
        onChange={function(e) {
          changeFunction(e, order);
        }}
        inputProps={{ 'aria-label': 'secondary checkbox' }}
      />
    </Box>
  );
};

export const getSelect = spec => {
  const {
    label,
    values,
    changeFunction,
    value,
    defaultValue,
    alt,
    order,
    unit,
    key,
    disabled
  } = spec;
  return (
    <Box
      key={order + key}
      margin="3px"
      display="flex"
      flex="1 1 100px"
      alignItems="center"
      flexDirection="column"
      height="100%"
      marginTop=".8rem"
    >
      <CssInputText
        fullWidth
        select
        title={alt || label}
        label={label}
        name={key}
        value={value}
        disabled={disabled || false}
        onChange={function(e) {
          changeFunction(e, order);
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <strong>
                <small>{unit || ''}</small>
              </strong>
            </InputAdornment>
          )
        }}
        variant="outlined"
        margin="dense"
      >
        <MenuItem key={defaultValue} value={defaultValue}>
          {defaultValue}
        </MenuItem>
        {values &&
          values.map(val => (
            <MenuItem key={val} value={val}>
              {val}
            </MenuItem>
          ))}
      </CssInputText>
    </Box>
  );
};
