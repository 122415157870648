import React, { forwardRef } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { Box, IconButton, makeStyles, Typography } from '@material-ui/core';
import { Facebook, Heart, Instagram, Twitter, Youtube } from 'react-feather';

const useStyles = makeStyles(theme => ({
  contactButtons: {
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: 'white'
    }
  },
  links: {
    padding: '.5rem'
  },
  textHover: {
    color: 'inherit',
    '&:hover': {
      color: theme.palette.primary.main
    }
  }
}));

const Page = forwardRef(({ children, title = '', ...rest }, ref) => {
  const classes = useStyles();
  return (
    <div ref={ref} {...rest}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        padding={1}
        mt={2}
      >
        <Box alignItems="center" justifyContent="center">
          <IconButton
            href="https://www.youtube.com/channel/UCRUVB0LM3cKzVrX9_PEP-qw"
            target="_blank"
            className={classes.contactButtons}
            title="Youtube"
          >
            <Youtube />
          </IconButton>
          <IconButton
            href="https://www.facebook.com/Esobras-354717258989472/"
            target="_blank"
            className={classes.contactButtons}
            title="Facebook"
          >
            <Facebook />
          </IconButton>
          <IconButton
            href="https://instagram.com/esobras.com.br?r=nametag"
            target="_blank"
            className={classes.contactButtons}
            title="Instagram"
          >
            <Instagram />
          </IconButton>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          flexWrap="wrap"
          justifyContent="center"
          mt={1}
        >
          <Typography noWrap className={classes.links} variant="body1">
            <a className={classes.textHover} href="/help">
              <em>Ajuda</em>
            </a>
          </Typography>
          <Typography noWrap className={classes.links} variant="body1">
            <a className={classes.textHover} href="/terms">
              <em>Termos de uso</em>
            </a>
          </Typography>
          <Typography noWrap className={classes.links} variant="body1">
            <a className={classes.textHover} href="/protect">
              <em>Proteja-se</em>
            </a>
          </Typography>
          <Typography noWrap className={classes.links} variant="body1">
            <a className={classes.textHover} href="/secure-sale">
              <em>Venda Segura</em>
            </a>
          </Typography>
          <Typography noWrap className={classes.links} variant="body1">
            <a className={classes.textHover} href="/about">
              <em>Sobre o e-sobras</em>
            </a>
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          <Typography component="span" align="center" variant="body1">
            feito com{' '}
          </Typography>
          <Typography color="primary" component="span">
            <small>
              <Heart size={15} />
            </small>
          </Typography>{' '}
          <Typography component="span" align="center" variant="body1">
            por
          </Typography>
          <Typography component="span" color="primary">
            <strong>
              <em> E-SOBRAS</em>
            </strong>
          </Typography>
        </Box>
      </Box>
    </div>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string
};

export default Page;
