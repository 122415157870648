import React, { useState } from 'react';
import ImagePreview from '../ImagePreview';
import { getInput, getCheckbox, getSelect } from './functions';
import { matchSorter } from 'match-sorter';
import {
  Box,
  IconButton,
  makeStyles,
  MenuItem,
  Typography,
  Switch
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import CssInputText from '../CssTextField';
import CurrencyInput from 'react-currency-input';
import { ImageOutlined } from '@material-ui/icons';
import _ from 'lodash';
import { getBrand } from 'src/views/Advertisement/functions';

const useStyles = makeStyles(theme => ({
  root: {
    border: 'solid thin',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    marginTop: theme.spacing(3),
    '&:hover, &:active, &:focus': {
      borderColor: theme.palette.primary.main
    }
  },
  search: {
    borderColor: theme.palette.primary.light,
    borderRadius: '60rem',
    minWidth: '220px',
    maxWidth: '250px',
    margin: '.3rem 0'
  },
  advertisementRow: {
    padding: '1.5rem',
    position: 'relative',
    marginBottom: 60,
    marginLeft: 'auto',
    marginRight: 'auto',
    minWidth: '320px',
    width: '100%',
    minHeight: 'max-content',
    borderRadius: '0 1rem 1rem',
    borderColor: theme.palette.primary.main,
    borderStyle: 'dashed',
    borderWidth: 'thin'
  },
  actionButtonsContainer: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(1),
    borderRadius: '1rem 1rem 0 0',
    position: 'absolute',
    top: '0',
    left: '0',
    transform: 'translateY(-100%)'
  },
  previewButton: {
    position: 'absolute',
    right: 0,
    top: '15%',
    transform: 'translateX(50%)',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      filter: 'brightness(0.8)'
    }
  },
  currency: {
    maxWidth: 350,
    '& label': {
      marginLeft: '3%',
      width: '100%',
      textAlign: 'left',
      marginTop: '-13px',
      marginBottom: '-3px',
      zIndex: 2
    },
    '& > div': {
      position: 'relative',
      width: '100%',
      height: '100%',
      padding: theme.spacing(0.8),
      backgroundColor: theme.palette.background.default,
      borderRadius: 60,
      border: `solid 3px ${theme.palette.background.default}`,
      boxShadow: theme.shadows[25],
      color: theme.palette.primary.contrastText,
      '& input': {
        fontFamily: theme.typography.fontFamily,
        width: '100%',
        height: '100%',
        backgroundColor: 'transparent',
        border: 'none',
        '&:hover, &:focus': {
          outline: 'none'
        }
      },
      '& fieldset': {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        margin: 0,
        padding: '0 8px',
        overflow: 'hidden',
        position: 'absolute',
        borderStyle: 'solid',
        borderRadius: 'inherit',
        pointerEvents: 'none',
        borderColor: 'transparent',
        borderWidth: 1
      },
      '& input:focus + fieldset': {
        borderColor: theme.palette.primary.main,
        borderWidth: 2,
        color: theme.palette.primary.main
      },
      '& input:hover + fieldset': {
        borderColor: theme.palette.primary.contrastText,
        borderWidth: 1
      }
    }
  }
}));

export default function AdvertisementRow({
  data,
  row,
  order,
  children,
  handleChange,
  handleFocus,
  handleCustomChange,
  handleMaterial,
  handleSearch,
  handleCategory,
  handleBlur,
  blockFields,
  preUnitPrice,
  prePiecePrice,
  handleDonation,
  preTotalPrice,
  isEditing,
  ...rest
}) {
  const [isHiddenPreview, setIsHiddenPreview] = useState(true);
  const [isDonation, setIsDonation] = useState(false);
  const classes = useStyles();

  const translateOptions = products => {
    return products ? products.map(product => getBrand(product)) : [];
  };

  function replaceSpecialChars(str) {
    str = str.replace(/[ÀÁÂÃÄÅ]/, 'A');
    str = str.replace(/[àáâãäå]/, 'a');
    str = str.replace(/[ùúû]/, 'u');
    str = str.replace(/[ÙÚÛ]/, 'U');
    str = str.replace(/[ìíî]/, 'i');
    str = str.replace(/[ÌÍÎ]/, 'I');
    str = str.replace(/[éèê]/, 'e');
    str = str.replace(/[óòôõ]/, 'o');
    str = str.replace(/[ÓÒÔÔ]/, 'o');
    str = str.replace(/[ÈÉÊË]/, 'E');
    str = str.replace(/[Ç]/, 'C');
    str = str.replace(/[ç]/, 'c');

    return str.replace(/[^a-z0-9]/gi, '');
  }

  const filterOptions = (options, { inputValue }) => {
    if (!inputValue) {
      return options;
    }

    var words = inputValue.split(' ');
    return words.reduceRight((items, word) => {
      console.log('items: ', items);
      word = replaceSpecialChars(word);
      return matchSorter(items, word, {
        threshold: matchSorter.rankings.CONTAINS,
        keys: [item => replaceSpecialChars(item)]
      });
    }, options);
  };

  const getFormStructureInputs = formStructure => {
    if (formStructure) {
      let sortedkeys = [];
      for (let field in formStructure) {
        let ord = formStructure[field].order;
        sortedkeys[ord] = field;
      }
      return translateKeyIntoElement(formStructure, sortedkeys);
    } else {
      return <></>;
    }
  };

  const translateKeyIntoElement = (formStructure, keys) => {
    return keys.map((key, index) => {
      const { type, max, min, alt } = formStructure[key];
      const spec = {
        ...formStructure[key],
        key,
        changeFunction: handleCustomChange,
        index: index + 1,
        value: row.customData[key],
        max,
        min,
        alt,
        order,
        onBlur: e => handleBlur(e, order)
      };
      if (type && (type === 'number' || type === 'text')) {
        return getInput(spec);
      }
      if (type && type === 'check') {
        return getCheckbox(spec);
      }
      if (type && type === 'select') {
        return getSelect(spec);
      }
      return getInput(spec);
    });
  };

  //fix react-currency-input roubando o foco no safari e edge;
  let componentDidMount_super = CurrencyInput.prototype.componentDidMount;
  CurrencyInput.prototype.componentDidMount = function() {
    if (!this.props.autoFocus) {
      let setSelectionRange_super = this.theInput.setSelectionRange;
      this.theInput.setSelectionRange = () => {};
      componentDidMount_super.call(this, ...arguments);
      this.theInput.setSelectionRange = setSelectionRange_super;
    } else {
      componentDidMount_super.call(this, ...arguments);
    }
  };
  let componentDidUpdate_super = CurrencyInput.prototype.componentDidUpdate;
  CurrencyInput.prototype.componentDidUpdate = function() {
    if (!this.props.autoFocus) {
      let setSelectionRange_super = this.theInput.setSelectionRange;
      this.theInput.setSelectionRange = () => {};
      componentDidUpdate_super.call(this, ...arguments);
      this.theInput.setSelectionRange = setSelectionRange_super;
    } else {
      componentDidMount_super.call(this, ...arguments);
    }
  };

  return (
    <form
      onSubmit={function(e) {
        e.preventDefault();
        e.stopPropagation();
      }}
      onKeyDown={function(e) {
        if (e.key === 'Enter') {
          e.preventDefault();
        }
      }}
      className={classes.advertisementRow}
    >
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        flexGrow={1}
        alignItems="center"
        justifyContent="center"
      >
        <Box maxWidth={100} className={classes.actionButtonsContainer}>
          {children}
        </Box>
        {!isEditing && (
          <>
            <Box
              margin="3px"
              display="flex"
              flex="1 1 120px"
              justifyContent="center"
              marginTop=".8rem"
            >
              <CssInputText
                name="category"
                title="Escolha o segmento"
                fullWidth
                select
                variant="outlined"
                defaultValue="default"
                margin="dense"
                value={row.category.name}
                onChange={e => handleCategory(e, order)}
              >
                <MenuItem value="default">
                  <em>Segmento</em>
                </MenuItem>
                {data.categoryNames &&
                  data.categoryNames.map(value => (
                    <MenuItem key={order + value + Date.now()} value={value}>
                      {value}
                    </MenuItem>
                  ))}
              </CssInputText>
            </Box>
            <Box
              marginTop=".8rem"
              margin="3px"
              display="flex"
              flex="1 1 100px"
              justifyContent="center"
            >
              <CssInputText
                name="material"
                title="Escolha o Material"
                select
                fullWidth
                variant="outlined"
                defaultValue="default"
                margin="dense"
                value={row.material.name}
                disabled={_.isBoolean(blockFields) && blockFields}
                onChange={e => handleMaterial(e, order)}
              >
                <MenuItem value="default">
                  <em>Material</em>
                </MenuItem>
                {data.materialNames &&
                  data.materialNames.map(value => (
                    <MenuItem key={order + value + Date.now()} value={value}>
                      {value}
                    </MenuItem>
                  ))}
              </CssInputText>
            </Box>
          </>
        )}
        <Box
          marginTop=".8rem"
          margin="3px"
          display="flex"
          flex="1 1 60px"
          justifyContent="center"
        >
          <CssInputText
            title="Quantidade"
            disabled={blockFields}
            type="number"
            label="Quant."
            name="amount"
            onKeyDown={e =>
              (e.key === '.' || e.key === 'e') && e.preventDefault()
            }
            variant="outlined"
            margin="dense"
            fullWidth
            value={row.amount}
            onBlur={e => handleBlur(e, order)}
            onChange={e => handleChange(e, order)}
          />
        </Box>
        <Box
          marginTop=".8rem"
          margin="3px"
          display="flex"
          flex="1 1 250px"
          justifyContent="center"
        >
          <Autocomplete
            fullWidth
            disabled={row.blockSearch}
            options={translateOptions(data.products)}
            filterOptions={filterOptions}
            selectOnFocus
            autoHighlight
            openOnFocus
            onChange={function(e, values) {
              handleSearch(e, values, order);
            }}
            value={row.productId ? getBrand(row.product) : ''}
            noOptionsText="Selecione um material..."
            style={{ width: '100%' }}
            renderInput={params => (
              <CssInputText
                title="Busque pelo produto desejado"
                margin="dense"
                label="Busque pelo produto"
                variant="outlined"
                {...params}
              />
            )}
          />
        </Box>
        {!blockFields && getFormStructureInputs(row.material.formStructure)}
        {row.unit && row.unit !== 'un' && (
          <Box
            marginTop=".8rem"
            margin="3px"
            display="flex"
            flex="1 1 80px"
            justifyContent="center"
          >
            <CssInputText
              fullWidth
              title="Medida Calculada"
              disabled={true}
              type="number"
              label={`${row.unitLabel || 'Medida'}`}
              name="measure"
              value={row.measure}
              variant="outlined"
              margin="dense"
            />
          </Box>
        )}
        <Box
          marginTop=".8rem"
          margin="3px"
          display="flex"
          flex="1 1 110px"
          justifyContent="center"
        >
          <CssInputText
            fullWidth
            title="Medida Total"
            disabled={true}
            type="number"
            label={`Total ${row.unitLabel || 'Medida'}`}
            name="totalMeasure"
            value={row.totalMeasure}
            variant="outlined"
            margin="dense"
          />
        </Box>
        <Box
          marginTop=".8rem"
          margin="3px"
          display="flex"
          flex="1 2 105px"
          justifyContent="center"
          flexWrap="wrap"
          className={classes.currency}
        >
          <Typography component="label" variant="caption" color="primary">
            {`R$ p/ ${row.unitLabel || 'Medida'}`}
          </Typography>
          <Box>
            <CurrencyInput
              title={`Preço por ${row.unitLabel || 'Medida'}`}
              value={Number(row.unitPrice)}
              prefix="R$"
              disabled={row.isDonation}
              decimalSeparator=","
              thousandSeparator="."
              precision="2"
              autoFocus=""
              selectAllOnFocus={true}
              onChange={(e, a) => preUnitPrice(e, a, order)}
            />
            <fieldset></fieldset>
          </Box>
        </Box>
        <Box
          marginTop=".8rem"
          margin="3px"
          display="flex"
          flex="1 2 105px"
          justifyContent="center"
          flexWrap="wrap"
          className={classes.currency}
        >
          <Typography component="label" variant="caption" color="primary">
            R$ Peça (Un.)
          </Typography>
          <Box>
            <CurrencyInput
              title="Preço Peça"
              value={Number(row.piecePrice)}
              prefix="R$"
              decimalSeparator=","
              thousandSeparator="."
              precision="2"
              autoFocus=""
              disabled={row.isDonation}
              selectAllOnFocus={true}
              onChange={(e, a, c) => prePiecePrice(e, a, order)}
            />
            <fieldset></fieldset>
          </Box>
        </Box>
        <Box
          marginTop=".8rem"
          margin="3px"
          display="flex"
          flex="1 2 90px"
          justifyContent="center"
          flexWrap="wrap"
          className={classes.currency}
        >
          <Typography component="label" variant="caption" color="primary">
            R$ Total
          </Typography>
          <Box>
            <CurrencyInput
              title="Preço total"
              value={Number(row.totalPrice)}
              disabled={true}
              prefix="R$"
              decimalSeparator=","
              thousandSeparator="."
              precision="2"
              autoFocus=""
              selectAllOnFocus={true}
              onChange={(e, a, c) => preTotalPrice(e, a, order)}
            />
            <fieldset></fieldset>
          </Box>
        </Box>
        <Box
          marginTop=".8rem"
          margin="3px"
          display="flex"
          flex="1 1 80px"
          justifyContent="left"
        >
          <CssInputText
            title="% Desconto à Vista"
            disabled={blockFields || row.isDonation}
            type="number"
            onKeyDown={e =>
              (e.key === '.' || e.key === 'e') && e.preventDefault()
            }
            label="Desc.(%)."
            name="percentOff"
            variant="outlined"
            margin="dense"
            fullWidth
            value={row.percentOff}
            onBlur={e => handleBlur(e, order)}
            onChange={e => handleChange(e, order)}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          margin="3px"
          flex="1 1 60px"
          alignItems="center"
          justifyContent="flex-start"
          height="100%"
          marginTop=".8rem"
        >
          <Typography
            style={{ marginTop: '-10px' }}
            variant="caption"
            color="primary"
            component="small"
          >
            Doação?
          </Typography>
          <Box>
            <Switch
              title="Esta sobra será doada?"
              color="primary"
              checked={row.isDonation}
              name={'doa' + order}
              onChange={function(e) {
                handleDonation(!row.isDonation, order);
              }}
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </Box>
        </Box>
        <Box>
          <IconButton
            className={classes.previewButton}
            title="Preview"
            disabled={blockFields}
            onClick={() => setIsHiddenPreview(!isHiddenPreview)}
          >
            <ImageOutlined />
          </IconButton>
          <ImagePreview
            hidden={isHiddenPreview}
            isDefault={row.unit && row.unit !== 'm²'}
            alt={row.product.name}
            img={row.product.imageUrl}
            caption="Preview"
            height={row.customData.length || 0}
            width={row.customData.width || 0}
            callback={val => setIsHiddenPreview(val)}
          />
        </Box>
      </Box>
    </form>
  );
}
