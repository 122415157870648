import React, { useEffect } from 'react';
import querystring from 'querystring';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchUserDetails,
  selectAvertisementSearch
} from './AdvertisementSearchReducer';
import Search from '../../components/Search';
import Breadcrumbs from '../../components/Breadcrumbs';
import ClipboardButton from '../../components/ClipboardButton';
import ResultsCard from '../../components/ResultsCard';
import Loading from '../../components/Loading';
import Api from 'src/api/Api';
import { HeaderImage } from './styles';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router';
import {
  selectSearchState,
  fetchSegments,
  searchResults
} from '../../components/Search/SearchReducer';
import Filters from '../../components/Filter';
import {
  searchByFilters,
  queryFilters,
  setFilters,
  removeFilter,
  selectFiltersState
} from '../../components/Filter/FilterReducer';
import LocationFilter from '../../components/LocationFilter';
import { useState } from 'react';
import PopMenu from '../../components/PopMenu';
import {
  Facebook,
  Mail,
  Twitter,
  Whatsapp
} from 'react-social-sharing/dist-modules';
import CustomPopUp from 'src/components/CustomPopUp';
import Page from 'src/components/Page';
import {
  Box,
  Container,
  makeStyles,
  MenuItem,
  Typography,
  Tooltip,
  IconButton,
  Button
} from '@material-ui/core';
import OutlineCssButton from 'src/components/OutlineCssButton';
import { ShareOutlined } from '@material-ui/icons';
import { Pagination } from '@material-ui/lab';
import CssInputText from 'src/components/CssTextField';
import { generateUniqueId } from 'src/utils';
import AdBuilder from '../../components/AdManager';
import useMobileDetect from '../../utils/useMobileDetect';
import UserCardResumedDetails from '../../components/UserCardResumedDetails';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingBottom: theme.spacing(3)
  }
}));

export default function AdvertisementSearch() {
  const [shouldViewShare, setShouldViewShare] = useState(false);
  const [anchorElement, setAnchorElement] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [isAdvertiserPage, setIsAdvertiserPage] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { headerImgUrl, error, errorMessage, user } = useSelector(
    selectAvertisementSearch
  );
  const {
    results,
    pagination,
    selectedProduct,
    selectedMaterial,
    selectedSegment
  } = useSelector(selectSearchState);

  const { filters } = useSelector(selectFiltersState);

  const shareUrl = document.URL;

  const detectMobile = useMobileDetect();

  useEffect(() => {
    setLoading(true);
    const getObjects = async () => {
      const query = window.location.search;
      await dispatch(fetchSegments());
      if (query.includes('userId')) {
        const qs = querystring.parse(query.replace('?', ''));
        dispatch(fetchUserDetails(qs.userId));
      }
      if (query) {
        await dispatch(queryFilters(query));
        await dispatch(searchResults(query));
      }
      setLoading(false);
    };
    getObjects();
  }, [window.location.search]);

  useEffect(() => {
    if (error) {
      setAlertMessage(errorMessage);
      setShowAlert(true);
    }
  }, [error]);

  useEffect(() => {
    if (user && user.name) {
      setIsAdvertiserPage(true);
    }
  }, [user]);

  const handleSaveSearchAlert = async () => {
    const response = await Api.validate();
    if (response.valid) {
      let payload = {
        materialId: selectedMaterial.id
      };
      if (selectedProduct.id) {
        payload['productId'] = selectedProduct.id;
      } else {
        payload['modelBrand'] = filters.mb;
      }
      if (filters.s) {
        payload['state'] = filters.s;
      }
      if (filters.c) {
        payload['city'] = filters.c;
      }
      const searchAlertResponse = await Api.createSearchAlert(payload);
      if (searchAlertResponse.error) {
        setAlertMessage(
          'Erro ao adicionar o alerta de ofertas :( \nPor favor tente novamente!'
        );
      } else {
        setAlertMessage('Alerta de oferta adicionado com sucesso!');
      }
      setShowAlert(true);
    } else {
      localStorage.setItem('lastUrl', document.URL);
      navigate('/login', { replace: true });
    }
  };
  const handlePageClick = async (event, value) => {
    if (value <= pagination.totalPages) {
      await dispatch(
        setFilters({
          key: 'page',
          value
        })
      );
      await dispatch(searchByFilters());
    }
  };

  const getResults = () => {
    if (results && results.length > 0) {
      return results.map((result, index) => (
        <>
          {(index % 3 == 0) && index != 0 && (
              <Box
                display="flex"
                flexDirection="column"
                width="100%"
                padding={1}
                marginTop={2}
                className={classes.resultsWrapper}
              >
                {detectMobile.isDesktop() && (
                  <AdBuilder
                    style={{ margin: '0 auto', marginTop: '10px' }}
                    size="[728,90]"
                    id={`ad-banner-na-728x90-${index}`}
                  ></AdBuilder>
                )}
                {!detectMobile.isDesktop() && (
                  <AdBuilder
                    style={{ margin: '0 auto', marginTop: '10px' }}
                    size="[300,250]"
                    id={`ad-banner-na-300x250-${index}`}
                  ></AdBuilder>
                )}
              </Box>
          )}
          <ResultsCard key={index} shareUrl={shareUrl} ad={result} />
        </>
      ));
    } else {
      return (
        <Box marginTop={10}>
          <Typography
            style={{ marginBottom: 10 }}
            align="center"
            variant="body1"
          >
            {isAdvertiserPage
              ? 'Este usuário ainda não possui anúncios : ('
              : 'Não Foram encontrados resultados para a sua busca :('}
          </Typography>
          {!isAdvertiserPage && (
            <Box
              bgcolor="#f9ffe6"
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              width="100%"
              paddingLeft="1rem"
              paddingRight="1rem"
              paddingBottom="2rem"
              paddingTop="2rem"
              borderRadius={10}
            >
              <Typography
                align="center"
                variant="body1"
                component="span"
                variant="body1"
              >
                <i>
                  Deseja ser avisado quando esta sobra for cadastrada na
                  plataforma?
                </i>
              </Typography>
              <Typography
                style={{ marginTop: '15px' }}
                align="center"
                variant="body1"
              >
                <Button
                  variant="contained"
                  onClick={e => {
                    handleSaveSearchAlert();
                  }}
                >
                  {' '}
                  <b>Clique Aqui</b>
                </Button>
              </Typography>
            </Box>
          )}

          {detectMobile.isDesktop() && (
            <AdBuilder
              style={{ margin: '0 auto', marginTop: '10px' }}
              size="[300,250]"
              id="ad-banner-300x250"
            ></AdBuilder>
          )}
          {detectMobile.isMobile() && (
            <>
              <AdBuilder
                style={{ margin: '10px auto 0px' }}
                size="[300,250]"
                id="ad-banner-300x250-2"
              ></AdBuilder>
              {/*<AdBuilder
                style={{ margin: '10px auto 0px'}}
                size="[320,50]"
                id="ad-banner-320x50"
              ></AdBuilder> */}
            </>
          )}
        </Box>
      );
    }
  };

  const filter = async () => {
    await dispatch(removeFilter({ key: 'page' }));
    dispatch(searchByFilters());
  };

  const handleOrderFilter = async e => {
    let value = e.target.value;
    await dispatch(setFilters({ key: 'sort', value }));
    await dispatch(removeFilter({ key: 'page' }));
    filter();
  };

  const getOrderByOptions = () => [
    { name: 'Mais Recente', value: 'date_desc' },
    { name: 'Mais Antigo', value: 'date_asc' },
    { name: 'Maior Preço', value: 'price_desc' },
    { name: 'Menor Preço', value: 'price_asc' },
    { name: 'Maior Quantidade', value: 'amount_desc' },
    { name: 'Menor Quantidade', value: 'amount_asc' }
  ];

  const getStringBreadcrumbs = () => {
    let data = selectedMaterial.name;
    if (selectedProduct.id) {
      if (selectedProduct.brand) {
        data = data
          .concat(' - ')
          .concat(selectedProduct.brand)
          .concat(' - ')
          .concat(selectedProduct.name);
      } else {
        data = data.concat(' - ').concat(selectedProduct.name);
      }
    }
    return data;
  };

  const getBreadcrumbsData = () => {
    const breads = [
      {
        name: selectedSegment.name,
        link: isMobile ? '#search-results' : ''
      },
      {
        name: selectedMaterial.name,
        link: `?categoryId=${selectedSegment.id}&material=${selectedMaterial.id}`
      }
    ];

    if (selectedProduct.id) {
      breads.push(
        {
          name: selectedProduct.brand,
          link: `?categoryId=${selectedSegment.id}&material=${selectedMaterial.id}&mb=${selectedProduct.brand}`
        },
        {
          name: selectedProduct.name,
          link: `?categoryId=${selectedSegment.id}&material=${selectedMaterial.id}&productId=${selectedProduct.id}`
        }
      );
    } else {
      breads.push({
        name: filters.mb,
        link: `?categoryId=${selectedSegment.id}&material=${selectedMaterial.id}&mb=${filters.mb}`
      });
    }

    return breads;
  };

  return (
    <Page className={classes.root} title="E-sobras Buscar por:">
      <CustomPopUp
        message={alertMessage}
        visible={showAlert}
        setShow={setShowAlert}
        defaultActionTitle="OK"
      />
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
        marginTop={3}
      >
        <Loading open={loading} />
        <HeaderImage image={headerImgUrl}>
          <Container maxWidth="lg">
            <Search placeholder="Buscar" />
          </Container>
        </HeaderImage>
        <Container maxWidth="lg" disableGutters>
          {detectMobile.isDesktop() && (
            <AdBuilder
              style={{ margin: '24px auto 0px' }}
              size="[728,90]"
              id="ad-banner-728x90-2"
            ></AdBuilder>
          )}
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            flex="1"
            width="100%"
            flexWrap="wrap"
            padding={3}
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              flex="1 0 250px"
              marginTop={3}
            >
              {isAdvertiserPage && <UserCardResumedDetails user={user} />}
              {!isAdvertiserPage && <Breadcrumbs data={getBreadcrumbsData()} />}
              {detectMobile.isMobile() && (
                <AdBuilder
                  style={{ margin: '10px auto 0px' }}
                  size="[300,250]"
                  id="ad-banner-300x250"
                ></AdBuilder>
              )}
              <Box
                marginTop={5}
                display="flex"
                flexDirection="column"
                width="85%"
              >
                <Typography gutterBottom variant="body1" component="p">
                  Ordenar Por:
                </Typography>
                <CssInputText
                  defaultValue="date_desc"
                  variant="outlined"
                  fullWidth
                  onChange={handleOrderFilter}
                  select
                  key={generateUniqueId('sort')}
                  value={filters.sort}
                >
                  {getOrderByOptions().map(item => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.name}
                    </MenuItem>
                  ))}
                </CssInputText>
              </Box>
              {!isAdvertiserPage && <LocationFilter />}
              <Box mt={5} display="flex" flexDirection="column" width="85%">
                <Typography gutterBottom variant="body1" component="p">
                  Especificações:
                </Typography>
                {
                  <Filters
                    material={selectedMaterial.id}
                    handleFilter={filter}
                  />
                }
              </Box>
            </Box>
            <Box
              id="search-results"
              display="flex"
              flexDirection="column"
              alignItems="center"
              flex="5 0 350px"
              marginTop={5}
            >
              <Box
                display="flex"
                flexWrap="wrap"
                alignItems="center"
                width="100%"
                justifyContent="space-between"
                px={3}
              >
                <Typography variant="body1" gutterBottom component="p">
                  {selectedProduct && Object.keys(selectedProduct).length > 0
                    ? `${selectedProduct.brand} - ${selectedProduct.name}`
                    : filters.mb || ''}
                </Typography>
                {Array.isArray(results) && results.length > 0 ? (
                  <>
                    <OutlineCssButton
                      onClick={event => {
                        setAnchorElement(event.currentTarget);
                        setShouldViewShare(!shouldViewShare);
                      }}
                      position="relative"
                      color="primary"
                    >
                      <ShareOutlined />
                      Compartilhar Pesquisa
                      <PopMenu element={anchorElement} open={shouldViewShare}>
                        <Tooltip
                          title="Compartilhar por Whatsapp."
                          placement="top"
                        >
                          <IconButton size="small">
                            <Whatsapp
                              solidcircle
                              small
                              link={shareUrl}
                              message={`Olá, olha a lista de produtos ${
                                isAdvertiserPage
                                  ? `de *${user.name}* no`
                                  : 'que encontrei dentro do'
                              } E-sobras: ${
                                isAdvertiserPage
                                  ? ''
                                  : `\n\n*Produto: ${getStringBreadcrumbs()}*\n\n`
                              }`}
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title="Compartilhar por E-mail."
                          placement="top"
                        >
                          <IconButton size="small">
                            <Mail solidcircle small link={shareUrl} />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title="Compartilhar no Facebook."
                          placement="top"
                        >
                          <IconButton size="small">
                            <Facebook solidcircle small link={shareUrl} />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Copiar link" placement="top">
                          <IconButton size="small">
                            <ClipboardButton
                              title="Copiar link"
                              link={shareUrl}
                            />
                          </IconButton>
                        </Tooltip>
                      </PopMenu>
                    </OutlineCssButton>
                  </>
                ) : (
                  ''
                )}
                <Typography variant="caption" component="small">
                  {`${
                    pagination && pagination.hasOwnProperty('totalDocs')
                      ? pagination.totalDocs
                      : '0'
                  } anúncio(s)`}
                </Typography>
              </Box>
              {getResults()}
              {results && results.length > 0 && (
                <Pagination
                  id="pagination"
                  onChange={handlePageClick}
                  defaultPage={1}
                  page={Number(filters.page) || 1}
                  showFirstButton
                  showLastButton
                  count={pagination.totalPages}
                  size="medium"
                  color="primary"
                />
              )}
            </Box>
          </Box>
        </Container>
      </Box>
    </Page>
  );
}
