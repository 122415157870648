import React, { useEffect, useState } from 'react';
import { Container, Grid, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import ProfileDetails from './ProfileDetails';
import { getLocalUser } from 'src/utils/user';
import Loading from 'src/components/Loading';
import CustomPopUp from 'src/components/CustomPopUp';
import Api from 'src/api/Api';
import { CONSTANTS } from 'src/config';
import AdBuilder from '../../../components/AdManager';
import useMobileDetect from '../../../utils/useMobileDetect';
import { useNavigate } from 'react-router';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Account = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [user, setUser] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState('');
  const detectMobile = useMobileDetect();

  async function checkLoggedUser() {
    setLoading(true);
    const response = await Api.validate();
    if (response.error) {
      navigate('/login', { replace: true });
    }
    setLoading(false);
  }

  useEffect(() => {
    checkLoggedUser();
    setUser(getLocalUser());
  }, []);

  const handleSave = async values => {
    setLoading(true);
    const newSet = { ...user, ...values };
    setUser(newSet);
    const response = await Api.updateUser(newSet);
    if (response.error) {
      setMessage(
        response.error ||
          'Erro ao salvar alterações, por favor tente novamente.'
      );
    } else {
      localStorage.setItem(CONSTANTS.userKey, JSON.stringify(response));
      setMessage('Alterações salvas com sucesso!');
    }
    setShowAlert(true);
    setLoading(false);
  };

  return (
    <Page className={classes.root} title="Perfil">
      <Container maxWidth="lg">
        <Loading open={loading} />
        {detectMobile.isDesktop() && (
          <div
            style={{
              margin: '0px auto 24px',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <AdBuilder size="[728,90]" id="ad-banner-728x90"></AdBuilder>
          </div>
        )}
        {detectMobile.isMobile() && (
          <div
            style={{
              margin: '0px auto 24px',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <AdBuilder size="[320,100]" id="ad-banner-320x100"></AdBuilder>
          </div>
        )}
        <CustomPopUp
          message={message}
          visible={showAlert}
          setShow={setShowAlert}
        />
        <Grid container spacing={3}>
          <ProfileDetails
            user={user}
            setLoading={setLoading}
            setMessage={setMessage}
            showAlert={setShowAlert}
            reload={setUser}
            handleSave={handleSave}
          />
        </Grid>
        {detectMobile.isMobile() && (
          <div
            style={{
              margin: '24px auto 0px',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <AdBuilder size="[300,250]" id="ad-banner-300x250"></AdBuilder>
          </div>
        )}
      </Container>
    </Page>
  );
};

export default Account;
