import { Box, Button, Typography, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { CONSTANTS } from 'src/config';
import theme from 'src/theme';

const useStyles = makeStyles(theme => ({
  root: {},
  box: {
    '&:hidden': {
      display: 'none'
    }
  }
}));

function CookiesAcceptanceManger() {
  const classes = useStyles();
  const [hidden, setHidden] = useState(false);
  function checkAcceptance() {
    console.log('COOKIES: ', localStorage.getItem(CONSTANTS.cookiesKey));
    return !!localStorage.getItem(CONSTANTS.cookiesKey);
  }
  useEffect(() => {
    if (checkAcceptance()) {
      setHidden(true);
    }
  }, [checkAcceptance]);
  function handleAccepted() {
    localStorage.setItem(CONSTANTS.cookiesKey, 'OK');
    setHidden(true);
  }
  return (
    <Box
      className={classes.box}
      width="100%"
      position="absolute"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      padding={3}
      boxShadow={`10px 10px 10px 20px rgba(0,0,0,0.2)`}
      bgcolor="rgba(0,0,0,0.85)"
      zIndex={999999}
      bottom={0}
      style={{ display: hidden ? 'none' : 'flex' }}
    >
      <Typography
        color="textSecondary"
        component="p"
        variant="caption"
        align="center"
      >
        Usamos cookies para personalizar o conteúdo, adaptar e medir anúncios,
        além de oferecer uma experiência mais segura a você. Ao continuar a
        navegação em nosso site, você concorda com o uso dessas informações.
        Leia nossa{' '}
        <a
          href="/cookies-policy"
          target="_blank"
          style={{ font: 'inherit', color: theme.palette.secondary.main }}
        >
          política de cookies
        </a>{' '}
        e saiba mais.
      </Typography>
      <Button
        onClick={handleAccepted}
        variant="contained"
        color="primary"
        margin="dense"
      >
        OK, entendi
      </Button>
    </Box>
  );
}

export default CookiesAcceptanceManger;
