import React from 'react';
import { Link, Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  Typography,
  Button
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import OutlineCssButton from 'src/components/OutlineCssButton';
import { getUserAttribute } from 'src/utils/user';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'transparent'
  },
  avatar: {
    width: 60,
    height: 60
  },
  addButton: {
    color: 'white',
    fontWeight: 'bold'
  }
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
      <Toolbar>
        <RouterLink to="/">
          <Typography color="primary" variant="h3">
            <strong>e-sobras</strong>{' '}
            <Typography variant="caption" color="primary">
              <small>beta</small>
            </Typography>
          </Typography>
        </RouterLink>
        <Box flexGrow={1} />
        <Hidden mdDown>
          <Box margin={0} mr={2} padding={0}>
            <Button
              variant="contained"
              color="primary"
              className={classes.addButton}
              href="/app/advertisement"
            >
              + Anunciar uma sobra
            </Button>
          </Box>
          <OutlineCssButton color="primary" variant="outlined">
            <Link
              style={{ color: 'inherit', width: '100%', height: '100%' }}
              to="/app/profile"
            >
              {getUserAttribute('name')}
            </Link>
          </OutlineCssButton>
        </Hidden>
        <IconButton color="primary" onClick={onMobileNavOpen}>
          <MenuIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
