import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import {
  ImagePreviewWrapper,
  ImagePreviewContainerHeader,
  Thumbnail,
  ThumbnailContainer,
  Width,
  Height
} from './styles';
import { Box } from '@material-ui/core';

export function ImagePreview({
  maxHeight = 200,
  maxWidth = 300,
  width,
  height,
  hidden,
  caption,
  img,
  callback,
  isStatic,
  position,
  isDefault,
  bordered = true
}) {
  const [state, setState] = useState({
    width: '100px',
    height: '100px',
    originalHeight: 0,
    originalWidth: 0,
    hidden,
    rotate: false,
    isDefault,
    isMobile: false,
    bordered
  });

  useEffect(() => {
    setState(old => ({
      ...old,
      hidden
    }));
  }, [hidden]);

  useEffect(() => {
    if (window.innerWidth <= 400) {
      setState({
        isMobile: true
      });
    }
  }, []);

  function setProportionalWidth(width, height, isDefault) {
    let rotate;
    let aspect;
    let newWidth;
    let newHeight;

    if (isDefault) {
      rotate = false;
      newWidth = 45;
      newHeight = maxHeight;
    } else {
      rotate = width < height;
      aspect = !rotate ? height / width : width / height;
      if (aspect < 0.25) {
        aspect = 0.25;
      }
      newWidth = rotate ? aspect * maxHeight : maxHeight;
      newHeight = rotate ? maxHeight : aspect * maxHeight;
    }

    return {
      rotate,
      width: state.isMobile ? maxWidth : newWidth + 'px',
      height: newHeight + 'px',
      originalHeight: !isNaN(height) ? height : 0,
      originalWidth: !isNaN(width) ? width : 0
    };
  }

  function close() {
    setState({
      ...state,
      hidden: true
    });
    callback(true);
  }

  return (
    <ImagePreviewWrapper
      bordered={bordered}
      position={position}
      isStatic={isStatic}
      onClickCapture={function(e) {
        if (e.target.tagName === 'SPAN' || e.target.tagName === 'SECTION') {
          e.preventDefault();
          e.stopPropagation();
        }
      }}
      style={{ display: state.hidden ? 'none' : 'flex' }}
      hidden={state.hidden}
    >
      <ImagePreviewContainerHeader>
        <span>{caption}</span>
        {!isStatic && (
          <button onClick={close}>
            <small>
              <strong>&#9587;</strong>
            </small>
          </button>
        )}
      </ImagePreviewContainerHeader>
      <ThumbnailContainer>
        <Box
          display="flex"
          flex="1"
          flexDirection="column"
          position="relative"
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          <Width>
            <strong>{width ? width + ' L' : ''}</strong>
          </Width>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{
              width: setProportionalWidth(width, height, isDefault).width,
              height: setProportionalWidth(width, height, isDefault).height
            }}
          >
            <Thumbnail img={img} />
          </Box>
          <Height>
            <strong>{height ? height + ' C' : ''}</strong>
          </Height>
        </Box>
      </ThumbnailContainer>
    </ImagePreviewWrapper>
  );
}

ImagePreview.propTypes = {
  img: PropTypes.string,
  caption: PropTypes.string,
  maxHeight: PropTypes.number,
  height: PropTypes.number,
  width: PropTypes.number,
  callback: PropTypes.func
};

export default ImagePreview;
