import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
  Typography,
  IconButton,
  Avatar,
  Dialog,
  DialogActions,
  CircularProgress,
  DialogTitle,
  DialogContent
} from '@material-ui/core';
import { useEffect } from 'react';
import CepInput from 'src/components/CepInput';
import PhoneInput from 'src/components/PhoneInput';
import Api from 'src/api/Api';
import moment from 'moment';
import { Formik } from 'formik';
import * as Yup from 'yup';
import theme from 'src/theme';

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    height: 100,
    width: 100
  },
  roundIconAvatar: {
    position: 'relative',
    '&:hover #hoverFile': {
      display: 'flex'
    }
  },
  label: {
    position: 'absolute',
    display: 'flex',
    top: 0,
    width: '100%',
    height: '100%',
    left: 0,
    zIndex: 30,
    background: 'transparent',
    color: 'transparent',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  inputFile: {
    display: 'none'
  },
  hoverFile: {
    position: 'absolute',
    display: 'none',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.4)',
    color: 'white',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 10,
    borderRadius: 60
  },
  submitButton: {
    borderRadius: 60,
    marginTop: theme.spacing(2),
    border: `3px solid ${theme.palette.background.default}`,
    boxShadow: theme.shadows[26],
    color: 'white',
    '&:hover': {
      boxShadow: theme.shadows[25]
    }
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '44%',
    left: '48%'
  }
}));

const ProfileDetails = ({
  className,
  user,
  reload,
  showAlert,
  setMessage,
  setLoading,
  handleSave,
  ...rest
}) => {
  const classes = useStyles();
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [values, setValues] = useState({
    name: 'E-sobras',
    surname: 'sobras',
    email: 'example@esobras.com',
    phone: '',
    cellPhone: '',
    state: 'Minas Gerais',
    city: 'Belo Horizonte',
    neighborhood: 'Itapoã',
    cep: '',
    active: true,
    userImage:
      'https://esobras-imgs.s3.amazonaws.com/ffc9bd99597903694029ca70404c1f0b-avatar.png'
  });

  const uploadFile = async event => {
    const files = event.target.files;
    if (files.length > 0) {
      renderImage(files[0]);
      const formData = new FormData();
      formData.append('file', files[0], files[0].name);
      setLoading(true);
      const response = await Api.uploadImage(formData);
      if (response.url) {
        const newSet = { ...values, userImage: response.url };
        reload(newSet);
      }
    }
    setLoading(false);
  };

  const renderImage = file => {
    var reader = new FileReader();
    reader.onload = function(event) {
      setValues({ ...values, userImage: event.target.result });
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    if (user) setValues({ ...user });
  }, [user]);

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleCep = async cep => {
    setLoading(true);
    const response = await Api.getAddress(cep);
    if (response.error) {
      setMessage(
        'Erro ao buscar CEP, por favor verifique o cep digitado e tente novamente'
      );
      showAlert(true);
    } else {
      const { localidade: city, uf: state, bairro: neighborhood } = response;
      setValues({ ...values, city, state, neighborhood });
    }
    setLoading(false);
  };

  const handleUpdate = () => handleSave(values);

  const handleDeactivate = async () => {
    setLoading(true);
    const { error } = await Api.inactivate(user.email);
    if (error) {
      setMessage(
        error.message
          ? error.message
          : error.error || 'Erro ao inativar usuário!'
      );
      showAlert(true);
    } else {
      setMessage(
        'Usuário desativado! Para reativar, acesse https://esobras.com.br/login  e entre com usuário e senha. \n Você receberá um email com um link de ativação. ;)'
      );
      showAlert(true);
      setTimeout(() => {
        localStorage.clear();
        window.location = '/';
      }, 6000);
    }
    setLoading(false);
  };

  const handleChangePassword = async formValues => {
    if (formValues) {
      if (formValues.password !== formValues.confirmation) {
        setMessage('Senhas não conferem!');
        showAlert(true);
        return;
      }
      setLoading(true);
      const { error } = await Api.changePassword(formValues);
      if (error) {
        setMessage(
          error.message
            ? error.message
            : error.error || 'Erro ao alterar senha! Tente novamente.'
        );
        showAlert(true);
      } else {
        setMessage('Senha alterada com sucesso, redirecionando para login.');
        showAlert(true);
        setTimeout(() => {
          localStorage.clear();
          window.location = '/login';
        }, 4000);
      }
      setLoading(false);
    }
  };

  return (
    <>
      <Grid item lg={4} md={6} xs={12}>
        <Dialog
          open={showChangePassword}
          onClose={() => setShowChangePassword(false)}
        >
          <DialogTitle>{'e-sobras'}</DialogTitle>
          <DialogContent>
            <Formik
              initialValues={{
                oldPassword: '',
                password: '',
                confirmation: ''
              }}
              validationSchema={Yup.object().shape({
                oldPassword: Yup.string()
                  .min(3)
                  .max(12)
                  .required('Senha antiga obrigatória!'),
                password: Yup.string()
                  .max(12)
                  .min(3)
                  .required('Senha obrigatória'),
                confirmation: Yup.string()
                  .max(12)
                  .min(3)
                  .required('Confirmação obrigatória')
              })}
              onSubmit={handleChangePassword}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box display="flex" justifyContent="center" mb={3}>
                    <Typography className="bold" color="primary" variant="h2">
                      Alterar Senha.
                    </Typography>
                  </Box>

                  <TextField
                    error={Boolean(touched.oldPassword && errors.oldPassword)}
                    fullWidth
                    helperText={touched.oldPassword && errors.oldPassword}
                    name="oldPassword"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                    label="Senha Antiga"
                    variant="outlined"
                    value={values.oldPassword}
                  />
                  <TextField
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    label="Nova Senha"
                    margin="normal"
                    name="password"
                    type="password"
                    variant="outlined"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                  />
                  <TextField
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    label="Confirmar Nova Senha"
                    margin="normal"
                    name="confirmation"
                    type="password"
                    variant="outlined"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.confirmation}
                  />
                  <Box
                    my={2}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    position="relative"
                  >
                    <Button
                      fullWidth
                      color="primary"
                      disabled={isLoading}
                      size="large"
                      type="submit"
                      variant="contained"
                      className={classes.submitButton}
                    >
                      Alterar
                    </Button>
                    {isLoading && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </Box>
                </form>
              )}
            </Formik>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setShowChangePassword(false)}
              color="primary"
            >
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
        <Card className={clsx(classes.root, className)} {...rest}>
          <CardContent>
            <Box alignItems="center" display="flex" flexDirection="column">
              <IconButton
                className={classes.roundIconAvatar}
                title="Alterar imagem"
              >
                <div id="hoverFile" className={classes.hoverFile}>
                  <Typography
                    component="small"
                    variant="caption"
                    color="primary"
                  >
                    <strong>Trocar Imagem</strong>
                  </Typography>
                </div>
                <label htmlFor="file" className={classes.label}>
                  Editar
                </label>
                <input
                  onChange={uploadFile}
                  id="file"
                  multiple="no"
                  className={classes.inputFile}
                  type="file"
                />
                <Avatar className={classes.avatar} src={values.userImage} />
              </IconButton>
              <Typography color="textPrimary" gutterBottom variant="h3">
                {values.name}
              </Typography>
              <Typography color="textSecondary" variant="body1">
                {`${values.city} - ${values.state}`}
              </Typography>
              <Typography
                className={classes.dateText}
                color="textSecondary"
                variant="body1"
              >
                {`${moment().format('hh:mm A')}`}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={8} md={6} xs={12}>
        <form
          autoComplete="off"
          noValidate
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Card>
            <CardHeader subheader="Editar suas informações." title="Perfil" />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    helperText="Por favor, especifique um nome."
                    label="Nome"
                    name="name"
                    onChange={handleChange}
                    required
                    value={values.name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Sobrenome"
                    helperText="Por favor, especifique um sobrenome."
                    name="surname"
                    onChange={handleChange}
                    required
                    value={values.surname}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    onChange={handleChange}
                    required
                    value={values.email}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Telefone"
                    name="phone"
                    onChange={handleChange}
                    type="text"
                    value={values.phone}
                    variant="outlined"
                    InputProps={{
                      inputComponent: PhoneInput
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Whatsapp"
                    name="cellPhone"
                    onChange={handleChange}
                    type="text"
                    value={values.cellPhone}
                    variant="outlined"
                    InputProps={{
                      inputComponent: PhoneInput
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Cep"
                    name="cep"
                    onBlur={e => {
                      handleCep(e.target.value);
                    }}
                    onChange={handleChange}
                    required
                    value={values.cep}
                    variant="outlined"
                    InputProps={{
                      inputComponent: CepInput
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Estado"
                    name="state"
                    disabled={true}
                    onChange={handleChange}
                    required
                    value={values.state}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Cidade"
                    name="city"
                    disabled={true}
                    onChange={handleChange}
                    required
                    value={values.city}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Bairro"
                    disabled={true}
                    name="neighborhood"
                    onChange={handleChange}
                    required
                    value={values.neighborhood}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button
                style={{
                  marginRight: 10
                }}
                onClick={() => setShowChangePassword(true)}
                variant="contained"
              >
                <Typography color="light" component="strong">
                  Alterar Senha
                </Typography>
              </Button>
              <Button
                style={{
                  marginRight: 10
                }}
                onClick={handleDeactivate}
                color="secondary"
                variant="contained"
              >
                <Typography color="light" component="strong">
                  Desativar perfil
                </Typography>
              </Button>
              <Button
                onClick={handleUpdate}
                color="primary"
                variant="contained"
              >
                <Typography color="light" component="strong">
                  Salvar alterações
                </Typography>
              </Button>
            </Box>
          </Card>
        </form>
      </Grid>
    </>
  );
};

ProfileDetails.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object,
  reload: PropTypes.func
};

export default ProfileDetails;
