import styled from 'styled-components';

export const ImagePreviewWrapper = styled.section`
  font-family: 'Poppins', 'Roboto', 'sans-serif', 'Helvetica';
  top: 0px;
  ${props => (props.isStatic ? '' : 'right:50px')};
  padding: 1rem;
  background-color: ${props => (props.isStatic ? 'transparent' : '#f5f5f5')};
  border-radius: 0.4rem;
  position: ${props => props.position || 'absolute'};
  z-index: 10;
  border: ${props => (props.bordered ? 'dashed 1px black' : 'none')};
  box-shadow: ${props =>
    props.isStatic ? 'none' : '10px 10px 10px rgba(0, 0, 0, 0.15)'};
  cursor: default;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  height: auto;
`;

export const ImagePreviewContainerHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.3rem;
  margin-top: -20px;
  color: black;

  span {
    font-weight: bold;
    margin-right: 1rem;
  }

  button {
    border: none;
    outline: none;
    border-radius: 50rem;
    width: 30px !important;
    height: 30px !important;
    min-width: auto !important;
    color: red;
    padding: 0px;
    margin-right: -20px;
    background: transparent;
  }
`;

export const ThumbnailContainer = styled.section`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;

  &::nth-child(2) {
    overflow: hidden;
  }
`;

export const Thumbnail = styled.section`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-image: url(${props => props.img});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-style: solid;
  border-width: 3px;
  border-bottom-color: ${props => (props.borderBottom ? 'red' : 'transparent')};
  border-top-color: ${props => (props.borderTop ? 'red' : 'transparent')};
  border-left-color: ${props => (props.borderLeft ? 'red' : 'transparent')};
  border-right-color: ${props => (props.borderRight ? 'red' : 'transparent')};

  & > div {
    width: 60%;
    height: 60%;
    border: dashed 2px #0000005c;
    cursor: pointer;
    outline-color: white;
    outline-offset: 2px;
    outline-style: dashed;
    outline-width: 1px;
  }

  & > div:hover {
    border-color: red;
    border-width: 3px;
    background-color: rgba(255, 0, 0, 0.3);
  }
`;

export const Width = styled.span`
  text-align: center;
`;

export const Height = styled.span`
  text-align: center;
  position: absolute;
  top: 50%;
 
  right: -18px;
  transform: rotate(90deg);
`;
