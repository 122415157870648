import { Box, Typography } from '@material-ui/core';
import React from 'react';

const BasicField = ({ label, children, direction }) => (
  <Box
    display="flex"
    flexDirection={direction || 'column'}
    marginTop={1}
    alignItems={direction === 'row' ? 'center' : 'flex-start'}
    flex={1}
  >
    <Typography gutterBottom variant="caption" component="small">
      {label}:
    </Typography>
    {children}
  </Box>
);

export default BasicField;
