import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const OutlineCssButton = withStyles(theme => ({
  root: {
    boxShadow: theme.shadows[26],
    '&:active, &:focus': {
      boxShadow: theme.shadows[2]
    }
  }
}))(Button);

export default OutlineCssButton;
