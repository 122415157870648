import React from 'react';
import {
  Box,
  Container,
  Typography,
  makeStyles,
  Button
} from '@material-ui/core';
import Page from 'src/components/Page';
import OutlineCssButton from 'src/components/OutlineCssButton';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 560
  }
}));

const NotFoundView = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="404">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="md">
          <Typography align="center" color="primary" variant="h1">
            Ops! A página que você procura não está aqui... <strong>:(</strong>
          </Typography>
          <Typography
            style={{ marginTop: 20 }}
            align="center"
            color="textPrimary"
            variant="subtitle2"
          >
            Acreditamos que você tenha se enganado ao tentar acessar diretamente
            algum endereço especifico. <br /> Clicando no botão abaixo você será
            redirecionado à home do esobras. <br />
            Se preferir, utilize a opção voltar do seu navegador ;)
          </Typography>
          <Box marginTop={7} textAlign="center">
            <OutlineCssButton color="primary" href="/">
              <strong>voltar</strong>
            </OutlineCssButton>
          </Box>
        </Container>
      </Box>
    </Page>
  );
};

export default NotFoundView;
