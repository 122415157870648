import React from 'react';
import { Box, Container, Typography, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import AdBuilder from '../../components/AdManager';
import useMobileDetect from '../../utils/useMobileDetect';

const useStyles = makeStyles(theme => ({
  title: {
    margin: '1.5rem 0 0.8rem 0'
  }
}));

const SelfProtect = () => {
  const classes = useStyles();
  const detectMobile = useMobileDetect();

  return (
    <Page className={classes.root} title="Poteja-se">
      <Box
        display="flex"
        flexDirection="column"
        marginTop={4}
        height="100%"
        className={classes.centerMd}
      >
        {detectMobile.isDesktop() && (
          <div style={{margin: '0px auto 24px' }}>
            <AdBuilder
              size="[970,250]"
              id="ad-banner-970x250"
            ></AdBuilder>
          </div>
        )}
        {detectMobile.isMobile() && (
          <div style={{
            margin: '0px auto 24px',
            display: 'flex',
            justifyContent: 'center'       
          }}>
            <AdBuilder
              size="[320,100]"
              id="ad-banner-320x100"
            ></AdBuilder>
          </div>
        )}
        <Container maxWidth="md">
          <Box flexDirection="column" display="Flex">
            <Typography gutterBottom={5} variant="h2" color="primary">
              Proteja-se
            </Typography>
            <Typography
              align="justify"
              gutterBottom={5}
              variant="body1"
              component="p"
            >
              Nunca clique em links de Emails afirmando que provêm de Bancos ou
              outras Instituições Financeiras. Deve sempre navegar diretamente
              para o site do Banco ou Instituição Financeira em vez de
              direcionar à sua conta bancária a partir de outro site.
            </Typography>
            <Typography
              align="justify"
              gutterBottom={5}
              variant="body1"
              component="p"
            >
              Suspeite de Emails (ou links para a páginas) que pedem para
              digitar a sua senha ou outras informações relevantes da sua conta
              bancária.
            </Typography>
            <Typography
              align="justify"
              gutterBottom={5}
              variant="body1"
              component="p"
            >
              Há cada vez mais relatos de vírus que são utilizados para
              interceptar e recolher informações sensíveis nos computadores.
              Certifique-se portanto, de que o seu computador tem software de
              proteção contra vírus devidamente atualizado. O sistema operativo
              e navegador de Internet (browser) do seu computador também devem
              estar atualizados com as mais recentes atualizações de segurança.
            </Typography>
            <Typography
              align="justify"
              gutterBottom={5}
              variant="body1"
              component="p"
            >
              Cuidado com as chamadas “cartas nigerianas” e outras tentativas
              feitas por indivíduos de países estrangeiros para enviar artigos
              para fora do Brasil. O objetivo das “cartas nigerianas” e
              similares é simplesmente cometer fraudes! As cartas nigerianas são
              feitas para induzi-lo a enviar dinheiro ou bens para países
              estrangeiros. É recomendado que não se envolva em tais
              negociações, que são na maioria das vezes fraudulentas.
            </Typography>
            <Typography gutterBottom={5} variant="body1" component="p">
              Efetue sempre a sua Compra ou Venda de forma segura.
            </Typography>
            <Typography gutterBottom={5} variant="body1" component="p">
              <strong>Equipe E-Sobras</strong>
            </Typography>
          </Box>
        </Container>
      </Box>
      {detectMobile.isMobile() && (
        <div style={{
          margin: '24px auto 0px',
          display: 'flex',
          justifyContent: 'center'       
        }}>
          <AdBuilder
            size="[300,250]"
            id="ad-banner-300x250"
          ></AdBuilder>
        </div>
      )}
    </Page>
  );
};

export default SelfProtect;
