import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Link,
  Typography,
  makeStyles,
  InputAdornment,
  IconButton,
  MenuItem
} from '@material-ui/core';
import Page from 'src/components/Page';
import theme from 'src/theme';
import OutlineCssButton from 'src/components/OutlineCssButton';
import CssInputText from 'src/components/CssTextField';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useState } from 'react';
import Api from 'src/api/Api';
import CustomPopUp from 'src/components/CustomPopUp';
import Loading from 'src/components/Loading';
import MaskedInput from 'react-text-mask';
import LocationSearch from 'src/components/LocationSearch';
import AdBuilder from '../../components/AdManager';
import useMobileDetect from '../../utils/useMobileDetect';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  submitButton: {
    borderRadius: 60,
    marginTop: theme.spacing(2),
    border: `3px solid ${theme.palette.background.default}`,
    boxShadow: theme.shadows[26],
    color: 'white',
    '&:hover': {
      boxShadow: theme.shadows[25]
    }
  },
  input: {
    '& input': {
      minHeight: 22
    }
  }
}));

const RegisterView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [address, setAddress] = useState(null);
  const [showCepHelper, setShowCepHelper] = useState(false);
  const detectMobile = useMobileDetect();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const handleRegister = async values => {
    setIsLoading(true);
    const response = await Api.register({ ...values, ...address });
    if (response.error || response.errors) {
      setAddress(undefined);
      setMessage(
        response.error ||
          'Erro ao cadastrar usuário, verifique as informações e tente novamente!'
      );
    } else {
      setMessage(
        'Usuário cadastrado com sucesso! Siga as instruções enviadas por email para ativação. Obrigado!'
      );
    }
    setIsLoading(false);
    setShowAlert(true);
  };

  const handleOKAction = () => {
    navigate('/', { replace: true });
  };

  const setCep = location => {
    const { localidade: city, uf: state, bairro: neighborhood } = location;
    setAddress({ city, state, neighborhood });
  };

  const handleCep = async cep => {
    setIsLoading(true);
    const response = await Api.getAddress(cep);
    if (response.error || response.erro) {
      setShowCepHelper(true);
    } else {
      setCep(response);
    }
    setIsLoading(false);
  };

  const alertHandler = address ? handleOKAction : () => {};

  const CepInput = props => {
    const { inputRef, ...other } = props;
    return (
      <MaskedInput
        {...other}
        ref={ref => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
        placeholderChar={'\u2000'}
      />
    );
  };

  const PhoneInput = props => {
    const { inputRef, ...other } = props;
    return (
      <MaskedInput
        {...other}
        ref={ref => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={[
          '(',
          /[1-9]/,
          /\d/,
          ')',
          ' ',
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          '-',
          /\d/,
          /\d/,
          /\d/,
          /\d/
        ]}
        placeholderChar={'\u2000'}
      />
    );
  };

  return (
    <Page className={classes.root} title="Register">
      <Container maxWidth="md">
        <Loading open={isLoading} />
        <CustomPopUp
          message={message}
          visible={showAlert}
          setShow={setShowAlert}
          replaceDefaultAction={alertHandler}
        />
        <Box display="flex" flexDirection="row" flexWrap="wrap" width="100%">
          {detectMobile.isDesktop() && (
            <div style={{ margin: '0 auto' }}>
              <AdBuilder size="[970,250]" id="ad-banner-970x250"></AdBuilder>
            </div>
          )}
          {detectMobile.isMobile() && (
            <div style={{ margin: '0 auto' }}>
              <AdBuilder size="[300,100]" id="ad-banner-300x100"></AdBuilder>
            </div>
          )}
          <Box
            display="flex"
            flexDirection="column"
            flex="1 0 320px"
            padding={2}
            marginTop={15}
          >
            <Typography gutterBottom color="primary" variant="h1" component="p">
              Procurando por <br />
              <strong>sobras?</strong>
            </Typography>
            <Typography
              gutterBottom
              color="textSecondary"
              variant="subtitle1"
              component="p"
            >
              O{' '}
              <Typography color="primary" component="em">
                <strong>E-SOBRAS</strong>
              </Typography>{' '}
              é o lugar certo para encontrar o que você procura. São centenas de
              anúncios de diversos lugares do Brasil. Está esperando o que?
              Cadastre-se agora! É rápido, fácil e{' '}
              <Typography color="primary" component="em">
                <strong>Gratuito</strong>
              </Typography>
              !
            </Typography>
            <OutlineCssButton
              color="primary"
              href="#register-from"
              style={{
                border: `solid thin ${theme.palette.primary.main}`,
                maxWidth: 200,
                padding: 15,
                borderRadius: 60,
                marginTop: 60
              }}
            >
              Vamos Começar!
            </OutlineCssButton>
          </Box>
          <Box
            display="flex"
            flex="1 0 320px"
            padding={3}
            flexDirection="column"
            height="100%"
            justifyContent="center"
            alignItems="center"
            id="register-from"
          >
            <Formik
              initialValues={{
                email: '',
                name: '',
                surname: '',
                cep: '',
                password: '',
                phone: '',
                cellPhone: '',
                howKnows: '',
                policy: false
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email('Email inválido')
                  .max(255)
                  .required('Email é obrigatório'),
                name: Yup.string()
                  .max(255)
                  .required('Nome é obrigatório'),
                surname: Yup.string()
                  .max(255)
                  .required('Sobrenome é obrigatório'),
                password: Yup.string()
                  .min(6, 'A Senha deve conter no mínimo 6 dígitos')
                  .max(255)
                  .required('Senha é obrigatório'),
                cep: Yup.string().required('Cep é obrigatório'),
                phone: Yup.string().required('Telefone é obrigatório'),
                cellPhone: Yup.string().required('Whatsapp é obrigatório'),
                howKnows: Yup.string().required('Este campo é obrigatório'),
                policy: Yup.boolean().oneOf(
                  [true],
                  'É necessário aceitar os termos.'
                )
              })}
              onSubmit={handleRegister}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <LocationSearch
                    open={showCepHelper}
                    close={() => setShowCepHelper(false)}
                    loading={setIsLoading}
                    callback={cepValues => {
                      if (!cepValues) {
                        handleChange({
                          target: { value: '', name: 'cep' }
                        });
                        setAddress(null);
                        return;
                      }
                      if (cepValues.cep) {
                        handleCep(cepValues.cep);
                        handleChange({
                          target: { value: cepValues.cep, name: 'cep' }
                        });
                      } else {
                        setCep(cepValues);
                      }
                    }}
                  />
                  <Box mt={1} mb={3}>
                    <Typography align="center" color="primary" variant="h2">
                      <strong>Criar sua conta gratuita.</strong>
                    </Typography>
                  </Box>
                  <CssInputText
                    className={classes.input}
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    label=" Nome"
                    margin="normal"
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name}
                    variant="outlined"
                  />
                  <CssInputText
                    className={classes.input}
                    error={Boolean(touched.surname && errors.surname)}
                    fullWidth
                    helperText={touched.surname && errors.surname}
                    label="Sobrenome"
                    margin="normal"
                    name="surname"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.surname}
                    variant="outlined"
                  />
                  <CssInputText
                    className={classes.input}
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    label="Email"
                    margin="normal"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="email"
                    value={values.email}
                    variant="outlined"
                  />
                  <CssInputText
                    className={classes.input}
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    label="Senha"
                    margin="normal"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                    value={values.password}
                    variant="outlined"
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  <CssInputText
                    className={classes.input}
                    error={Boolean((touched.cep && errors.cep) || !address)}
                    fullWidth
                    helperText={(touched.cep && errors.cep) || !address}
                    label="Cep"
                    margin="normal"
                    name="cep"
                    onBlur={e => {
                      handleCep(e.target.value);
                      handleBlur(e);
                    }}
                    onChange={handleChange}
                    value={values.cep}
                    variant="outlined"
                    InputProps={{
                      inputComponent: CepInput
                    }}
                  />
                  {address && (
                    <Box
                      padding={2}
                      boxShadow={theme.shadows[10]}
                      borderRadius={15}
                      width="100%"
                    >
                      <Typography
                        align="center"
                        variant="caption"
                        color="primary"
                      >
                        {`${address.city} (${address.state}) - ${address.neighborhood}`}
                      </Typography>
                    </Box>
                  )}
                  <CssInputText
                    key="phone-input"
                    className={classes.input}
                    error={Boolean(touched.phone && errors.phone)}
                    fullWidth
                    helperText={touched.phone && errors.phone}
                    label="Telefone"
                    margin="normal"
                    name="phone"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="phone"
                    value={values.phone}
                    variant="outlined"
                    InputProps={{
                      inputComponent: PhoneInput
                    }}
                  />
                  <CssInputText
                    key="wp-input"
                    className={classes.input}
                    error={Boolean(touched.cellPhone && errors.cellPhone)}
                    fullWidth
                    helperText={touched.cellPhone && errors.cellPhone}
                    label="Número Whatsapp"
                    margin="normal"
                    name="cellPhone"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="phone"
                    value={values.cellPhone}
                    variant="outlined"
                    InputProps={{
                      inputComponent: PhoneInput
                    }}
                  />
                  <CssInputText
                    select
                    key="hn-input"
                    className={classes.input}
                    error={Boolean(touched.howKnows && errors.howKnows)}
                    fullWidth
                    helperText={touched.howKnows && errors.howKnows}
                    label="Como nos conheceu?"
                    margin="normal"
                    name="howKnows"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.howKnows}
                    variant="outlined"
                  >
                    <MenuItem value="Grupo de Whatsapp">
                      Grupo de Whatsapp
                    </MenuItem>
                    <MenuItem value="Pesquisa no Google">
                      Pesquisa no Google
                    </MenuItem>
                    <MenuItem value="Instagram">Instagram</MenuItem>
                    <MenuItem value="Youtube">Youtube</MenuItem>
                    <MenuItem value="Blog">Blog</MenuItem>
                    <MenuItem value="Madeireiras">Madeireiras</MenuItem>
                    <MenuItem value="Revista InfoMarceneiro">
                      Revista InfoMarceneiro
                    </MenuItem>
                    <MenuItem value="Indicação">Indicação</MenuItem>
                    <MenuItem value="Equipe E-Sobras">Equipe E-Sobras</MenuItem>
                    <MenuItem value="Outros">Outros</MenuItem>
                  </CssInputText>
                  <Box alignItems="center" display="flex" ml={-1}>
                    <Checkbox
                      checked={values.policy}
                      name="policy"
                      onChange={handleChange}
                    />
                    <Typography color="textSecondary" variant="body1">
                      Eu li e concordo com os{' '}
                      <a
                        style={{
                          color: theme.palette.primary.main,
                          font: 'inherit'
                        }}
                        target="_blank"
                        href="/terms"
                      >
                        Termos de uso
                      </a>
                    </Typography>
                  </Box>
                  <Box width="100%">
                    {Boolean(touched.policy && errors.policy) && (
                      <Typography
                        align="center"
                        color="secondary"
                        variant="body1"
                      >
                        {'É necessário aceitar os termos de uso.'}
                      </Typography>
                    )}
                  </Box>
                  <Box my={2}>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      className={classes.submitButton}
                    >
                      Cadastrar
                    </Button>
                  </Box>
                  <Typography color="textSecondary" variant="body1">
                    Já é cadastrado?{' '}
                    <Link component={RouterLink} to="/login" variant="h6">
                      Entrar
                    </Link>
                  </Typography>
                </form>
              )}
            </Formik>
          </Box>
          {detectMobile.isMobile() && (
            <div style={{ margin: '0 auto' }}>
              <AdBuilder size="[300,250]" id="ad-banner-300x250"></AdBuilder>
            </div>
          )}
        </Box>
      </Container>
    </Page>
  );
};

export default RegisterView;
