export function phoneMask(value) {
  var str = value.replace(/[^\d]+/g, "");
  if (str.length > 2) {
    str = "(" + str.substring(0, 2) + ") " + str.substring(2, str.length);
  }
  if (str.length > 9) {
    str = str.substring(0, 9) + "-" + str.substring(9, str.length);
  }
  if (str.length > 11) {
    str = value.replace(/[^\d]+/g, "");
    str =
      "(" +
      str.substring(0, 2) +
      ") " +
      str.substring(2, str.length - 4) +
      "-" +
      str.substring(str.length - 4);
  }
  if (str.length > 14) {
    str = str.substring(0, 15);
  }
  return str;
}

export function dateMask(value) {
  var str = value.replace(/[^\d]+/g, "");
  if (str.length > 2) {
    str = str.substring(0, 2) + "/" + str.substring(2, str.length);
  }
  if (str.length > 4) {
    str = str.substring(0, 4) + "/" + str.substring(4, str.length);
  }
  if (str.length > 6) {
    str = value.replace(/[^\d]+/g, "");
    str =
      str.substring(0, 2) +
      "/" +
      str.substring(2, 4) +
      "/" +
      str.substring(4, str.length);
  }
  if (str.length > 9) {
    str = str.substring(0, 10);
  }
  return str;
}

export function cepMask(value) {
  var str = value.replace(/[^\d]+/g, "");
  if (str.length > 5) {
    str = str.substring(0, 5) + "-" + str.substring(5, str.length);
  }
  if (str.length > 7) {
    str = value.replace(/[^\d]+/g, "");
    str = str.substring(0, 5) + "-" + str.substring(5, str.length);
  }
  if (str.length > 8) {
    str = str.substring(0, 9);
  }
  return str;
}
