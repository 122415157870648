import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import api from '../../api/Api';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Link,
  Typography,
  makeStyles,
  CircularProgress,
  InputAdornment,
  IconButton
} from '@material-ui/core';
import Page from 'src/components/Page';
import CustomPopUp from 'src/components/CustomPopUp';
import CssInputText from 'src/components/CssTextField';
import { useEffect } from 'react';
import Api from '../../api/Api';
import querystring from 'querystring';
import { Visibility, VisibilityOff } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    '& > *': {
      outline: 'none !important'
    }
  },
  submitButton: {
    borderRadius: 60,
    marginTop: theme.spacing(2),
    border: `3px solid ${theme.palette.background.default}`,
    boxShadow: theme.shadows[26],
    color: 'white',
    '&:hover': {
      boxShadow: theme.shadows[25]
    }
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '44%',
    left: '48%'
  }
}));

const LoginView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isForgot, setIsForgot] = useState(false);
  const [isRecovery, setIsRecovery] = useState(false);
  const [action, setAction] = useState({});
  const [resetPassParams, setResetPassParams] = useState({});
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  useEffect(() => {
    const query = querystring.parse(window.location.search.replace('?', ''));
    if (query.t && query.e) {
      setIsRecovery(true);
      setResetPassParams(query);
    }
    if (query.logout) {
      localStorage.clear();
    }
  }, [window.location.search]);

  const validatePreviousURLAndRedirect = () => {
    const URL = localStorage.getItem('lastUrl');
    if (URL) window.location = URL;
    else navigate('/app/advertisement', { replace: true });
  };

  const handleLogin = async values => {
    setLoading(true);
    const logged = await api.login({ ...values });
    if (logged.error) {
      setMessage(
        logged.error ||
          'Erro ao efetuar login, verifique as informações e tente novamente.'
      );
      setOpenDialog(true);
    } else {
      validatePreviousURLAndRedirect();
    }
    setLoading(false);
  };

  const handleForgotPass = async values => {
    setLoading(true);
    const response = await Api.forgotPass(values);
    if (response.error) {
      setMessage(
        response.error || 'Erro na comunicação com o servidor, tente novamente.'
      );
      setOpenDialog(true);
    } else {
      setMessage(response.info);
      setAction({ ok: () => navigate('/', { replace: true }) });
      setOpenDialog(true);
    }
    setLoading(false);
  };

  const handleResetPass = async values => {
    setLoading(true);

    if (values.password !== values.confirmPassword) {
      setMessage('Senhas não conferem!');
      setOpenDialog(true);
      setLoading(false);
      return;
    }

    const response = await Api.resetPass({
      password: values.password,
      email: resetPassParams.e,
      token: resetPassParams.t
    });

    if (response.error) {
      setMessage(
        response.error || 'Erro na comunicação com o servidor, tente novamente.'
      );
      setOpenDialog(true);
    } else {
      setMessage('Reset de senha efetuado com sucesso.');
      setOpenDialog(true);
      setIsRecovery(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    const checkIsUserOnline = async () => {
      const { valid, error } = await Api.validate();
      if (valid || !error) navigate('/app/advertisement', { replace: true });
    };
    checkIsUserOnline();
  }, []);

  return (
    <Page className={classes.root} title="Login">
      <CustomPopUp
        visible={openDialog}
        setShow={setOpenDialog}
        message={message}
        replaceDefaultAction={action.ok}
      />
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="xs">
          {!isForgot && !isRecovery && (
            <Formik
              initialValues={{
                email: '',
                password: ''
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email('Email inválido.')
                  .max(255)
                  .required('Email obrigatório'),
                password: Yup.string()
                  .max(255)
                  .min(5)
                  .required('Senha obrigatória')
              })}
              onSubmit={handleLogin}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    mb={3}
                  >
                    <Typography className="bold" color="primary" variant="h2">
                      Acesse sua conta
                    </Typography>
                    <Link
                      component={RouterLink}
                      to="/register"
                      variant="body1"
                      color="primary"
                    >
                      ou cadastre-se gratuitamente!
                    </Link>
                  </Box>

                  <CssInputText
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="email"
                    label="Email"
                    variant="outlined"
                    value={values.email}
                  />
                  <CssInputText
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    label="Senha"
                    margin="normal"
                    name="password"
                    variant="outlined"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  <Box
                    my={2}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    position="relative"
                  >
                    <Button
                      fullWidth
                      color="primary"
                      disabled={loading}
                      size="large"
                      type="submit"
                      variant="contained"
                      className={classes.submitButton}
                    >
                      entrar agora
                    </Button>
                    {loading && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </Box>
                  <Typography color="textSecondary" variant="body1">
                    Novo por aqui?{' '}
                    <Link component={RouterLink} to="/register" variant="h6">
                      Cadastre-se gratuitamente!
                    </Link>
                  </Typography>
                </form>
              )}
            </Formik>
          )}
          {isForgot && (
            <Formik
              initialValues={{
                email: ''
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email('Email inválido.')
                  .max(255)
                  .required('Email obrigatório')
              })}
              onSubmit={handleForgotPass}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box display="flex" justifyContent="center" mb={3}>
                    <Typography className="bold" color="primary" variant="h2">
                      Recuperar senha.
                    </Typography>
                  </Box>

                  <CssInputText
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="email"
                    label="Email"
                    variant="outlined"
                    value={values.email}
                  />
                  <Box
                    my={2}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    position="relative"
                  >
                    <Button
                      fullWidth
                      color="primary"
                      disabled={loading}
                      size="large"
                      type="submit"
                      variant="contained"
                      className={classes.submitButton}
                    >
                      enviar
                    </Button>
                    {loading && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Link
                      component={RouterLink}
                      to="#"
                      onClick={() => {
                        setIsForgot(false);
                      }}
                      variant="h6"
                    >
                      entrar
                    </Link>
                  </Box>
                </form>
              )}
            </Formik>
          )}
          {isRecovery && (
            <Formik
              initialValues={{
                password: '',
                confirmPassword: ''
              }}
              validationSchema={Yup.object().shape({
                password: Yup.string()
                  .max(255)
                  .min(5)
                  .required('Nova Senha obrigatória'),
                confirmPassword: Yup.string()
                  .max(255)
                  .min(5)
                  .required('Confirmação de senha obrigatória')
              })}
              onSubmit={handleResetPass}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box display="flex" justifyContent="center" mb={3}>
                    <Typography className="bold" color="primary" variant="h2">
                      Nova Senha.
                    </Typography>
                  </Box>

                  <CssInputText
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                    label="Nova Senha"
                    variant="outlined"
                    value={values.password}
                  />
                  <CssInputText
                    error={Boolean(
                      touched.confirmPassword && errors.confirmPassword
                    )}
                    fullWidth
                    helperText={
                      touched.confirmPassword && errors.confirmPassword
                    }
                    label="Cofirmação de Senha"
                    margin="normal"
                    name="confirmPassword"
                    type="password"
                    variant="outlined"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.confirmPassword}
                  />
                  <Box
                    my={2}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    position="relative"
                  >
                    <Button
                      fullWidth
                      color="primary"
                      disabled={loading}
                      size="large"
                      type="submit"
                      variant="contained"
                      className={classes.submitButton}
                    >
                      enviar
                    </Button>
                    {loading && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Link
                      component={RouterLink}
                      to="#"
                      onClick={() => {
                        setIsForgot(false);
                      }}
                      variant="h6"
                    >
                      entrar
                    </Link>
                  </Box>
                </form>
              )}
            </Formik>
          )}
          {!isForgot && !isRecovery && (
            <Box
              color="primary"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography component="p" color="primary">
                <Link
                  component={Button}
                  to="#"
                  onClick={() => setIsForgot(true)}
                  variant="h6"
                >
                  Esqueci a senha
                </Link>
              </Typography>
            </Box>
          )}
        </Container>
      </Box>
    </Page>
  );
};

export default LoginView;
