import { createSlice } from '@reduxjs/toolkit';
import Api, { getLocations } from '../../api/Api';

export const advertisementSearchReducer = createSlice({
  name: 'advertisementSearch',
  initialState: {
    locations: [],
    headerImgUrl:
      'https://esobras-imgs.s3.amazonaws.com/548a5f9e5e93b66347532806ee6fc5ad-photo-1485965089147-0f655dcf01b0.jpg',
    error: false,
    errorMessage: '',
    isLoading: false,
    user: undefined
  },
  reducers: {
    setLocations: (state, action) => {
      let payload = action.payload;
      state.locations = payload;
    },
    setError: (state, action) => {
      const { error = false, errorMessage = '' } = action.payload;
      state = { ...state, error, errorMessage };
    },
    setIsLoading: (state, action) => {
      const isLoading = action.payload.isLoading || false;
      state.isLoading = isLoading;
    },
    setUser: (state, action) => {
      const user = action.payload;
      if (user.name) {
        state.user = user;
      }
    }
  }
});

export const fetchLocations = () => async dispatch => {
  const errorMessage = 'Erro ao buscar, tente novamente';
  const result = await getLocations();
  if (result.error) {
    dispatch(
      setError({
        error: true,
        errorMessage: result.error.message || errorMessage
      })
    );
  } else {
    dispatch(setLocations(result));
  }
  dispatch(setIsLoading({ isLoading: false }));
};

export const fetchUserDetails = userId => async dispatch => {
  dispatch(setIsLoading({ isLoading: true }));
  const result = await Api.getUserDetails(userId);
  if (result.error) {
    dispatch(
      setError({
        error: true,
        errorMessage:
          result.error.message || 'Erro ao buscar dados do anunciante!'
      })
    );
  } else {
    dispatch(setUser(result));
  }
  dispatch(setIsLoading({ isLoading: false }));
};

export const {
  setError,
  setIsLoading,
  setLocations,
  setUser
} = advertisementSearchReducer.actions;

export const selectAvertisementSearch = state => state.advertisementSearch;

export default advertisementSearchReducer.reducer;
