import React, { useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Currency from 'src/utils/currency';
import {
  Box,
  Button,
  Card,
  IconButton,
  makeStyles,
  SwipeableDrawer,
  Radio,
  FormControl,
  RadioGroup,
  FormControlLabel,
  TableFooter,
  TableCell,
  TableRow,
  Typography
} from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import { DeleteOutline, EditOutlined, Visibility } from '@material-ui/icons';
import { useState } from 'react';
import Advertisement from 'src/views/Advertisement';
import Api from 'src/api/Api';
import { getBrand } from 'src/views/Advertisement/functions';
import Loading from 'src/components/Loading';
import CustomPopUp from 'src/components/CustomPopUp';
import { columns } from './data';
import formatCurrency from '../../utils/currency';

const useStyles = makeStyles(theme => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const Results = ({ className, advertisements, reload, ...rest }) => {
  const [showEditor, setShowEditor] = useState();
  const [ads, setAds] = useState([]);
  const [selectedAdvertisement, setSelectedAdvertisement] = useState(null);
  const [removeAdvertisement, setRemoveAdvertisement] = useState(null);
  const [deleteReason, setDeleteReason] = useState(null);
  const [data, setData] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    setAds(advertisements);
  }, [advertisements]);

  const handleEdit = async adv => {
    setLoading(true);
    const materials = await getMaterials(adv.material.category_id);
    const products = await getProducts(adv.material.id);
    const category = await Api.getSegmentById(adv.material.category_id);
    const { ad: advertisement } = await Api.getAdvertisementDetails(adv.id);
    const advertisementToEdit = {
      ...advertisement,
      category,
      productId: advertisement.productId.id,
      product: advertisement.productId,
      unitLabel: advertisement.material.unitLabel
    };

    setSelectedAdvertisement(advertisementToEdit);
    setData({
      materials,
      products,
      categories: [category],
      materialNames: materials.map(mat => mat.name),
      productNames: products.map(prod => getBrand(prod)),
      categoryNames: [category.name]
    });
    setShowEditor(true);
    setLoading(false);
  };

  const getMaterials = categoryId => {
    return Api.getMaterialsBySegment(categoryId);
  };

  const getProducts = materialId => {
    return Api.getProductsByMaterialId(materialId);
  };

  const handleRemove = async advertisement => {
    const response = await Api.removeAdvertisement(
      advertisement.id,
      deleteReason
    );

    if (response.error) {
      setMessage(response.error.message);
    } else {
      const newAds = ads.filter(ad => ad.id != advertisement.id);
      setRemoveAdvertisement(false);
      setAds(newAds);
      setMessage('Anuncio removido com sucesso!');
      setShowEditor(false);
      setDeleteReason(null);
    }
    setShowAlert(true);
    setLoading(false);
  };

  const editButton = advertisement => {
    return (
      <IconButton
        title="editar"
        size="small"
        onClick={() => handleEdit(advertisement)}
      >
        <EditOutlined color="primary" fontSize="small" />
      </IconButton>
    );
  };

  const showButton = advertisement => (
    <IconButton
      title="detalhes"
      target="_blank"
      href={`/details/${advertisement.id}`}
      size="small"
    >
      <Visibility color="primary" fontSize="small" />
    </IconButton>
  );

  const removeButton = advertisement => {
    return (
      <IconButton
        title="remover"
        size="small"
        onClick={() => {
          setMessage(
            'Antes confirmar a remoção do seu anuncio, poderia nos informar se sua sobra foi:'
          );
          setShowAlert(true);
          setRemoveAdvertisement(advertisement);
        }}
      >
        <DeleteOutline color="error" fontSize="small" />
      </IconButton>
    );
  };

  const onSave = async advertisement => {
    setLoading(true);
    advertisement = advertisement[0];
    const response = await Api.updateAdvertisementDetails(
      selectedAdvertisement.id,
      advertisement
    );

    if (response.error) {
      setMessage(response.error.message);
    } else {
      const newAds = ads.filter(ad => ad.id != selectedAdvertisement.id);
      newAds.push(response);
      setAds(newAds);
      setMessage('Anuncio atualizado com sucesso!');
      setShowEditor(false);
    }
    setShowAlert(true);
    setLoading(false);
  };

  const getData = () =>
    ads.map(ad => [
      'MA-' + ad.sequenceValue,
      <img
        style={{ borderRadius: 60 }}
        width="auto"
        height={40}
        src={ad.product.imageUrl || ''}
      />,
      ad.product.brand.name + ' - ' + ad.product.name,
      ad.material.name,
      ad.amount,
      Number(ad.totalPrice) ? Currency(ad.totalPrice) : 'Doação',
      new Date(ad.createdAt).toLocaleDateString('pt-BR'),
      [editButton(ad), removeButton(ad), showButton(ad)]
    ]);
    const footerClasses = clsx({
      [classes.footerCell]: true,
      [classes.stickyFooterCell]: true,
    });
    const dataTable = getData();
    const tableTotalSum = dataTable => {
      return dataTable.reduce((sum, row) => sum + (row.totasl ? row.totsal : 0), 0);
    };
    const tableTaxSum = dataTable => {
      return dataTable.reduce((sum, row) => sum + (row.tax ? row.tax : 0), 0);
    };

    const options = {
      selectableRowsHideCheckboxes: true,
      selectableRowsHeader: false,
      filter: false,
      searchOpen: true,
      search: true,
      responsive: 'standard',
      print: false,
      download: false,
      downloadOptions: {
        filename: 'anuncios.csv',
        filterOptions: {
          useDisplayedColumnsOnly: true
        }
      },
      customTableBodyFooterRender: function(opts) {
        console.dir(opts);
  
        let totalProducts =
          opts.data.reduce((accu, item) => {
            return accu + 1;
          }, 0);
  
        let totalSale =
          opts.data.reduce((accu, item) => {
            return accu + parseFloat(item.data[5].replace("R$", "").trim());
          }, 0);  
        return (
          <TableFooter className={footerClasses}>
            <TableRow>
              <TableCell><Typography variant="subtitle2" style={{color: 'black'}}><strong>Produtos: {totalProducts}</strong></Typography></TableCell>
              <TableCell style={{backgroundColor: 'white'}}><Typography variant="subtitle2" style={{color: 'black'}}><strong>Total: R$ {formatCurrency(totalSale)}</strong></Typography></TableCell>
            </TableRow>
          </TableFooter>
        );
      },
    
      textLabels: {
        body: {
          noMatch: 'Desculpe, não encontramos resultados para a sua busca.',
          toolTip: 'Ordenar',
          columnHeaderTooltip: column => `Ordenar por ${column.label}`
        },
        pagination: {
          next: 'Próxima pagina.',
          previous: 'Página anterior.',
          rowsPerPage: 'Linhas por página.',
          displayRows: 'de'
        },
        toolbar: {
          search: 'Buscar',
          downloadCsv: 'Download CSV',
          print: 'Imprimir',
          viewColumns: 'Mostrar Colunas',
          filterTable: 'Filtrar Tabela'
        },
        filter: {
          all: 'Todos',
          title: 'FILTROS',
          reset: 'LIMPAR'
        },
        viewColumns: {
          title: 'Mostrar Colunas',
          titleAria: 'Mostrar / Ocultar Colunas'
        },
        selectedRows: {
          text: 'linha(s) selecionadas',
          delete: 'Remover',
          deleteAria: 'Remover linhas selecionadas'
        }
      }
    };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CustomPopUp
        visible={showAlert}
        message={message}
        setShow={setShowAlert}
        defaultActionTitle={removeAdvertisement ? 'Não' : 'OK'}
        replaceDefaultAction={() => setRemoveAdvertisement(false)}
        actionButtons={
          removeAdvertisement && (
            <Button
              disabled={!deleteReason}
              onClick={() => handleRemove(removeAdvertisement)}
            >
              Sim
            </Button>
          )
        }
      >
        {removeAdvertisement && (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            mt={2}
            mb={2}
          >
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="position"
                name="position"
                defaultValue="top"
                onChange={event => setDeleteReason(event.target.value)}
              >
                <FormControlLabel
                  value="vendido"
                  control={<Radio color="primary" />}
                  label="Vendida"
                  labelPlacement="start"
                />
                <FormControlLabel
                  value="reutilizado"
                  control={<Radio color="primary" />}
                  label="Reutilizada"
                  labelPlacement="start"
                />
              </RadioGroup>
            </FormControl>
          </Box>
        )}
      </CustomPopUp>
      <Loading open={loading} />
      <SwipeableDrawer
        onClose={() => setShowEditor(false)}
        anchor="bottom"
        open={showEditor}
      >
        <Advertisement
          isEditing={true}
          editAd={selectedAdvertisement}
          loading={loading}
          callback={onSave}
          data={data}
        />
      </SwipeableDrawer>
      <Box overflow="auto">
        <MUIDataTable
          title={'Listagem de Anuncios'}
          data={dataTable}
          columns={columns}
          options={options}
          filter={false}
        />
      </Box>
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  advertisements: PropTypes.array.isRequired
};

export default Results;
