import { getUserAttribute } from '../../utils/user';
import _ from 'lodash';

export class RowData {
  constructor(
    materials,
    products,
    materialNames,
    productNames,
    categories,
    categoryNames
  ) {
    this.materials = materials || [];
    this.products = products || [];
    this.materialNames = materialNames || [];
    this.productNames = productNames || [];
    this.categories = categories || [];
    this.categoryNames = categoryNames || [];
  }
}

export class Row {
  constructor(row = {}) {
    let {
      order,
      productId,
      unit,
      unitLabel,
      unitPrice,
      totalPrice,
      piecePrice,
      percentOff,
      amount,
      measure,
      totalMeasure,
      customData,
      material,
      product,
      category,
      lastZIndex,
      blockSearch,
      blockFields,
      isDonation
    } = row;
    this.order = order || 0;
    this.productId = productId || '';
    this.userId = getUserAttribute('_id');
    this.unit = unit || 'un';
    this.unitLabel = unitLabel || 'Unidade';
    this.unitPrice = unitPrice || 0;
    this.totalPrice = totalPrice || 0;
    this.piecePrice = piecePrice || 0;
    this.percentOff = percentOff || 0;
    this.amount = amount || 0;
    this.measure = measure || 0;
    this.totalMeasure = totalMeasure || 0;
    this.customData = customData || {};
    this.material = material || {};
    this.product = product || {};
    this.category = category || { name: 'default' };
    this.lastZIndex = lastZIndex || 10;
    this.blockSearch = _.isBoolean(blockSearch) ? blockSearch : false;
    this.blockFields = _.isBoolean(blockFields) ? blockFields : false;
    this.isDonation =
      (parseFloat(totalPrice) == 0 && productId) || isDonation ? true : false;
  }

  validateFields() {
    let message =
      'Campo #field inválido ou nulo, favor preencher corretamente todos os anúncios antes de prosseguir.';
    let isValid = true;
    if (!this.unitPrice && !this.isDonation) {
      message = message.replace('#field', 'Preço Unitário');
      isValid = false;
      return { isValid, message };
    }
    if (!this.totalPrice && !this.isDonation) {
      message = message.replace('#field', 'Preço Total');
      isValid = false;
      return { isValid, message };
    }
    if (!this.amount) {
      message = message.replace('#field', 'Quantidade');
      isValid = false;
      return { isValid, message };
    }
    if (!this.measure || !this.totalMeasure) {
      message =
        'Por favor, verifique as medidas inseridas no formulário antes de proseguir.';
      isValid = false;
      return { isValid, message };
    }
    if (this.customData) {
      if (!this.validateCustomData(this.customData)) {
        message =
          'Por favor verifique as informações inseridas antes de continuar.';
        isValid = false;
        return { isValid, message };
      }
    }

    if (isValid) {
      return { isValid, message };
    } else {
      return { isValid, message };
    }
  }

  validateCustomData(customData) {
    for (const key in customData) {
      if (customData.hasOwnProperty(key)) {
        const element = customData[key];
        if (typeof element !== 'boolean') {
          if (!element) {
            return false;
          }
        }
      }
    }
    return true;
  }
}

export class AdvertisementRowModel {
  constructor() {
    this.rows = [new Row()];
    this.data = new RowData();
  }
}
