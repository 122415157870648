import {
  Backdrop,
  Box,
  CircularProgress,
  makeStyles,
  Typography
} from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    background: theme.palette.background.default,
    color: theme.palette.primary.main
  }
}));

export default function Loading({ open, children, message, ...rest }) {
  const classes = useStyles();

  return (
    <>
      <Backdrop className={classes.backdrop} open={open}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <CircularProgress color="inherit" />
          <Typography variant="h1" color="primary">
            <strong>E-SOBRAS</strong>
          </Typography>
          {message && (
            <Typography variant="h4" color="primary">
              {message}
            </Typography>
          )}
        </Box>
      </Backdrop>
    </>
  );
}
