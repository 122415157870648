import React, { useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Currency from 'src/utils/currency';
import {
  Box,
  Button,
  Card,
  IconButton,
  makeStyles,
  SwipeableDrawer,
  Radio,
  FormControl,
  RadioGroup,
  FormControlLabel
} from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import { DeleteOutline, EditOutlined, Visibility } from '@material-ui/icons';
import { useState } from 'react';
import Advertisement from 'src/views/Advertisement';
import Api from 'src/api/Api';
import { getBrand } from 'src/views/Advertisement/functions';
import Loading from 'src/components/Loading';
import CustomPopUp from 'src/components/CustomPopUp';
import { options, columns } from './data';

const useStyles = makeStyles(theme => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const Results = ({ className, advertisements, reload, ...rest }) => {
  const [ads, setAds] = useState([]);
  const [removeAdvertisement, setRemoveAdvertisement] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    setAds(advertisements);
  }, [advertisements]);

  const handleRemove = async advertisement => {
    const response = await Api.removeBookmark(advertisement);

    if (response.error) {
      setMessage(response.error.message);
    } else {
      const newAds = ads.filter(ad => ad.id != advertisement.id);
      setRemoveAdvertisement(false);
      setAds(newAds);
      setMessage('Anúncio removido da sua lista de Favoritos!');
    }
    setShowAlert(true);
    setLoading(false);
  };

  const showButton = advertisement => (
    <IconButton
      title="detalhes"
      target="_blank"
      href={`/details/${advertisement.id}`}
      size="small"
    >
      <Visibility color="primary" fontSize="small" />
    </IconButton>
  );

  const removeButton = advertisement => {
    return (
      <IconButton
        title="remover Favorito"
        size="small"
        onClick={() => {
          setMessage(
            'Deseja Realmente remover este anúncio da sua lista de favoritos?'
          );
          setShowAlert(true);
          setRemoveAdvertisement(advertisement);
        }}
      >
        <DeleteOutline color="error" fontSize="small" />
      </IconButton>
    );
  };
  const getData = () =>
    ads.map(ad => [
      <img
        style={{ borderRadius: 60 }}
        width="auto"
        height={40}
        src={ad.product.imageUrl || ''}
      />,
      ad.product.brand.name + ' - ' + ad.product.name,
      ad.material.name,
      ad.user.name + ' ' + ad.user.surname,
      ad.amount,
      Number(ad.piecePrice) ? Currency(ad.piecePrice) : 'Doação',
      [removeButton(ad), showButton(ad)]
    ]);

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CustomPopUp
        visible={showAlert}
        message={message}
        setShow={setShowAlert}
        defaultActionTitle={removeAdvertisement ? 'Não' : 'OK'}
        replaceDefaultAction={() => setRemoveAdvertisement(false)}
        actionButtons={
          removeAdvertisement && (
            <Button onClick={() => handleRemove(removeAdvertisement)}>
              Sim
            </Button>
          )
        }
      ></CustomPopUp>
      <Loading open={loading} />
      <Box overflow="auto">
        <MUIDataTable
          title={'Listagem de Anuncios'}
          data={getData()}
          columns={columns}
          options={options}
          filter={false}
        />
      </Box>
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  advertisements: PropTypes.array.isRequired
};

export default Results;
