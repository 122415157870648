export const parseArrayFilters = (array) => {
    let values = [];
    for (var item of array) {
        values.push(item.name);
    }
    return values.join(",");
};

export const parseTargetFilters = (value) => {
    let correctValue = value.target.hasOwnProperty("checked")
        ? value.target.checked
        : value.target.value;
    return correctValue;
};
