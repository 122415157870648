import { createSlice } from '@reduxjs/toolkit';
import Api from 'src/api/Api';
import { AdvertisementDetails } from './Models/AdvertisementDetails';

export const advertisementDetailsReducer = createSlice({
  name: 'advertisementDetails',
  initialState: {
    details: new AdvertisementDetails(),
    error: false,
    errorMessage: '',
    isLoading: false
  },
  reducers: {
    setDetails: (state, action) => {
      state.details = new AdvertisementDetails(action.payload.ad);
    },
    setError: (state, action) => {
      const { error = false, errorMessage = '' } = action.payload;
      state.error = error;
      state.errorMessage = errorMessage;
    },
    setIsLoading: (state, action) => {
      const isLoading = action.payload.isLoading || false;
      state.isLoading = isLoading;
    }
  }
});

export const {
  setDetails,
  setError,
  setIsLoading
} = advertisementDetailsReducer.actions;

export const fetchAdvertisementData = id => async dispatch => {
  const errorMessage = 'Erro ao buscar detalhes do anúncio.';
  try {
    dispatch(setIsLoading({ isLoading: true }));
    const result = await Api.getAdvertisementDetails(id);
    if (result.error) {
      dispatch(
        setError({
          error: true,
          errorMessage: result.error || errorMessage
        })
      );
    } else {
      dispatch(setDetails(result));
    }
    dispatch(setIsLoading({ isLoading: false }));
  } catch (error) {
    dispatch(setError({ error: true, errorMessage }));
  }
};

export const selectDetails = state => state.advertisementDetails;

export default advertisementDetailsReducer.reducer;
