import axios from 'axios';
import { CONSTANTS } from '../config';
import { StatusCodes, getReasonPhrase } from 'http-status-codes';
import { setupCache } from 'axios-cache-adapter';

const cache = setupCache({
  maxAge: 2 * 60 * 1000
});

function getToken() {
  let token = localStorage.getItem(CONSTANTS.tokenKey);
  return token ? 'Bearer ' + token.replace(/"/g, '') : '';
}

const handleResponseErrors = {
  [StatusCodes.UNAUTHORIZED]: error => {
    if (
      window.location &&
      (window.location.pathname.match('advertisement') ||
        window.location.pathname.match('profile'))
    ) {
      localStorage.clear();
      window.location = '/login';
    }
    return {
      status: StatusCodes.UNAUTHORIZED,
      error: error.response ? error.response.data.error : ''
    };
  },
  [StatusCodes.FORBIDDEN]: error => ({
    error: error.response.data.error || error.response.data,
    status: error.response.status
  }),
  [StatusCodes.BAD_REQUEST]: error => ({
    error: error.response.data.error || error.response.data,
    status: error.response.status
  }),
  [StatusCodes.NOT_FOUND]: error => ({
    error: error.response.data.error || error.response.data,
    status: error.response.status
  }),
  [StatusCodes.INTERNAL_SERVER_ERROR]: error => ({
    error: error.response.data.error || error.response.data,
    status: error.response.status,
    message: getReasonPhrase(StatusCodes.INTERNAL_SERVER_ERROR)
  }),
  genericError: error => ({
    error,
    status: StatusCodes.INTERNAL_SERVER_ERROR,
    message: error.message
  })
};

const responseHandler = response => {
  return Promise.resolve(response.data);
};

const errorHandler = error => {
  if (error.response) {
    return handleResponseErrors[error.response.status](error);
  } else {
    return handleResponseErrors.genericError(error);
  }
};

const http = axios.create({
  baseURL: CONSTANTS.host,
  headers: {
    'Content-Type': 'application/json'
  },
  timeout: 8000,
  adapter: cache.adapter
});

http.interceptors.response.use(responseHandler, errorHandler);
http.interceptors.request.use(
  config => {
    config.headers.Authorization = getToken();
    return Promise.resolve(config);
  },
  error => {
    return Promise.reject(error);
  }
);

const cleanHttpClient = axios.create({
  timeout: 3000,
  adapter: cache.adapter
});

const uncachedHttpClient = axios.create({
  timeout: 3000,
  baseURL: CONSTANTS.host,
  headers: {
    'Content-Type': 'application/json'
  }
});

cleanHttpClient.interceptors.response.use(responseHandler, errorHandler);
uncachedHttpClient.interceptors.response.use(responseHandler, errorHandler);
uncachedHttpClient.interceptors.request.use(
  config => {
    config.headers.Authorization = getToken();
    return Promise.resolve(config);
  },
  error => {
    return Promise.reject(error);
  }
);

export { cleanHttpClient, uncachedHttpClient };

export default http;
