import formatCurrency from '../../../utils/currency';
import { isMobile } from 'react-device-detect';

export const productDetails = (ad, material) => {
  let names = ' ';
  for (const key in ad.customData) {
    if (ad.customData.hasOwnProperty(key)) {
      const value = ad.customData[key];
      const formMaterial = material.formStructure[key];
      const unit = formMaterial ? formMaterial.unit : '';
      const isNumberValue = !isNaN(value);
      const isBoolValue = typeof value === 'boolean';
      const sufix = isBoolValue
        ? ''
        : unit
        ? new String(unit).toUpperCase().concat(' ')
        : '';
      const prefix = names.trim().length > 0 ? ' x ' : '';
      if (key === 'length' || key === 'width' || key === 'thickness') {
        if (isNumberValue) {
          !isBoolValue && (names = names.concat(prefix, value, sufix));
        } else {
          names = names.concat(' ' + value.toUpperCase());
        }
      }
    }
  }
  return names;
};

export const getFullProductDescription = (product, ad, material) =>
  ''
    .concat(material.name)
    .concat(' - ')
    .concat(product.brand)
    .concat(' - ')
    .concat(product.name);

export const getAmountString = ad =>
  ''.concat(ad.amount).concat(ad.amount > 1 ? ' UNIDADES' : ' UNIDADE');
export const subscribedPrice = ad =>
  ad.oldTotalPrice
    ? formatCurrency(ad.oldTotalPrice)
    : formatCurrency(ad.totalPrice);

export const getBreadcrumbsData = (
  selectedSegment,
  selectedMaterial,
  selectedProduct,
  filters
) => {
  const breads = [
    {
      name: selectedSegment.name,
      link: isMobile ? '#search-results' : ''
    },
    {
      name: selectedMaterial.name,
      link: `?categoryId=${selectedSegment.id}&material=${selectedMaterial.id}`
    }
  ];

  if (selectedProduct.id) {
    breads.push(
      {
        name: selectedProduct.brand,
        link: `?categoryId=${selectedSegment.id}&material=${selectedMaterial.id}&mb=${selectedProduct.brand}`
      },
      {
        name: selectedProduct.name,
        link: `?categoryId=${selectedSegment.id}&material=${selectedMaterial.id}&productId=${selectedProduct.id}`
      }
    );
  } else {
    breads.push({
      name: filters.mb,
      link: `?categoryId=${selectedSegment.id}&material=${selectedMaterial.id}&mb=${filters.mb}`
    });
  }

  return breads;
};
