import React, { useState } from 'react';
import ImagePreview from '../ImagePreview';
import PopMenu from '../PopMenu';
import Api from 'src/api/Api';
import { pushUserAttributeValue, pullUserAttributeValue } from 'src/utils/user';
import {
  Whatsapp,
  Mail,
  Facebook,
  Twitter
} from 'react-social-sharing/dist-modules';
import formatCurrency from '../../utils/currency';
import { formatWithCustomSeparator } from '../../utils/dateFormatter';
import CustomPopUp from '../CustomPopUp';
import ClipboardButton from '../ClipboardButton';
import {
  getFullProductDescription,
  productDetails,
  getAmountString
} from '../../views/adverisementDetails/business';
import {
  Box,
  IconButton,
  Typography,
  Tooltip,
  CircularProgress,
  Badge
} from '@material-ui/core';
import {
  Favorite,
  FavoriteBorderOutlined,
  ShareOutlined
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import OutlineCssButton from '../OutlineCssButton';
import theme from 'src/theme';
import { useNavigate } from 'react-router';
import { WhatsApp as WhatsAppIco } from '@material-ui/icons';
import Avatar from '@material-ui/core/Avatar';
import {wppMessageSend, wppMessageSendOnElement} from '../../utils/wppMessageSend'

const useStyles = makeStyles(theme => ({
  resultsWrapper: {
    '& > div': {
      transition: 'all ease-in-out 0.6s'
    },
    '&:hover > div': {
      backgroundColor: `${theme.palette.background.default} !important`
    }
  },
  button: {
    position: 'relative',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    padding: 2,
    maxHeight: 40,
    margin: 0
  }
}));


export default function ResultsCard(props) {
  const { ad } = props;
  const { productId, material } = ad;
  const [shouldViewShare, setShouldViewShare] = useState(false);
  const [isBookMark, setIsBookMark] = useState(ad.bookmark);
  const [loading, setLoading] = useState(false);
  const [anchorElement, setAnchorElement] = useState(null);
  const [openWP, setOpenWP] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [linkTo, setLinkTo] = useState('');
  const [message, setMessage] = useState('');
  const [anchor, setAnchor] = useState(undefined);
  const eachPrice = ad.totalPrice / ad.amount;
  const shareUrl = 'https://' + window.location.host + '/details/' + ad.id;
  const classes = useStyles();
  const navigate = useNavigate();

  
  const handleShowWPInfo = async () => {
    const response = await Api.validate();
    if (response.valid) {
      setMessage(ad.userId.cellPhone);
      setLinkTo(wppMessageSend(ad.userId.cellPhone, ad, productId, material, shareUrl));
      setOpenWP(!openWP);
    } else {
      setShowMessage(true);
    }
  };

  async function handleBookmarkClick(advertisement) {
    setLoading(true);
    const response = await Api.validate();
    if (response.valid) {
      if (isBookMark) {
        await Api.removeBookmark(advertisement);
        pullUserAttributeValue('bookmarks', advertisement.id);
        setIsBookMark(false);
      } else {
        await Api.addBookmark(advertisement);
        pushUserAttributeValue('bookmarks', advertisement.id);
        setIsBookMark(true);
      }
    } else {
      localStorage.setItem('lastUrl', document.URL);
      navigate('/login', { replace: true });
    }
    setLoading(false);
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      padding={1}
      marginTop={2}
      className={classes.resultsWrapper}
    >
      <CustomPopUp
        replaceDefaultAction={() => {
          localStorage.setItem('lastUrl', document.URL);
          navigate('/login', { replace: true });
        }}
        defaultActionTitle="Entrar"
        message="Para ter acesso aos dados de contato, você precisa estar logado."
        visible={showMessage}
        setShow={setShowMessage}
      />
      <Box
        display="flex"
        flexWrap="wrap"
        padding={2}
        boxShadow={theme.shadows[26]}
        border={`solid thin ${theme.palette.primary.main}`}
        borderRadius={theme.shape.borderRadius}
        alignItems="center"
        bgcolor="white"
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          flex="1 0 160px"
          marginRight={2}
        >
          <ImagePreview
            bordered={false}
            position="relative"
            maxHeight={110}
            maxWidth={140}
            isStatic={true}
            isDefault={ad.unit && ad.unit !== 'm²'}
            alt={productId.name}
            img={productId.imageUrl}
            width={ad.customData.width}
            height={ad.customData.length}
            callback={_ => {}}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          flexWrap="wrap"
          width="100%"
          justifyContent="space-between"
          flex="50 0 300px"
        >
          <Box
            position="relative"
            display="flex"
            justifyContent="space-between"
            paddingBottom={2}
          >
            <OutlineCssButton
              style={{ paddingLeft: '7px', paddingRight: '7px' }}
              className={classes.button}
              onClick={e => {
                setAnchorElement(e.currentTarget);
                setShouldViewShare(!shouldViewShare);
              }}
            >
              <ShareOutlined color="white" />
              <strong>Compartilhar</strong>
              <PopMenu element={anchorElement} open={shouldViewShare}>
                <Tooltip title="Compartilhar por Whatsapp." placement="top">
                  <IconButton size="small">
                    <Whatsapp
                      solidcircle
                      small
                      link={shareUrl}
                      message={wppMessageSendOnElement(ad, productId, material)}
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Compartilhar por E-mail." placement="top">
                  <IconButton size="small">
                    <Mail solidcircle small link={shareUrl} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Compartilhar no Facebook." placement="top">
                  <IconButton size="small">
                    <Facebook solidcircle small link={shareUrl} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Copiar link" placement="top">
                  <IconButton size="small">
                    <ClipboardButton title="Copiar link" link={shareUrl} />
                  </IconButton>
                </Tooltip>
              </PopMenu>
            </OutlineCssButton>
            <IconButton onClick={() => handleBookmarkClick(ad)} color="primary">
              {loading ? (
                <CircularProgress size={20} />
              ) : isBookMark ? (
                <Favorite color="secondary" />
              ) : (
                <FavoriteBorderOutlined color="primary" />
              )}
            </IconButton>
          </Box>
          <Typography component="p" variant="subtitle1" wrap>
            <strong>
              {getFullProductDescription(productId, ad, material)}
            </strong>
          </Typography>
          <Typography component="p" variant="caption" wrap>
            {`Medidas: ${productDetails(ad, material)}`}
          </Typography>
          <Typography component="p" variant="caption" wrap>
            <u>Qtd:</u> {getAmountString(ad)} <strong>Cod. MA-{ad.sequenceValue}</strong>
          </Typography>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="flex-end"
            position="relative"
          >
            <Typography variant="h2" component="span">
              <strong>
                {Number(ad.piecePrice) ? formatCurrency(ad.piecePrice) : ``}
              </strong>
            </Typography>
            <Typography variant="caption" component="small">
              {Number(ad.piecePrice) ? 'cada' : ''}
            </Typography>
            {Number(ad.percentOff) > 0 && (
              <Box
                borderRadius={5}
                padding={0.5}
                maxWidth={150}
                ml={1}
                bgcolor={theme.palette.secondary.main}
                color="white"
              >
                <Typography component="P" variant="caption">
                  <strong>- {ad.percentOff}% à vista</strong>
                </Typography>
              </Box>
              // <Box
              //   borderRadius={100}
              //   padding={0.5}
              //   width={80}
              //   height={80}
              //   marginLeft="auto"
              //   right={15}
              //   bottom={-20}
              //   position="absolute"
              //   bgcolor={theme.palette.secondary.main}
              //   display="flex"
              //   flexDirection="column"
              //   alignItems="center"
              //   justifyContent="center"
              //   color="white"
              // >
              //   <Typography component="P" variant="body1">
              //     <strong>- {ad.percentOff}%</strong>
              //   </Typography>
              //   <Typography component="P" variant="caption">
              //     <small>
              //       <strong>à vista</strong>
              //     </small>
              //   </Typography>
              // </Box>
            )}
          </Box>
          {/*<Box display="flex" flexDirection="row" alignItems="center">
            <Typography color="primary" style={{ position: 'relative' }}>
              <strong>
                {Number(ad.piecePrice)
                  ? formatCurrency(ad.totalPrice)
                  : `DOAÇÃO!`}{' '}
                <small>{Number(ad.piecePrice) ? 'Total' : ``}</small>
              </strong>
            </Typography>
                </Box>*/}
          <Box
            display="flex"
            padding={0}
            marginBottom={0}
            width="100%"
            >
            <Box
            display="flex"
            flex="0 1 auto"
            alignItems="center"
            paddingRight={1}
            marginBottom={0}

            >
             <Avatar alignItems="center" justifyContent="center" alt="Remy Sharp" src={ad.userId.userImage || 'https://esobras-imgs.s3.amazonaws.com/ffc9bd99597903694029ca70404c1f0b-avatar.png'} className={classes.large}/>
            </Box>
            <Box
            display="flex"
            flex="0 1 auto"
            flexWrap="wrap"
            alignItems="flex-start"
            padding={0}
            marginBottom={0}
            > 
              <Box
              flex="0 0 100%"
              padding={0}
              marginBottom={0}
              width="100%"
              > 
                <Typography
                  href={`/search?userId=${ad.userId.id}`}
                  target="_blank"
                  color="primary"
                  component="a"
                  title="Ver anúncios do usuário."
                  paddingRight={10}
                  flexDirection="column"
                  alignItems="flex-end"
                >
                  <strong style={{paddingRight:"5px"}}>{`Por:  ${ad.userName} `}</strong>
                </Typography>
                <Typography
                  title={ad.userId.isLogged ? 'Online' : 'Offline'}
                  style={{ lineHeight: '1', cursor: 'pointer' }}
                  component="span"
                  variant="subtitle2"
                  color={ad.userId.isLogged ? 'primary' : 'error'}
                  flexDirection="column"
                  alignItems="flex-end"
                >
                  * Online
                </Typography>
              </Box>
              <Box
              flex="0 0 100%"
              padding={0}
              marginBottom={0}
              width="100%"
              >            
                <Typography
                  wrap="true"
                  lineHeight="1"
                  size="10pt"
                  paddingTop="0"
                  paddingBottom="0"
                  className="text-dark"
                >
                  {`${ad.neighborhood} - ${ad.city} ${ad.state}`}
                </Typography>

              </Box>
            </Box>
          </Box>

          <Box
            display="flex"
            flexWrap="wrap"
            justifyContent="space-between"
            paddingTop={1}
            width="100%"
          >
            <OutlineCssButton
              style={{ paddingLeft: '7px', paddingRight: '7px',backgroundColor: '#539eed' }}             
              href={`/details/${ad.id}`}
              target="_blank"
              className={classes.button}
            >
              <strong>ver anúncio</strong>
            </OutlineCssButton>
            <OutlineCssButton
              style={{ paddingLeft: '3px', paddingRight: '7px' }}
              onClick={e => {
                setAnchor(e.currentTarget);
                handleShowWPInfo();
              }}
              target="_blank"
              className={classes.button}
            >
              <PopMenu open={openWP} element={anchor}>
                {message && (
                  <Typography style={{ padding: 15 }} component="p" variant="h5">
                    <strong>
                      <a target="_blank" href={linkTo}>
                        {message}
                      </a>
                    </strong>
                  </Typography>
                )}
              </PopMenu>
              <WhatsAppIco style={{marginRight: "3px"}} /> <strong>  WhatsApp vendedor</strong>
            </OutlineCssButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
