import { Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Arrow = styled.span`
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  color: var(--primary);
`;

export default function Breadcrumbs(props) {
  let items = [];
  function getItems(data) {
    if (Array.isArray(data) && data.length > 0)
      items = data.map((breads, index) => {
        const { name, link } = breads;
        return (
          <Link
            key={`${name}-${index}`}
            to={link}
            target={index === 0 ? '' : '_parent'}
          >
            <Typography
              color="textPrimary"
              gutterBottom
              component="small"
              noWrap
            >
              <small style={{ fontSize: '8pt' }}>
                {index !== 0 && name && <Arrow>{'>'}</Arrow>}
                {name}
              </small>
            </Typography>
          </Link>
        );
      });
    return items;
  }
  return <div className="d-flex p-0">{getItems(props.data)}</div>;
}
