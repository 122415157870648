import React from 'react';
import { Box, Container, Typography, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import AdBuilder from '../../components/AdManager';
import useMobileDetect from '../../utils/useMobileDetect';
import theme from 'src/theme';

const useStyles = makeStyles(theme => ({
  title: {
    margin: '1.5rem 0 0.8rem 0'
  }
}));

const AboutUs = () => {
  const classes = useStyles();
  const detectMobile = useMobileDetect();

  return (
    <Page className={classes.root} title="Porquê Nós?">
      <Box
        display="flex"
        flexDirection="column"
        marginTop={4}
        height="100%"
        className={classes.centerMd}
      >
        {detectMobile.isDesktop() && (
          <div style={{ margin: '0px auto 24px' }}>
            <AdBuilder size="[970,250]" id="ad-banner-970x250"></AdBuilder>
          </div>
        )}
        {detectMobile.isMobile() && (
          <div
            style={{
              margin: '0px auto 24px',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <AdBuilder size="[320,100]" id="ad-banner-320x100"></AdBuilder>
          </div>
        )}
        <Container maxWidth="md">
          <Box flexDirection="column" display="Flex">
            <Typography
              align="justify"
              gutterBottom
              variant="h4"
              className={classes.title}
            >
              <strong>Para quê anunciar no E-Sobras?</strong>
            </Typography>
            <Typography
              align="justify"
              gutterBottom
              variant="body1"
              component="p"
            >
              A plataforma E-Sobras foi criada da concepção do reaproveitamento
              e comercialização de sobras padronizadas e não padronizadas,
              incluindo todos os seguimentos que gerem sobras/retalhos e que
              possam ser reaproveitados por uma comunidade de vários usuários.
            </Typography>
            <Typography gutterBottom variant="h4" className={classes.title}>
              <strong>A quem se destina</strong>
            </Typography>
            <Typography
              align="justify"
              gutterBottom
              variant="body1"
              component="p"
            >
              A E-Sobras é uma plataforma de cadastro de sobras/retalhos aonde o
              usuário cadastra a sua sobra e compartilha o anúncio com outros
              vários usuários cadastrados. O intuito da plataforma é a
              colaboração entre usuários, minimizar descarte de materiais
              reaproveitados, abrir uma comercialização inteligente de
              sobras/retalhos de produtos que ainda são extremamente aceitáveis
              e procurados por profissionais e empresas no mercado.
            </Typography>
            <Typography
              align="justify"
              gutterBottom
              variant="body1"
              component="p"
            >
              O serviço disponibilizado pelo E-Sobras possibilita, de forma
              inteligente a divulgação das suas sobras, através de um suporte{' '}
              <strong>
                disponível 24 horas por dia, 365 dias por anos e acessível a
                partir de qualquer parte do mundo.
              </strong>
            </Typography>
            <Typography
              align="justify"
              gutterBottom
              variant="body1"
              component="p"
            >
              <strong>
                Não perca tempo, cadastre e encontre sua sobra em nossa
                plataforma. A única plataforma de sobras do Brasil!
              </strong>
            </Typography>
            <Box mt={3}>
              <Typography
                align="justify"
                gutterBottom
                variant="body1"
                component="p"
              >
                <a
                  href="/register"
                  target="_blank"
                  style={{ font: 'inherit', color: theme.palette.primary.main }}
                >
                  Comece já!.
                </a>
              </Typography>
            </Box>
            <Box mt={3}>
              <Typography gutterBottom variant="body1" component="p">
                <strong>Equipe E-Sobras</strong>
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
      {detectMobile.isMobile() && (
        <div
          style={{
            margin: '24px auto 0px',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <AdBuilder size="[300,250]" id="ad-banner-300x250"></AdBuilder>
        </div>
      )}
    </Page>
  );
};

export default AboutUs;
