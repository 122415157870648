import api from '../../api/Api';

export function getBrand(product) {
  if (!product || !product.name) return '';
  return `${product.brand} - ${product.name}`;
}

export function normalizeNumberValues(value, unit) {
  if (unit && unit !== 'm²') {
    return Number(value);
  }
  return (Math.round(Number(value) * 1000) / 1000).toFixed(3);
}

export function getMaterials(categoryId) {
  return api.getMaterialsBySegment(categoryId);
}

export function getCategories() {
  return api.getSegments();
}

export function saveAdvertisements(advertisements) {
  return api.saveAdvertisements(advertisements);
}

export function getProducts(materialId) {
  return api.getProductsByMaterialId(materialId);
}
