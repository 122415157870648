import React, { useState } from 'react';
import { Box, Container, Typography, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Search from '../../components/Search';
import { useEffect } from 'react';
import CustomPopUp from 'src/components/CustomPopUp';
import Api from 'src/api/Api';
import querystring from 'query-string';
import AdBuilder from '../../components/AdManager';
import useMobileDetect from '../../utils/useMobileDetect';
import { useDispatch } from 'react-redux';
import {
  fetchSegments,
  searchResults
} from 'src/components/Search/SearchReducer';
import { queryFilters } from 'src/components/Filter/FilterReducer';

const useStyles = makeStyles(theme => ({
  textHome: {
    fontSize: '10vw',
    fontWeight: '700',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      fontSize: '42pt'
    },
    position: 'reative'
  },
  textBeta: {
    position: 'absolute',
    bottom: '80%',
    transform: 'translateX(-80%)',
    fontSize: '12pt',
    [theme.breakpoints.up('md')]: {
      bottom: '75%'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '11pt'
    }
  },
  centerMd: {
    minHeight: '43vh'
  }
}));

const LoginView = () => {
  const classes = useStyles();
  const [message, setMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const detectMobile = useMobileDetect();
  const dispatch = useDispatch();

  const isFromSharing = async () => {
    const query = window.location.search;
    await dispatch(fetchSegments());
    if (query) {
      await dispatch(queryFilters(query));
      await dispatch(searchResults(query));
    }
  };

  useEffect(() => {
    const activateUser = async i => {
      const response = await Api.activate(i);
      if (response.error) {
        setMessage(response.error);
      } else {
        setMessage('Usuário ativado com sucesso!');
      }
      setShowAlert(true);
    };
    if (window.location.search) {
      const query = querystring.parse(window.location.search.replace('?', ''));
      if (query.i) {
        activateUser(query.i);
      }
      if (query.ra) {
        setMessage('Alerta removido com sucesso');
        setShowAlert(true);
      }
      if (query.productId && query.material && query.categoryId) {
        isFromSharing(query);
      }
    }
  }, [window.location.search]);

  return (
    <Page className={classes.root} title="E-Sobras">
      <CustomPopUp visible={showAlert} message={message} />
      <Box
        display="flex"
        flexDirection="column"
        marginTop={4}
        height="100%"
        className={classes.centerMd}
      >
        <Container maxWidth="lg">
          <Box flexDirection="column" alignItems="center" display="Flex">
            <Typography color="primary" className={classes.textHome}>
              e-sobras
            </Typography>
            <Container maxWidth="md">
              <Search preview placeholder="Buscar" />
              {detectMobile.isDesktop() && (
                <AdBuilder
                  style={{
                    marginTop: '30px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    bottom: '20px'
                  }}
                  size="[728,90]"
                  id="ad-banner-728x90"
                ></AdBuilder>
              )}
            </Container>
          </Box>
        </Container>
        {detectMobile.isMobile() && (
          <div
            style={{
              margin: '24px auto 0px',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <AdBuilder size="[300,250]" id="ad-banner-300x250"></AdBuilder>
          </div>
        )}
      </Box>
    </Page>
  );
};

export default LoginView;
