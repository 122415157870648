import React, { useEffect, useState } from 'react';
import requestFilters from './__mocks__/filtersMock';
import './css/index.css';
import { useSelector, useDispatch } from 'react-redux';
import {
  removeFilter,
  searchByFilters,
  selectFiltersState,
  validateAndSetFilters
} from './FilterReducer';
import {
  Box,
  Chip,
  Input,
  makeStyles,
  MenuItem,
  Select,
  Slider,
  Switch,
  Typography
} from '@material-ui/core';
import _ from 'lodash';
import OutlineCssButton from '../OutlineCssButton';
import BasicField from './components/BasicField';

const useStyles = makeStyles(theme => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  },
  noLabel: {
    marginTop: theme.spacing(3)
  },
  filterButton: {
    border: `dashed thin ${theme.palette.primary.main}`,
    borderRadius: 60,
    marginTop: 15
  },
  clearFilterButton: {
    border: `dashed thin ${theme.palette.secondary.main}`,
    borderRadius: 60,
    marginTop: 15,
    color: theme.palette.secondary.main
  }
}));

const getFilters = materialId =>
  new Promise(resolve => resolve(requestFilters(materialId)));

class Filter {
  constructor(filter) {
    const { material, filters } = filter || {};
    this.material = material || '';
    this.filters = filters || [];
  }
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

export default function Filters(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const searchState = useSelector(selectFiltersState);
  const [filterSpecs, setFilterSpecs] = useState(new Filter());

  const onChange = (object, field) => {
    if (object.target.type === 'checkbox') {
      object = object.target.checked;
    } else {
      object = object.target.value.join(',');
    }
    dispatch(
      validateAndSetFilters([
        {
          key: field,
          value: object
        }
      ])
    );
  };

  useEffect(() => {
    if (searchState.filterString) {
      window.location = '/search?' + searchState.filterString;
    }
  }, [searchState.filterString]);

  const setRange = filter => {
    dispatch(validateAndSetFilters(filter));
  };

  const onFilter = async () => {
    await dispatch(removeFilter({ key: 'page' }));
    dispatch(searchByFilters());
  };

  // const clearFilters = async () => {
  //   if (filterSpecs.filters && filterSpecs.filters.length > 0) {
  //     for (var filter of filterSpecs.filters) {
  //       await dispatch(removeFilter({ key: filter.filter }));
  //     }
  //   }
  // };

  const getFieldValue = filter => {
    let filterValue = searchState.filters[filter];
    let values = [];
    if (filterValue && typeof filterValue === 'string') {
      let splitted = filterValue.split(',');
      if (splitted.length > 0) {
        values = splitted;
      }
    }
    return values;
  };

  const FieldTypes = {
    select: 'select',
    check: 'check',
    range: 'range',
    input: 'input',
    multiSelect: 'multiSelect'
  };

  const getField = {
    [FieldTypes.select]: fieldSpec => (
      <BasicField label={fieldSpec.label}>
        <Select
          fullWidth
          multiple
          variant="outlined"
          value={getFieldValue(fieldSpec.filter)}
          onChange={selectedList => onChange(selectedList, fieldSpec.filter)}
          input={<Input />}
          MenuProps={MenuProps}
          renderValue={selected => (
            <div className={classes.chips}>
              {selected.map(value => (
                <Chip key={value} label={value} className={classes.chip} />
              ))}
            </div>
          )}
        >
          {fieldSpec.values.map(val => (
            <MenuItem key={val} value={val.toString()}>
              {val}
            </MenuItem>
          ))}
        </Select>
      </BasicField>
    ),
    [FieldTypes.check]: fieldSpec => (
      <BasicField label={fieldSpec.label}>
        <Switch
          color="primary"
          name={fieldSpec.filter}
          checked={
            searchState.filters[fieldSpec.filter] &&
            searchState.filters[fieldSpec.filter] !== 'false'
          }
          onChange={events => {
            onChange(events, fieldSpec.filter);
          }}
        />
      </BasicField>
    ),
    [FieldTypes.range]: fieldSpec => (
      <BasicField label={fieldSpec.label}>
        <Slider
          value={[
            searchState.filters[fieldSpec.minFilter] || fieldSpec.minValue,
            searchState.filters[fieldSpec.maxFilter] || fieldSpec.maxValue
          ]}
          min={fieldSpec.minValue}
          max={fieldSpec.maxValue}
          step={5}
          valueLabelDisplay="auto"
          onChange={(e, values) => {
            setRange([
              { key: fieldSpec.minFilter, value: values[0] },
              { key: fieldSpec.maxFilter, value: values[1] }
            ]);
          }}
          getAriaValueText={value => `${value} (${fieldSpec.unit})`}
        />
      </BasicField>
    )
  };

  async function requestFilters(material) {
    let resp = await getFilters(material);
    setFilterSpecs(new Filter(resp));
  }

  useEffect(() => {
    requestFilters(props.material);
  }, [props.material]);

  return (
    <>
      <BasicField direction="row" label="Produtos com Desconto">
        <Switch
          color="primary"
          name="percentOff"
          key={Math.random()}
          checked={
            searchState.filters['percentOff'] &&
            searchState.filters['percentOff'] !== 'false'
          }
          onChange={events => {
            onChange(events, 'percentOff');
            onFilter();
          }}
        />
      </BasicField>
      <BasicField direction="row" label="Produtos para doação">
        <Switch
          color="primary"
          name="donation"
          key={Math.random()}
          checked={
            searchState.filters['donation'] &&
            searchState.filters['donation'] !== 'false'
          }
          onChange={events => {
            onChange(events, 'donation');
            onFilter();
          }}
        />
      </BasicField>
      {Array.isArray(filterSpecs.filters) && filterSpecs.filters.length > 0 ? (
        filterSpecs.filters.map(filterSpec =>
          getField[FieldTypes[filterSpec.type]](filterSpec)
        )
      ) : (
        <Box
          bgcolor="#f9ffe6"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          width="100%"
          paddingLeft="1rem"
          paddingRight="1rem"
          paddingBottom="2rem"
          paddingTop="2rem"
          borderRadius={10}
        >
          <Typography
            align="center"
            variant="body1"
            component="span"
            variant="body1"
          >
            <i>
              Selecione o Segmento e o Material para liberar as opções de
              Filtragem.
            </i>
          </Typography>
        </Box>
      )}
      {filterSpecs.filters && filterSpecs.filters.length > 0 && (
        <>
          <OutlineCssButton
            className={classes.filterButton}
            color="primary"
            onClick={onFilter}
          >
            Filtrar
          </OutlineCssButton>
          {/* <OutlineCssButton
            className={classes.clearFilterButton}
            color="primary"
            onClick={clearFilters}
          >
            Limpar Filtros
          </OutlineCssButton> */}
        </>
      )}
    </>
  );
}
