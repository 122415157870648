import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchAdvertisementData,
  selectDetails
} from './AdvertisementDetailsReducer';
import theme from 'src/theme';
import { Whatsapp, Mail, Facebook, Twitter } from 'react-social-sharing';
import { useEffect } from 'react';
import Breadcrumbs from '../../components/Breadcrumbs';
import formatCurrency from '../../utils/currency';
import {
  getFullProductDescription,
  productDetails,
  getAmountString,
  getBreadcrumbsData
} from './business';
import { getDescriptionStructure } from './business/generators';
import { formatWithCustomSeparator } from '../../utils/dateFormatter';
import PopMenu from '../../components/PopMenu';
import ClipboardButton from '../../components/ClipboardButton';
import Advice from './styles/Advise';
import UserCardDetails from '../../components/UserCardDetails';
import ImagePreview from '../../components/ImagePreview';
import { useState } from 'react';
import { selectSearchState } from '../../components/Search/SearchReducer';
import { selectFiltersState } from '../../components/Filter/FilterReducer';
import {
  Box,
  Container,
  makeStyles,
  Typography,
  Tooltip,
  IconButton
} from '@material-ui/core';
import { ArrowDown } from 'react-feather';
import Page from 'src/components/Page';
import OutlineCssButton from 'src/components/OutlineCssButton';
import CustomPopUp from 'src/components/CustomPopUp';
import Loading from 'src/components/Loading';
import AdBuilder from '../../components/AdManager';
import useMobileDetect from '../../utils/useMobileDetect';
import { useNavigate } from 'react-router';
import Api from 'src/api/Api';
import { WhatsApp as WhatsAppIco } from '@material-ui/icons';
import {wppMessageSend, wppMessageSendOnElement} from '../../utils/wppMessageSend'

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(3)
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    marginRight: 10,
    transition: 'all ease-in-out 0.6s',
    '&:hover': {
      filter: 'brightness(.9)',
      backgroundColor: theme.palette.primary.main
    }
  },
  mdLgPadding: {
    [theme.breakpoints.up('sm')]: {
      padding: '1.5rem'
    }
  }
}));

export default function AdvertisementDetails() {
  const classes = useStyles();
  const { isLoading, details: state, error, errorMessage } = useSelector(
    selectDetails
  );
  const [shouldViewShare, setShouldViewShare] = useState(false);
  const [message, setMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [anchorElement, setAnchorElement] = useState(null);
  const { selectedProduct, selectedMaterial, selectedSegment } = useSelector(
    selectSearchState
  );
  const filters = useSelector(selectFiltersState);
  const dispatch = useDispatch();
  const detectMobile = useMobileDetect();
  const navigate = useNavigate();

  useEffect(() => {
    const params = window.location.pathname.split('/');
    if (params) {
      const id = params[params.length - 1];
      dispatch(fetchAdvertisementData(id));
    }
  }, []);

  useEffect(() => {
    if (error) {
      setMessage(errorMessage);
      setShowAlert(true);
    }
  }, [error, errorMessage]);

  const [linkTo, setLinkTo] = useState('');
  const [messagewp, setMessagewp] = useState('');
  const [openMail, setOpenMail] = useState(false);
  const [openPhone, setOpenPhone] = useState(false);
  const [openWP, setOpenWP] = useState(false);
  const [anchor, setAnchor] = useState(undefined);
  const [showMessagewp, setShowMessagewp] = useState(false);

  const product = state.product;
  const ad = state.ad;
  const user = state.user;
  const material = state.material;
  const productId = ad;
  const shareUrl = document.URL;
  const eachPrice = ad.totalPrice / ad.amount;


  const handleShowWPInfo = async () => {
    const response = await Api.validate();
    if (response.valid) {
      setMessagewp(user.cellPhone);
      setLinkTo(wppMessageSend(user.cellPhone,ad, product, material, shareUrl));
      setOpenWP(!openWP);
    } else {
      setShowMessagewp(true);
    }
  };
  return (
    <Page
      className={classes.root}
      title={`Detalhes do anúncio: ${product.name}`}
    >
      <CustomPopUp
        replaceDefaultAction={() => {
          localStorage.setItem('lastUrl', document.URL);
          navigate('/login', { replace: true });
        }}
        defaultActionTitle="Entrar"
        message="Para ter acesso aos dados de contato, você precisa estar logado."
        visible={showMessagewp}
        setShow={setShowMessagewp}
      />
      <Loading open={isLoading} />
      {detectMobile.isDesktop() && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '25px'
          }}
        >
          <AdBuilder size="[728,90]" id="ad-banner-728x90"></AdBuilder>
        </div>
      )}
      {detectMobile.isMobile() && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '15px'
          }}
        >
          <AdBuilder size="[320,100]" id="ad-banner-320x100"></AdBuilder>
        </div>
      )}
      <CustomPopUp
        message={message}
        visible={showAlert}
        setShow={setShowAlert}
        replaceDefaultAction={() => navigate('/', { replace: true })}
      />
      <Container maxWidth="md">
        <Box display="flex" flexWrap="wrap" flex="1" width="100%">
          <Box
            display="flex"
            flexDirection="column"
            flex="1 0 320px"
            marginBottom="3.5rem"
            className={classes.mdLgPadding}
          >
            <Breadcrumbs
              data={getBreadcrumbsData(
                selectedSegment,
                selectedMaterial,
                selectedProduct,
                filters
              )}
            />
            <ImagePreview
              position="relative"
              maxHeight={290}
              maxWidth={290}
              isStatic={true}
              isDefault={material.unit && material.unit !== 'm²'}
              alt={product.name}
              img={product.imageUrl}
              height={ad.customData.length || 0}
              width={ad.customData.width || 0}
              callback={() => {}}
            />
            <Box marginTop={2}>
              <Typography variant="caption" paddingTop="10px">
                <strong>Cod. MA-{ad.sequenceValue}</strong>
              </Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            flex="1 0 320px"
            marginBottom=".5rem"
            className={classes.mdLgPadding}
          >
            <Typography color="textSecondary">
              Este vendedor possui{' '}
              <b>{`${user.totalAds ? user.totalAds : '1'} `}</b> anúncio(s)
            </Typography>
            <Typography variant="h3" component="p">
              <strong>
                {getFullProductDescription(product, ad, material)}
              </strong>
            </Typography>
            <Typography variant="subtitle1" component="h3">
              <u>Medidas:</u> {`${productDetails(ad, material)}`}
            </Typography>
            <Typography variant="body1">
              <u>Qtd:</u> {getAmountString(ad)}
            </Typography>
            <Box
              display="flex"
              flexDirection="column"
              width="100%"
              marginTop={3}
              padding={0}
            >
              <Box
                display="flex"
                flexDirection="row"
                alignItems="flex-end"
                position="relative"
              >
                <Typography gutterBottom variant="h2" component="span">
                  <strong>
                    {Number(eachPrice) ? formatCurrency(eachPrice) : 'Doação!'}
                  </strong>{' '}
                  <Typography variant="body1" component="small">
                    {Number(ad.piecePrice) ? 'cada' : ''}
                  </Typography>
                </Typography>
                {Number(ad.percentOff) > 0 && (
                  <Box
                    borderRadius={5}
                    padding={0.5}
                    maxWidth={150}
                    ml={1}
                    mb={2}
                    bgcolor={theme.palette.secondary.main}
                    color="white"
                  >
                    <Typography component="P" variant="caption">
                      <strong>- {ad.percentOff}% à vista</strong>
                    </Typography>
                  </Box>
                  // <Box
                  //   borderRadius={100}
                  //   padding={0.5}
                  //   width={80}
                  //   height={80}
                  //   marginLeft="auto"
                  //   right={15}
                  //   bottom={-20}
                  //   position="absolute"
                  //   bgcolor={theme.palette.secondary.main}
                  //   display="flex"
                  //   flexDirection="column"
                  //   alignItems="center"
                  //   justifyContent="center"
                  //   color="white"
                  // >
                  //   <Typography component="P" variant="body1">
                  //     <strong>- {ad.percentOff}%</strong>
                  //   </Typography>
                  //   <Typography component="P" variant="caption">
                  //     <small>
                  //       <strong>à vista</strong>
                  //     </small>
                  //   </Typography>
                  // </Box>
                )}
              </Box>
              <Typography gutterBottom color="primary">
                <strong>
                  {Number(ad.totalPrice) ? 'Total:  ' : ``}
                  {Number(ad.totalPrice) ? formatCurrency(ad.totalPrice) : ``}
                </strong>
              </Typography>
              <Typography gutterBottom>
                Publicado em: {formatWithCustomSeparator(ad.createdAt)}
              </Typography>
              <Box
                display="flex"
                alignItems="center"
                padding={0}
                marginBottom={2}
                >
                <Typography
                  href={`/search?userId=${ad.userId.id}`}
                  target="_blank"
                  color="primary"
                  component="a"
                  title="Ver anúncios do usuário."
                >
                  <strong style={{paddingRight:"5px"}}>{`Por:  ${user.name} ${user.surname}`}</strong>
                </Typography>
                <Typography
                  title={ad.userId.isLogged ? 'Online' : 'Offline'}
                  style={{ lineHeight: '1', cursor: 'pointer' }}
                  component="span"
                  variant="subtitle2"
                  color={ad.userId.isLogged ? 'primary' : 'error'}
                >
                  * Online
                </Typography>
              </Box>
              <Box display="flex" padding={0} height="100%" marginTop={3}>
                <OutlineCssButton
                  className={classes.button}
                  onClick={event => {
                    setAnchorElement(event.currentTarget);
                    setShouldViewShare(!shouldViewShare);
                  }}
                >
                  <strong>Compartilhar</strong>
                  <PopMenu open={shouldViewShare} element={anchorElement}>
                    <Tooltip title="Compartilhar no Whatsapp." placement="top">
                      <IconButton size="small">
                        <Whatsapp
                          solidcircle
                          small
                          link={shareUrl}
                          message={wppMessageSendOnElement(ad, product, material)}
                        />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Compartilhar por E-mail." placement="top">
                      <IconButton size="small">
                        <Mail solidcircle small link={shareUrl} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Compartilhar no Facebook." placement="top">
                      <IconButton size="small">
                        <Facebook solidcircle small link={shareUrl} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Copiar URL." placement="top">
                      <IconButton size="small">
                        <ClipboardButton
                          callback={text => {
                            setMessage(text);
                            setShowAlert(true);
                          }}
                          title="Copiar link"
                          link={shareUrl}
                        />
                      </IconButton>
                    </Tooltip>
                  </PopMenu>
                </OutlineCssButton>

                { /*<OutlineCssButton className={classes.button} href="#details">
                  <strong>Detalhes</strong>
                        </OutlineCssButton> */}


                <OutlineCssButton
                  style={{ paddingLeft: '7px', paddingRight: '7px',   }}
                  onClick={e => {
                    setAnchor(e.currentTarget);
                    handleShowWPInfo();
                  }}
                  target="_blank"
                  className={classes.button}
                >
                  <PopMenu open={openWP} element={anchor}>
                    {messagewp && (
                      <Typography style={{ padding: 15 }} component="p" variant="h5">
                        <strong>
                          <a target="_blank" href={linkTo}>
                            {messagewp}
                          </a>
                        </strong>
                      </Typography>
                    )}
                  </PopMenu>
                  <WhatsAppIco/> <strong style={{ marginLeft: 5 }}> WhatsApp vendedor</strong>
                </OutlineCssButton>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          padding={0}
          justifyContent="center"
          width="100%"
          className={classes.mdLgPadding}
        >
          <a href="#details" >
            <ArrowDown size={40} backgroundColor='#539eed'/>
          </a>
        </Box>
        <Advice userName={state.user.name} />
        {detectMobile.isDesktop() && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '25px'
            }}
          >
            <AdBuilder size="[970,250]" id="ad-banner-970x250"></AdBuilder>
          </div>
        )}
        {detectMobile.isMobile() && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '25px'
            }}
          >
            <AdBuilder size="[300,250]" id="ad-banner-300x250"></AdBuilder>
          </div>
        )}
        <Box
          display="flex"
          flexDirection="row"
          flex="1 0 300px"
          marginTop="4rem"
          marginBottom=".5rem"
          flexWrap="wrap-reverse"
          className={classes.mdLgPadding}
        >
          <UserCardDetails
            user={user}
            address={ad}
            adInfo={{ product, ad, material }}
          />
          <Box
            display="flex"
            flexDirection="column"
            flex="3 0 350px"
            id="details"
            marginBottom=".5rem"
            className={classes.mdLgPadding}
          >
            <Typography variant="h3">
              <strong>DETALHES:</strong>
            </Typography>
            <Box
              display="flex"
              flexDirection="column"
              marginTop={3}
              maxHeight={500}
              padding={0}
            >
              <Typography color="textSecondary" variant="h4" component="p">
                <strong>MARCA/MODELO:</strong>
              </Typography>
              <Typography variant="h3">
                {`${product.brand} - ${product.name}`}
              </Typography>
              <Box
                display="flex"
                flexWrap="wrap"
                flexDirection="column"
                maxHeight={400}
                padding={0}
              >
                {getDescriptionStructure(ad.customData, material.formStructure)}
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </Page>
  );
}
