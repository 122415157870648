export const CONSTANTS = {
  // host: '/api',
  // host: '//esobras-api.herokuapp.com',
  //host: '//esobras-dev-net.umbler.net/api',
  // host: '//esobras-api-com-br.umbler.net/api',
  host: '//api.esobras.com.br',
  userKey: '__es_us_',
  tokenKey: '__es_tk',
  cookiesKey: '__es_acc'
};
