import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import CssInputText from './CssTextField';
import * as _ from 'lodash';
import { cleanHttpClient as httpClient } from 'src/api/httpClient';

const SEARCH_TIMEOUT = 1000;

function LocationSearch({ callback, open, close, loading, ...rest }) {
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState(0);
  const [selectedCity, setSelectedCity] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [cities, setCities] = useState([]);
  const [streets, setStreets] = useState([]);
  const [notFound, setNotFound] = useState(false);
  const [message, setMessage] = useState('');

  function reset() {
    setSelectedState(0);
    setSelectedCity('');
    setNeighborhood('');
    setCities([]);
    setStreets([]);
  }

  const fetchStates = async () => {
    loading(true);
    const response = await httpClient.get(
      'https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome'
    );

    if (Array.isArray(response) && response.length > 0) {
      setStates(
        response.map(state => ({
          name: state.nome,
          uf: state.sigla,
          id: state.id
        }))
      );
    }
    loading(false);
  };

  const getCitiesFromState = async id => {
    loading(true);
    const response = await httpClient.get(
      `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${id}/municipios`
    );

    if (Array.isArray(response) && response.length > 0) {
      setCities(response.map(city => city.nome));
    }
    loading(false);
  };

  useEffect(() => {
    fetchStates();
  }, []);

  useEffect(() => {
    if (selectedState > 0) getCitiesFromState(selectedState);
  }, [selectedState]);

  const debounceSearch = useRef(
    _.debounce(async values => {
      search(values);
    }, SEARCH_TIMEOUT)
  ).current;

  const search = async values => {
    loading(true);
    const response = await httpClient.get(
      `https://viacep.com.br/ws/${values.uf}/${
        values.city
      }/${values.street.split(' ').join('+')}/json/`
    );
    if (Array.isArray(response) && response.length > 0) {
      response[0].bairro ? setStreets(response) : setNotFound(true);
    } else {
      setNotFound(true);
    }
    loading(false);
  };

  const handleSearch = event => {
    const value = event.target.value;
    const state = states.find(s => s.id === selectedState);
    if (value.length > 3) {
      debounceSearch({
        uf: state.uf,
        city: selectedCity,
        street: value
      });
    } else setStreets([]);
  };

  const handleManualAddress = street => {
    if (street) {
      callback(street);
    } else {
      const state = states.find(s => s.id === selectedState);
      callback({
        uf: state.uf,
        localidade: selectedCity,
        bairro: neighborhood
      });
    }
    reset();
    close();
  };

  const handleClose = force => {
    if (force) {
      reset();
      close();
      callback(null);
      return;
    }
    if (selectedState === 0 || !selectedCity || (!notFound && !neighborhood)) {
      setMessage('Por favor procure um endereço correto antes de prosseguir!');
    } else {
      handleManualAddress();
    }
  };

  const handleState = e => {
    setNotFound(false);
    setSelectedCity('');
    setStreets([]);
    setNeighborhood('');
    setSelectedState(e.target.value);
  };

  const handleCity = e => {
    setNotFound(false);
    setSelectedCity(e.target.value);
    setStreets([]);
    setNeighborhood('');
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>e-sobras</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {message || 'Selecione seu endereço'}
        </DialogContentText>
        <Box width="100%" mt={3} display="flex" flexDirection="column">
          <CssInputText
            margin="normal"
            variant="outlined"
            fullWidth
            select
            label="Estado"
            onChange={handleState}
          >
            <MenuItem value="default">Estado</MenuItem>
            {states.length > 0 &&
              states.map(state => (
                <MenuItem value={state.id}>{state.name}</MenuItem>
              ))}
          </CssInputText>
          <CssInputText
            margin="normal"
            onChange={handleCity}
            variant="outlined"
            fullWidth
            disabled={selectedState === 0}
            select
            label="Cidade"
          >
            <MenuItem value="default">Cidade</MenuItem>
            {cities.length > 0 &&
              cities.map(city => <MenuItem value={city}>{city}</MenuItem>)}
          </CssInputText>
          <CssInputText
            margin="normal"
            variant="outlined"
            fullWidth
            placeholder="Busque pelo nome da rua"
            disabled={!selectedCity}
            onChange={handleSearch}
          />
          <Box hidden={!notFound}>
            <CssInputText
              margin="normal"
              variant="outlined"
              fullWidth
              placeholder="Adicionar bairro"
              title="Adicionar bairro manualmente"
              onChange={e => setNeighborhood(e.target.value)}
            />
            <Button
              color="primary"
              variant="contained"
              onClick={handleManualAddress}
            >
              Adicionar
            </Button>
          </Box>
          <Box mt={5} height="6rem" width="100%" overflow="auto">
            {streets.length > 0 &&
              !notFound &&
              streets.map(street => (
                <MenuItem
                  onClick={() => handleManualAddress(street)}
                  value={street.cep}
                >{`${street.logradouro} - (${street.cep}) - ${street.bairro}`}</MenuItem>
              ))}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)} color="primary">
          OK
        </Button>
        <Button onClick={() => handleClose(true)} color="secondary">
          cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default React.memo(LocationSearch);
