import http, {
  cleanHttpClient as http2,
  uncachedHttpClient
} from './httpClient';
import { CONSTANTS } from '../config';

const API_PATHS = {
  CATEGORY: '/category',
  MATERIAL: '/material',
  PRODUCT: '/product',
  PRODUCT_LIST_BY_MATERIAL: '/product/material/',
  BOOKMARK: '/user/bookmark/',
  USER_BOOKMARK: '/user/bookmarks/',
  SEARCH_ADS: '/list/advertisement',
  AD_BY_USER: '/user/advertisement',
  ADS_LOCATIONS: '/ad/locations',
  LOGIN: '/login',
  LOGOUT: '/logout',
  VALIDATE: '/validate',
  ADVERTISEMENT: '/advertisement',
  USER: '/user',
  CEP_API: 'https://viacep.com.br/ws/$/json/',
  ACTIVATE_USER: '/user/activate',
  INACTIVATE_USER: '/user/inactivate',
  USER_DETAILS: '/user/details',
  FORGOT_PASS_USER: '/user/forgotPassword',
  CREATE_SEARCH_ALERT: '/alarm',
  RESET_PASS_USER: '/user/resetPassword',
  IMG: '/imgs',
  CHANGE_PASSWORD: '/user/change-pass'
};

function handleArrayResponse(response) {
  if (Array.isArray(response) && response.length > 0) {
    return response;
  } else {
    return [];
  }
}

function handleSearchResponse(response) {
  if (Array.isArray(response.docs) && response.docs.length > 0) {
    return response;
  } else {
    return [];
  }
}

function handleLoginResponse(response) {
  const { user, token } = response;
  localStorage.setItem(CONSTANTS.userKey, JSON.stringify(user));
  localStorage.setItem(CONSTANTS.tokenKey, token);
  return true;
}

export async function getSegments() {
  const response = await http.get(API_PATHS.CATEGORY);
  return response.error ? [] : handleArrayResponse(response);
}
export function getSegmentById(id) {
  return http.get(API_PATHS.CATEGORY + '/' + id);
}

export async function getMaterialsBySegment(categoryId) {
  const response = await http.get(API_PATHS.MATERIAL, {
    params: { categoryId }
  });
  return response.error ? [] : handleArrayResponse(response);
}

export async function getProductsByMaterialId(materialId) {
  const response = await http.get(
    API_PATHS.PRODUCT_LIST_BY_MATERIAL + materialId
  );
  return response.error ? [] : handleArrayResponse(response);
}

export async function getLocations() {
  const response = await http.get(API_PATHS.ADS_LOCATIONS);
  return response.error ? undefined : response;
}

export function getAddress(cep) {
  const replaceCep = API_PATHS.CEP_API.replace('$', cep);
  return http2.get(replaceCep);
}

export async function searchAds(query) {
  const response = await http.get(API_PATHS.SEARCH_ADS + '/' + query);
  return response.error ? [] : handleSearchResponse(response);
}

export async function searchAdsByUser() {
  const response = await uncachedHttpClient.get(API_PATHS.AD_BY_USER);
  return response.error ? [] : handleArrayResponse(response);
}

export async function searchUserBookmarks() {
  const response = await uncachedHttpClient.get(API_PATHS.USER_BOOKMARK);
  return response.error ? [] : handleArrayResponse(response);
}
export async function login(form) {
  const response = await http.post(API_PATHS.LOGIN, { ...form });
  return response.error ? response : handleLoginResponse(response);
}

export async function saveAdvertisements(advertisements) {
  const response = await http.post(API_PATHS.ADVERTISEMENT, [
    ...advertisements
  ]);
  return response;
}

export async function addBookmark(advertisement) {
  const response = await uncachedHttpClient.post(
    API_PATHS.BOOKMARK + advertisement.id
  );
  return response;
}

export async function removeBookmark(advertisement) {
  const response = await uncachedHttpClient.delete(
    API_PATHS.BOOKMARK + advertisement.id
  );
  return response;
}

export function register(user) {
  return http.post(API_PATHS.USER, user);
}

export function updateUser(user) {
  return http.put(API_PATHS.USER + '/' + user.id, user);
}

export async function getAdvertisementDetails(id) {
  const response = await uncachedHttpClient.get(
    API_PATHS.ADVERTISEMENT + '/' + id
  );
  return response;
}

export async function updateAdvertisementDetails(id, ad) {
  const response = await http.put(API_PATHS.ADVERTISEMENT + '/' + id, {
    ...ad
  });
  return response;
}

export async function getUserDetails(id) {
  const response = await http.get(API_PATHS.USER_DETAILS + '/' + id);
  return response;
}

export async function removeAdvertisement(id, deleteReason) {
  const response = await http.delete(
    API_PATHS.ADVERTISEMENT + '/' + id + '/' + deleteReason
  );
  return response;
}

export async function createSearchAlert(payload) {
  return http.post(API_PATHS.CREATE_SEARCH_ALERT, { ...payload });
}

export function activate(iv) {
  return http.post(API_PATHS.ACTIVATE_USER, { iv });
}

export function inactivate(email) {
  return http.post(API_PATHS.INACTIVATE_USER, { email });
}

export function forgotPass(payload) {
  return http.post(API_PATHS.FORGOT_PASS_USER, { ...payload });
}

export function resetPass(payload) {
  return http.post(API_PATHS.RESET_PASS_USER, { ...payload });
}

export function validate() {
  return http.get(API_PATHS.VALIDATE);
}

export function uploadImage(img) {
  return http.post(API_PATHS.IMG, img);
}

export function logout(email) {
  return http.post(API_PATHS.LOGOUT, { email });
}

export function changePassword(form) {
  return http.post(API_PATHS.CHANGE_PASSWORD, { ...form });
}

export default {
  getSegments,
  getMaterialsBySegment,
  getProductsByMaterialId,
  getLocations,
  searchAds,
  searchAdsByUser,
  login,
  register,
  updateUser,
  saveAdvertisements,
  getAdvertisementDetails,
  updateAdvertisementDetails,
  removeAdvertisement,
  getAddress,
  getSegmentById,
  activate,
  validate,
  forgotPass,
  resetPass,
  uploadImage,
  inactivate,
  logout,
  changePassword,
  createSearchAlert,
  getUserDetails,
  addBookmark,
  removeBookmark,
  searchUserBookmarks
};
