import styled from "styled-components";

export const HeaderImage = styled.div`
    margin-top: 1.5rem;
    width: 100%;
    height: 130px;
    background-color: var(--light);
    background-image: ${(props) => `url("${props.image || ""}")`};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;

    & > div {
        max-width: 800px;
        max-height: 45px;

        @media (max-width: 576px) {
            max-height: 90px;
            height: 100%;
        }
    }
`;

export const Side = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
`;
