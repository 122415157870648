import React from 'react';
import { Copy } from 'react-feather';
import copy from 'clipboard-copy';
import { makeStyles } from '@material-ui/styles';
import OutlineCssButton from './OutlineCssButton';
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius,
    margin: '.5rem',
    padding: 10,
    minWidth: 0,
    maxHeight: 31,
    verticalAlign: 'unset',
    transition: 'all ease-in-out .45s',
    '& *': {
      margin: 0,
      padding: 0
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      filter: 'brightness(.85)'
    }
  },
  svg: {
    width: 18,
    height: 18,
    color: 'white',
    margin: 0,
    padding: 0
  }
}));

export default function Clipboard({ link, callback }) {
  const classes = useStyles();
  return (
    <OutlineCssButton
      className={classes.root}
      onClick={() => {
        copy(link);
      }}
    >
      <Copy className={classes.svg} size="15px" />
    </OutlineCssButton>
  );
}
