import {
  getFullProductDescription,
  productDetails,
} from '../views/adverisementDetails/business';
import formatCurrency from './currency';

export const wppMessageSend = (cellPhone, ad, productId, material, shareUrl)  => {
    return 'https://wa.me/+55' +
    (cellPhone
      ? cellPhone.replace(/(\()|(\))|\s|-/gi, '')
      : '') +
    '?text=' +
    `Olá, estava visitando seu anúncio no E-sobras:%0A%0AProduto: *${getFullProductDescription(
      productId,
      ad,
      material
    )}*%0AMedidas: *${productDetails(
      ad,
      material
    ).trim()}*%0A%0ACod: *MA-${ad.sequenceValue}*
    %0A%0AValor: *${formatCurrency(ad.piecePrice)}*%0A%0APodemos conversar?%0A%0A` +
    encodeURI(shareUrl)
};

export const wppMessageSendOnElement = (ad, productId, material, shareUrl)  => {
  return `Olá, estava visitando seu anúncio no E-sobras:\n\nProduto: *${getFullProductDescription(
    productId,
    ad,
    material
  )}*\nMedidas: *${productDetails(
    ad,
    material
  ).trim()}*\n\nCod: *MA-${ad.sequenceValue}*
  \n\nValor: *${formatCurrency(ad.piecePrice)}*\n\nPodemos conversar?\n\n`
};
