import React, { useState } from "react";

import {Helmet} from "react-helmet";

import PropTypes from "prop-types";
import { useEffect } from "react";

import creatNewAdSlot from "./defineSlot"

export function AdBuilder(props) {
    let { size, id, style } = props;
    const [scriptGPTLoaded, setscriptGPTLoaded] = useState(
        false
    );

    function close() {
        setscriptGPTLoaded({
            scriptGPTLoaded: true
        });
    }

    useEffect(() => { 
        window.setTimeout(function(){
            close();
        }, 2000);
        
    }, [size, id, style]); 

    return (
        <div>
            <Helmet>
            <script
                src="https://www.googletagservices.com/tag/js/gpt.js"
                
            ></script>
            </Helmet>
            {scriptGPTLoaded && 
                creatNewAdSlot({
                    path: '/22156677344/esobras',
                    size: size == "fluid" ? "fluid" : JSON.parse(size),
                    id: id,
                    // id:""
                })
            }
            <div style={Object.assign({}, {width: size == "fluid"? "100%" : JSON.parse(size)[0], height: size == "fluid" ? "auto" : JSON.parse(size)[1]}, style)} id={id}></div>
        </div>
    );
}

AdBuilder.propTypes = {
    size: PropTypes.string,
    id: PropTypes.string
};

export default AdBuilder;
