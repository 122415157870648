import React from 'react';
import { Box, Container, Typography, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import AdBuilder from '../../components/AdManager';
import useMobileDetect from '../../utils/useMobileDetect';

const useStyles = makeStyles(theme => ({
  title: {
    margin: '1.5rem 0 0.8rem 0'
  }
}));

const CookiesPolicy = () => {
  const classes = useStyles();
  const detectMobile = useMobileDetect();

  return (
    <Page className={classes.root} title="Política de Cookies">
      <Box
        display="flex"
        flexDirection="column"
        marginTop={4}
        height="100%"
        className={classes.centerMd}
      >
        {detectMobile.isDesktop() && (
          <div style={{ margin: '0px auto 24px' }}>
            <AdBuilder size="[970,250]" id="ad-banner-970x250"></AdBuilder>
          </div>
        )}
        {detectMobile.isMobile() && (
          <div
            style={{
              margin: '0px auto 24px',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <AdBuilder size="[320,100]" id="ad-banner-320x100"></AdBuilder>
          </div>
        )}
        <Container maxWidth="md">
          <Box flexDirection="column" display="Flex">
            <Typography
              className={classes.title}
              gutterBottom
              variant="h2"
              color="primary"
            >
              Política de uso de cookies
            </Typography>
            <Typography
              align="justify"
              gutterBottom
              variant="body1"
              component="p"
            >
              Por vezes utilizamos cookies para armazenar e monitorar
              informações e preferências de nossos usuários. Os cookies nos
              permitem personalizar em termos visuais suas visitas ao nosso
              ambiente on-line, tão logo você seja identificado, tornando a sua
              experiência em nosso site otimizada.
            </Typography>
            <Typography
              align="justify"
              gutterBottom
              variant="body1"
              component="p"
            >
              Nós e nossas afiliadas, terceiros e outros parceiros utilizamos
              cookies e outras tecnologias de identificação em nosso site,
              aplicativo móvel, comunicações por e-mail, anúncios e outros
              serviços online.
            </Typography>
            <Typography
              align="justify"
              gutterBottom
              variant="body1"
              component="p"
            >
              Além disso, podemos monitorar os seus acessos e entender o que
              você busca, quanto tempo fica em cada página, dentre outras coisas
              importantes para sempre o atendermos da melhor forma possível.
            </Typography>
            <Typography
              align="justify"
              gutterBottom
              variant="body1"
              component="p"
            >
              A despeito da maioria dos navegadores de internet estarem
              inicialmente configurados para aceitar cookies, caso você prefira,
              poderá negar o armazenamento em seu disco rígido, utilizando
              alguma forma alternativa. Contudo, todos os usuários devem
              entender que áreas do ambiente esobras.com.br e seus subdomínios
              estão sujeitas a um funcionamento incorreto neste caso.
            </Typography>
            <Typography
              align="justify"
              gutterBottom
              variant="body1"
              component="p"
            >
              Para que nossos anúncios tenham a maior relevância possível,
              trabalhamos com prestadores de serviços/afiliados que nos auxiliam
              a veicular anúncios semelhantes em diversos aparelhos e
              plataformas. Por exemplo, trabalhamos com serviços de anúncios de
              mídias sociais para veicular a você anúncios relevantes de acordo
              com as suas atividades nesses canais de mídia. Nós também
              poderemos contratar prestadores de serviços para veicular anúncios
              em aplicativos ou sites móveis semelhantes aos que são veiculados
              em sites tradicionais.
            </Typography>
            <Typography className={classes.title} gutterBottom variant="h4">
              <strong>Sincronização e Relevância de Anúncios</strong>
            </Typography>
            <Typography
              align="justify"
              gutterBottom
              variant="body1"
              component="p"
            >
              Há cada vez mais relatos de vírus que são utilizados para
              interceptar e recolher informações sensíveis nos computadores.
              Certifique-se portanto, de que o seu computador tem software de
              proteção contra vírus devidamente atualizado. O sistema operativo
              e navegador de Internet (browser) do seu computador também devem
              estar atualizados com as mais recentes atualizações de segurança.
            </Typography>
            <Typography gutterBottom variant="body1" component="p">
              <strong>Equipe E-Sobras</strong>
            </Typography>
          </Box>
        </Container>
      </Box>
      {detectMobile.isMobile() && (
        <div
          style={{
            margin: '24px auto 0px',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <AdBuilder size="[300,250]" id="ad-banner-300x250"></AdBuilder>
        </div>
      )}
    </Page>
  );
};

export default CookiesPolicy;
