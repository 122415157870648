import React from 'react';
import { Box, Container, Typography, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import AdBuilder from '../../components/AdManager';
import useMobileDetect from '../../utils/useMobileDetect';

const useStyles = makeStyles(theme => ({
  title: {
    margin: '1.5rem 0 0.8rem 0'
  }
}));

const SecureSales = () => {
  const classes = useStyles();
  const detectMobile = useMobileDetect();

  return (
    <Page className={classes.root} title="Poteja-se">
      <Box
        display="flex"
        flexDirection="column"
        marginTop={4}
        height="100%"
        className={classes.centerMd}
      >
        {detectMobile.isDesktop() && (
          <div style={{margin: '0px auto 24px' }}>
            <AdBuilder
              size="[970,250]"
              id="ad-banner-970x250"
            ></AdBuilder>
          </div>
        )}
        {detectMobile.isMobile() && (
          <div style={{
            margin: '0px auto 24px',
            display: 'flex',
            justifyContent: 'center'       
          }}>
            <AdBuilder
              size="[320,100]"
              id="ad-banner-320x100"
            ></AdBuilder>
          </div>
        )}
        <Container maxWidth="md">
          <Box flexDirection="column" display="Flex">
            <Typography gutterBottom={5} variant="h2" color="primary">
              Venda Segura
            </Typography>
            <Typography gutterBottom={5} variant="h4" className={classes.title}>
              <strong>
                Você deve garantir que sua sobra que está a vender é genuíno
                e/ou original.
              </strong>
            </Typography>
            <Typography
              align="justify"
              gutterBottom={5}
              variant="body1"
              component="p"
            >
              Antes de anunciar, certifique-se de fazer o reconhecimento de sua
              sobra. Se anunciar sobras com modelos incompatíveis com as
              categorias, a responsabilidade é inteiramente sua. A E-Sobras é
              uma plataforma de indicação de usuários e não se responsabiliza
              pela veracidade do anuncio!
            </Typography>
            <Typography
              align="justify"
              gutterBottom={5}
              variant="body1"
              component="p"
            >
              Solicite documentação para identificação do potencial comprador.
              Avalie a situação e não corra riscos desnecessários.
            </Typography>
            <Typography
              align="justify"
              gutterBottom={5}
              variant="body1"
              component="p"
            >
              Seja prudente em situações quando os potenciais compradores são de
              outros estados ou países e que querem pagar com um cheque ou
              transferência bancária. Este é um método comum utilizado pelos
              fraudulentos.
            </Typography>
            <Typography gutterBottom={5} variant="h4" className={classes.title}>
              <strong>
                Não envie o produto sem ter as condições de pagamento
                devidamente acordadas e/ou finalizadas.
              </strong>
            </Typography>
            <Typography
              align="justify"
              gutterBottom={5}
              variant="body1"
              component="p"
            >
              Evite pagamentos por cheque. Não é garantido que esse cheque tenha
              cobertura.
            </Typography>
            <Typography
              align="justify"
              gutterBottom={5}
              variant="h4"
              className={classes.title}
            >
              <strong>Cuidado com falsos comprovativos de pagamento.</strong>
            </Typography>
            <Typography
              align="justify"
              gutterBottom={5}
              variant="body1"
              component="p"
            >
              Efetue as suas vendas sempre com a máxima segurança.
            </Typography>
            <Typography gutterBottom={5} variant="body1" component="p">
              <strong>Equipe E-Sobras</strong>
            </Typography>
          </Box>
        </Container>
      </Box>
      {detectMobile.isMobile() && (
        <div style={{
          margin: '24px auto 0px',
          display: 'flex',
          justifyContent: 'center'       
        }}>
          <AdBuilder
            size="[300,250]"
            id="ad-banner-300x250"
          ></AdBuilder>
        </div>
      )}
    </Page>
  );
};

export default SecureSales;
