import React from 'react';
import { Box, Container, Typography, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import AdBuilder from '../../components/AdManager';
import useMobileDetect from '../../utils/useMobileDetect';
import { Helmet } from 'react-helmet';

const useStyles = makeStyles(theme => ({
  title: {
    margin: '1.5rem 0 0.8rem 0'
  },
  video: {
    minWidth: '300px'
  }
}));

const Help = () => {
  const classes = useStyles();
  const detectMobile = useMobileDetect();

  return (
    <Page className={classes.root} title="Ajuda">
      <Box
        display="flex"
        flexDirection="column"
        marginTop={4}
        height="100%"
        className={classes.centerMd}
      >
        <Container maxWidth="md">
          <Box flexDirection="column" display="Flex">
            <Typography gutterBottom variant="h3" color="primary">
              Como utilizar o E-Sobras?
            </Typography>
            <Box mt={5} display="flex" flexDirection="column" flexWrap="wrap">
              <Typography gutterBottom variant="h3">
                Precisa de uma ajudinha para utilizar o E-sobras? Nos vídeos
                abaixo podemos tirar várias dúvidas recorrentes.
              </Typography>
              <Box mt={5} flex={1}>
                <Typography gutterBottom variant="h2" className={classes.title}>
                  <strong>Buscando uma sobra</strong>
                </Typography>
              </Box>
              <Box mt={3} width="100%">
                <iframe
                  width="100%"
                  className={classes.video}
                  height="400vw"
                  src="https://www.youtube.com/embed/Dd-Q_FhZvds"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </Box>
              <Box mt={5} flex={1}>
                <Typography
                  align="left"
                  gutterBottom
                  variant="h2"
                  className={classes.title}
                >
                  <strong>Compartilhar Pesquisas e Anúncios</strong>
                </Typography>
              </Box>
              <Box mt={3} width="100%">
                <iframe
                  width="100%"
                  className={classes.video}
                  height="400vw"
                  src="https://www.youtube.com/embed/LEvoGxj4I9w"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </Box>
              <Box mt={5} flex={1}>
                <Typography gutterBottom variant="h2" className={classes.title}>
                  <strong>Anunciando fitas de borda</strong>
                </Typography>
              </Box>
              <Box mt={3} width="100%">
                <iframe
                  width="100%"
                  className={classes.video}
                  height="400vw"
                  src="https://www.youtube.com/embed/9i-Kys3fnm8"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </Box>
              <Box mt={5} flex={1}>
                <Typography gutterBottom variant="h2" className={classes.title}>
                  <strong>Quando não econtro o produto que procuro.</strong>
                </Typography>
              </Box>
              <Box mt={3} width="100%">
                <iframe
                  width="100%"
                  className={classes.video}
                  height="400vw"
                  src="https://www.youtube.com/embed/Z77d-a5XvlM"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </Box>
              <Box mt={5} flex={1}>
                <Typography gutterBottom variant="h2" className={classes.title}>
                  <strong>
                    Recebendo um alerta quando o produto chegar na plataforma.
                  </strong>
                </Typography>
              </Box>
              <Box mt={3} width="100%">
                <iframe
                  width="100%"
                  className={classes.video}
                  height="400vw"
                  src="https://www.youtube.com/embed/yQwt8QhGTSI"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </Page>
  );
};

export default Help;
