import { createSlice } from '@reduxjs/toolkit';
import querystring from 'querystring';
import { isMobile } from 'react-device-detect';
import Api from 'src/api/Api';
import { getUserAttribute } from 'src/utils/user';

export const SearchReducer = createSlice({
  name: 'searchState',
  initialState: {
    segments: [],
    materials: [],
    products: [],
    results: [],
    image: '',
    modelBrand: '',
    pagination: {},
    selectedMaterial: {},
    selectedSegment: {},
    selectedProduct: {},
    error: false,
    errorMessage: '',
    isLoading: false
  },
  reducers: {
    setResults: (state, action) => {
      let page = action.payload;
      let docs = page.docs;
      const bookmarks = getUserAttribute('bookmarks');
      if (bookmarks && Array.isArray(bookmarks)) {
        docs = page.docs.map(doc => ({
          ...doc,
          bookmark: bookmarks.includes(doc.id)
        }));
      }
      state.results = docs;
      delete page.docs;
      state.pagination = page;
    },
    setSegments: (state, action) => {
      state.segments = action.payload;
    },
    setMaterials: (state, action) => {
      state.materials = action.payload;
    },
    setProducts: (state, action) => {
      let products = action.payload;
      if (products) {
        state.products = products;
      } else {
        state.products = [];
      }
    },
    setSelectedSegment: (state, action) => {
      let id = action.payload;
      let segment = state.segments.find(
        segment => segment.id == id || segment.oldId == id
      );
      if (segment) state.selectedSegment = segment;
    },
    setSelectedMaterial: (state, action) => {
      let id = action.payload;
      if (id) {
        let material = state.materials.find(
          material => material.id == id || material.oldId == id
        );
        if (material) state.selectedMaterial = material;
      } else {
        state.selectedMaterial = '';
      }
    },
    setSelectedProduct: (state, action) => {
      let id = action.payload.id;
      let product = state.products.find(product => product.id == id);
      if (product) {
        state.selectedProduct = product;
        state.image = product.imageUrl;
      } else {
        state.selectedProduct = {};
      }
    },
    resetProduct: (state, action) => {
      state.selectedProduct = {};
    },
    resetModelBrand: (state, action) => {
      state.modelBrand = '';
    },
    setModelBrand: (state, action) => {
      const { modelBrand } = action.payload;
      if (modelBrand) {
        state.modelBrand = modelBrand;
      } else {
        state.modelBrand = '';
      }
    },
    setError: (state, action) => {
      const { error = false, errorMessage = '' } = action.payload;
      state = { ...state, error, errorMessage };
    },
    setIsLoading: (state, action) => {
      const isLoading = action.payload;
      state.isLoading = isLoading;
    },
    setImage: (state, action) => {
      const image = action.payload.imageUrl;
      if (image) {
        state.image = image;
      }
    }
  }
});

export const {
  setResults,
  setMaterials,
  setProducts,
  setSegments,
  setSelectedSegment,
  setSelectedMaterial,
  setSelectedProduct,
  setImage,
  setError,
  setIsLoading,
  setModelBrand,
  resetModelBrand,
  resetProduct
} = SearchReducer.actions;

export const searchResults = query => async dispatch => {
  const errorMessage = 'Erro ao buscar, tente novamente';
  const values = querystring.parse(query.split('?')[1]);

  if (values.categoryId) {
    await dispatch(setSelectedSegment(values.categoryId));
    await dispatch(getMaterialsBySegmentId(values.categoryId));
  }

  if (values.material) {
    await dispatch(setSelectedMaterial(values.material));
    await dispatch(getProductsByMaterialId(values.material));
  }
  if (values.productId) {
    await dispatch(setSelectedProduct({ id: values.productId }));
  }
  if (values.mb) {
    await dispatch(setSelectedProduct({ modelBrand: values.mb }));
  }

  try {
    dispatch(setIsLoading(true));
    const result = await Api.searchAds(query);
    if (result) {
      await dispatch(setResults(result));
      window.location.href = '#';
      if (isMobile) window.location.href = '#search-results';
    } else {
      dispatch(setError({ error: true, errorMessage }));
    }
    dispatch(setIsLoading(false));
  } catch (error) {
    dispatch(setIsLoading(false));
    dispatch(setError({ error: true, errorMessage }));
  }
};

export const fetchSegments = () => async dispatch => {
  const errorMessage = 'Erro ao buscar dados de segmentos.';
  try {
    dispatch(setIsLoading(true));
    const result = await Api.getSegments();
    if (result.error) {
      dispatch(
        setError({
          error: true,
          errorMessage: result.error.message || errorMessage
        })
      );
    } else {
      dispatch(setSegments(result));
    }
    dispatch(setIsLoading(false));
  } catch (error) {
    dispatch(setIsLoading(false));
    dispatch(setError({ error: true, errorMessage }));
  }
};

export const getMaterialsBySegmentId = segmentId => async dispatch => {
  const errorMessage = 'Erro ao buscar dados de materiais.';
  try {
    dispatch(setIsLoading(true));
    if (segmentId === '5eb47d2dcd807c0f57981969') segmentId = 8;
    const result = await Api.getMaterialsBySegment(segmentId);
    if (result.error) {
      dispatch(
        setError({
          error: true,
          errorMessage: result.error.message || errorMessage
        })
      );
    } else {
      dispatch(setMaterials(result));
    }
    dispatch(setIsLoading(false));
  } catch (error) {
    dispatch(setIsLoading(false));
    dispatch(setError({ error: true, errorMessage }));
  }
};

export const getProductsByMaterialId = materialId => async dispatch => {
  const errorMessage = 'Erro ao buscar dados de produtos.';
  try {
    dispatch(setIsLoading(true));
    if (materialId === '5eb47d7bcd807c0f5798196a') materialId = 30;
    const result = await Api.getProductsByMaterialId(materialId);
    if (result.error) {
      dispatch(
        setError({
          error: true,
          errorMessage: result.error.message || errorMessage
        })
      );
    } else {
      dispatch(setProducts(result));
    }
    dispatch(setIsLoading(false));
  } catch (error) {
    dispatch(setIsLoading(false));
    dispatch(setError({ error: true, errorMessage }));
  }
};

export const selectSearchState = state => state.searchState;

export default SearchReducer.reducer;
