import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import store from './store';
import { Provider } from 'react-redux';

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

// var Tawk_API = Tawk_API || {},
//   Tawk_LoadStart = new Date();
// (function() {
//   var s1 = document.createElement('script'),
//     s0 = document.getElementsByTagName('script')[0];
//   s1.async = true;
//   s1.src = 'https://embed.tawk.to/600c3e2bc31c9117cb71b638/1esntcid8';
//   s1.charset = 'UTF-8';
//   s1.setAttribute('crossorigin', '*');
//   s0.parentNode.insertBefore(s1, s0);
// })();
