import React from 'react';
import { Box, Container, Typography, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import AdBuilder from '../../components/AdManager';
import useMobileDetect from '../../utils/useMobileDetect';
import theme from 'src/theme';

const useStyles = makeStyles(theme => ({
  title: {
    margin: '1.5rem 0 0.8rem 0'
  }
}));

const TermsAndConditions = () => {
  const classes = useStyles();
  const detectMobile = useMobileDetect();

  return (
    <Page className={classes.root} title="Termos de uso">
      <Box
        display="flex"
        flexDirection="column"
        marginTop={4}
        height="100%"
        className={classes.centerMd}
      >
        {detectMobile.isDesktop() && (
          <div style={{ margin: '0px auto 24px' }}>
            <AdBuilder size="[970,250]" id="ad-banner-970x250"></AdBuilder>
          </div>
        )}
        {detectMobile.isMobile() && (
          <div
            style={{
              margin: '0px auto 24px',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <AdBuilder size="[320,100]" id="ad-banner-320x100"></AdBuilder>
          </div>
        )}
        <Container maxWidth="md">
          <Box flexDirection="column" display="Flex">
            <Typography gutterBottom variant="h2" color="primary">
              Termos de Uso
            </Typography>
            <Typography gutterBottom variant="h4" className={classes.title}>
              <strong>Termos de Uso do esobras.com.br</strong>
            </Typography>
            <Typography
              align="justify"
              gutterBottom
              variant="body1"
              component="p"
            >
              Seja bem-vindo ao esobras.com.br. Ao usar este site você está
              aceitando e concordando com os termos, regras e condições
              expressas neste documento.
            </Typography>

            <Typography
              align="justify"
              gutterBottom
              variant="h4"
              className={classes.title}
            >
              <strong>Mudanças nas condições de uso</strong>
            </Typography>
            <Typography
              align="justify"
              gutterBottom
              variant="body1"
              component="p"
            >
              Em função do caráter volátil do meio digital, o esobras.com.br
              reserva-se o direito de modificar estes termos de uso a qualquer
              momento, sem aviso prévio ou notificação. Por isso, sugerimos que
              você o leia com freqüência para se atualizar das condições de uso
              deste site, aqui expressas.
            </Typography>
            <Typography gutterBottom variant="h4" className={classes.title}>
              <strong>A quem se destina</strong>
            </Typography>
            <Typography
              align="justify"
              gutterBottom
              variant="body1"
              component="p"
            >
              Apenas maiores de 18 anos podem utilizar o esobras.com.br. Quando
              você finaliza seu cadastro, você está afirmando ter mais de 18
              anos, e que é capaz e responsável pela compreensão deste documento
              e da Política de Privacidade.
            </Typography>
            <Typography gutterBottom variant="h4" className={classes.title}>
              <strong>Responsabilidades</strong>
            </Typography>
            <Typography
              align="justify"
              gutterBottom
              variant="body1"
              component="p"
            >
              O conteúdo do esobras.com.br é produzido pelos usuários sem
              controle prévio e, apesar de manter uma equipe de profissionais
              para atender aos usuários, esobras.com.br não se responsabiliza,
              em nenhuma circunstância, por qualquer espécie de ofensa que os
              conteúdos possam causar a qualquer usuário. Não há mecanismos de
              censura, mas palavras, frases, textos ou quaisquer outras
              manifestações de comunicação que infrinjam as leis vigentes serão
              retiradas do site, seu autor banido, sem prejuízo da comunicação
              da infração às autoridades competentes.
            </Typography>
            <Typography
              align="justify"
              gutterBottom
              variant="body1"
              component="p"
            >
              Você – e somente você – é integralmente responsável pelo uso que
              faz do
              <strong>esobras.com.br</strong>, e estará , ao utilizar o site,
              sujeito às resoluções deste Termo de Uso, bem como às leis
              vigentes. Você assume todo e qualquer risco na utilização do{' '}
              <strong>esobras.com.br</strong>.
            </Typography>
            <Typography
              align="justify"
              gutterBottom
              variant="body1"
              component="p"
            >
              O <strong>esobras.com.br</strong>, seus sócios, afiliados,
              parceiros, fornecedores, funcionários ou qualquer pessoa ligada
              direta ou indiretamente com estes não podem ser responsabilizados
              por nenhuma ocorrência decorrente do uso do site por qualquer
              usuário.
            </Typography>
            <Typography gutterBottom variant="h4" className={classes.title}>
              <strong>Propriedade e Copyright</strong>
            </Typography>
            <Typography
              align="justify"
              gutterBottom
              variant="body1"
              component="p"
            >
              Ao submeter qualquer informação no <strong>esobras.com.br</strong>{' '}
              (recado, mensagens, comunidades, imagens, sons, ou qualquer outro
              tipo de conteúdo), você está imediatamente cedendo ao{' '}
              <strong>esobras.com.br</strong> todos os direitos de reprodução e
              uso daquele conteúdo, sem quaisquer espécies de ônus, financeiro
              ou não, para o <strong>esobras.com.br</strong>, seus sócios,
              afiliados, parceiros, fornecedores, funcionários ou qualquer
              pessoa ligada direta ou indiretamente com estes. Esta cessão é de
              caráter global, irrestrito e para qualquer meio de comunicação.
            </Typography>
            <Typography
              align="justify"
              gutterBottom
              variant="body1"
              component="p"
            >
              Caso você identifique no <strong>esobras.com.br</strong> algum
              conteúdo cuja presença viole algum direito de imagem ou de autor
              (Copyright), você deve contatar o <strong>esobras.com.br</strong>,
              informando seu endereço de e-mail ou qualquer outra informação que
              nos permita contatá-lo, a justificativa da avaliação de violação e
              as direções para eventual averiguação.
            </Typography>
            <Typography gutterBottom variant="h4" className={classes.title}>
              <strong>Descompromisso</strong>
            </Typography>
            <Typography
              align="justify"
              gutterBottom
              variant="body1"
              component="p"
            >
              O <strong>esobras.com.br</strong> não garante, a nenhum tempo e
              sob nenhuma circunstância, o funcionamento do site como um todo ou
              qualquer de suas ferramentas isoladamente, assim como não garante
              nenhuma espécie de disponibilidade do site. O{' '}
              <strong>esobras.com.br</strong> pode ser indisponibilizado segundo
              a vontade ou necessidade de seus administradores, não cabendo
              possibilidade de litígio por eventuais danos que esta prática
              possa causar a qualquer usuário.
            </Typography>
            <Typography
              align="justify"
              gutterBottom
              variant="body1"
              component="p"
            >
              Veja também nossa{' '}
              <a
                href="/cookies-policy"
                target="_blank"
                style={{ font: 'inherit', color: theme.palette.secondary.main }}
              >
                política de cookies.
              </a>
            </Typography>
            <Typography gutterBottom variant="body1" component="p">
              <strong>Equipe E-Sobras</strong>
            </Typography>
          </Box>
        </Container>
      </Box>
      {detectMobile.isMobile() && (
        <div
          style={{
            margin: '24px auto 0px',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <AdBuilder size="[300,250]" id="ad-banner-300x250"></AdBuilder>
        </div>
      )}
    </Page>
  );
};

export default TermsAndConditions;
