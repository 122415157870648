import React, { useState, useEffect } from 'react';
import api from 'src/api/Api';
import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './Results';
import Loading from '../../components/Loading';

const useStyles = makeStyles(theme => ({
  button: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    marginRight: 10,
    transition: 'all ease-in-out 0.6s',
    '&:hover': {
      filter: 'brightness(.9)',
      backgroundColor: theme.palette.primary.main
    }
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CustomerListView = () => {
  const classes = useStyles();
  const [ads, setAds] = useState([]);
  const [loading, setLoading] = useState(false);
  const getAds = async () => {
    setLoading(true);
    let anuncios = await api.searchAdsByUser();
    await setAds(anuncios);
    setLoading(false);
  };

  useEffect(() => {
    getAds();
  }, []);

  return (
    <Page className={classes.root} title="Anuncios">
      <Loading open={loading} />
      <Container maxWidth={false}>
        <Box mt={3}>
          <Results reload={getAds} advertisements={ads} />
        </Box>
      </Container>
    </Page>
  );
};

export default CustomerListView;
