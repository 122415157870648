import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchLocations,
  selectAvertisementSearch
} from '../../views/advertisementSearch/AdvertisementSearchReducer';
import {
  removeFilter,
  searchByFilters,
  selectFiltersState
} from '../Filter/FilterReducer';
import {
  handleSelectedCity,
  handleSelectedNeighborhood,
  handleSelectedState,
  selectLocationState,
  cleanLocationFilter
} from './locationReducer';
import { Autocomplete } from '@material-ui/lab';
import { Box, Typography } from '@material-ui/core';
import OutlineCssButton from '../OutlineCssButton';
import CssInputText from '../CssTextField';
import { generateUniqueId } from 'src/utils';
import theme from 'src/theme';

export default function LocationFilter() {
  const { locations } = useSelector(selectAvertisementSearch);
  const filtersState = useSelector(selectFiltersState);
  const { cities, neighborhoods, selectedCity, selectedState } = useSelector(
    selectLocationState
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLocations());
  }, []);

  useEffect(() => {
    const teste = async () => {
      if (!Array.isArray(locations) && locations.states) {
        if (filtersState.filters.s && !selectedState)
          await dispatch(
            handleSelectedState(filtersState.filters.s, locations)
          );
        if (filtersState.filters.c && !selectedCity)
          await dispatch(handleSelectedCity(filtersState.filters.c, locations));
      }
    };

    teste();
  }, [
    !Array.isArray(locations),
    filtersState.filters.s,
    filtersState.filters.c
  ]);

  const filter = async () => {
    await dispatch(removeFilter({ key: 'page' }));
    dispatch(searchByFilters());
  };

  return (
    <Box display="flex" flexDirection="column" marginTop={5} width="85%">
      <Typography gutterBottom variant="body1">
        Localidade:
      </Typography>
      <Autocomplete
        onChange={event => {
          const { target, key } = event;
          const { value, textContent } = target;
          let selected = textContent;
          if (key === 'Enter') {
            selected = cities.find(c => c.includes(value.toUpperCase()));
          }
          dispatch(handleSelectedState(selected, locations));
        }}
        key={generateUniqueId('S')}
        fullWidth
        autoHighlight
        autoSelect
        selectOnFocus
        options={locations.states || []}
        value={filtersState.filters.s}
        renderInput={props => (
          <CssInputText margin="dense" variant="outlined" {...props} />
        )}
      />
      <Autocomplete
        onChange={event => {
          const { target, key } = event;
          const { value, textContent } = target;
          let city = textContent;
          if (key === 'Enter') {
            city = cities.find(c => c.includes(value));
          }
          dispatch(handleSelectedCity(city, locations));
        }}
        key={generateUniqueId('C')}
        options={cities}
        autoSelect
        autoHighlight
        value={filtersState.filters.c}
        fullWidth
        renderInput={props => (
          <CssInputText
            placeholder=""
            margin="dense"
            variant="outlined"
            {...props}
          />
        )}
      />
      <Autocomplete
        onChange={event => {
          const { target, key } = event;
          const { value, textContent } = target;
          let neighborhood = textContent;
          if (key === 'Enter') {
            neighborhood = neighborhoods.find(n => n.includes(value));
          }
          dispatch(handleSelectedNeighborhood(neighborhood));
        }}
        fullWidth
        autoHighlight
        autoSelect
        value={filtersState.filters.b}
        key={generateUniqueId('N')}
        options={neighborhoods}
        renderInput={props => (
          <CssInputText margin="dense" variant="outlined" {...props} />
        )}
      />
      <OutlineCssButton
        style={{
          border: `dashed thin ${theme.palette.primary.main}`,
          borderRadius: 60,
          marginBottom: 20,
          marginTop: 20
        }}
        color="primary"
        onClick={filter}
      >
        Filtrar
      </OutlineCssButton>
      <OutlineCssButton
        style={{
          border: `dashed thin ${theme.palette.secondary.main}`,
          borderRadius: 60,
          marginBottom: 20
        }}
        color="secondary"
        onClick={() => {
          dispatch(cleanLocationFilter());
        }}
      >
        Limpar Filtro
      </OutlineCssButton>
    </Box>
  );
}
