import { createSlice } from '@reduxjs/toolkit';
import querystring from 'querystring';
import { parseArrayFilters, parseTargetFilters } from './utils';
import { isMobile } from 'react-device-detect';

export const FilterReducer = createSlice({
  name: 'filtersState',
  initialState: {
    filters: {},
    filterString: ''
  },
  reducers: {
    setFilters: (state, action) => {
      const { key, value } = action.payload;
      if (state.filters[key]) {
        state.filters[key] = value;
      } else {
        state.filters = { ...state.filters, [key]: value };
      }
    },
    setStringFilters: state => {
      let filterString = JSON.stringify(state.filters)
        .replace(/(\,\")/gi, '&')
        .replace(/\"/gi, '')
        .replace(/\:/gi, '=')
        .replace(/undefined/gi, '')
        .replace(/(\{)?(\})?/gi, '');
      state.filterString = filterString;
      window.location =
        '/search?' + filterString + (isMobile ? '#search-results' : '');
    },
    removeFilter: (state, action) => {
      const { key } = action.payload;
      if (state.filters[key]) {
        let newFilters = { ...state.filters };
        delete newFilters[key];
        state.filters = newFilters;
      }
    },
    resetFilters: (state, action) => {
      let newFilters = {};
      if (action.payload) {
        const { key } = action.payload;
        if (state.filters["userId"]) {
          newFilters["userId"] = state.filters["userId"];
        }
        if (state.filters[key]) {
          newFilters[key] = state.filters[key];
        }
      }
      state.filters = newFilters;
    }
  }
});

export const {
  setFilters,
  resetFilters,
  setStringFilters,
  removeFilter
} = FilterReducer.actions;

export const queryFilters = query => async dispatch => {
  if (query.startsWith('?')) {
    query = query.replace('?', '');
  }
  let params = querystring.parse(query);
  for (var key in params) {
    await dispatch(
      setFilters({
        key,
        value: params[key]
      })
    );
  }
};
export const validateAndSetFilters = filters => async dispatch => {
  if (Array.isArray(filters) && filters.length > 0) {
    for (var filter of filters) {
      let value = filter.value;
      let key = filter.key;
      if (Array.isArray(value)) {
        value = parseArrayFilters(value);
      }
      if (value.target) {
        value = parseTargetFilters(value);
      }
      dispatch(
        setFilters({
          key,
          value
        })
      );
    }
  }
};

export const searchByFilters = () => async dispatch => {
  dispatch(setStringFilters());
};

export const selectFiltersState = state => state.filtersState;

export default FilterReducer.reducer;
